/* eslint-disable @typescript-eslint/indent */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientSettingsService } from '../core/services/client-settings.service';
import { CustomerDetailsSearch,CustomerSearchRequestModel,CustomerDetailSearchResultModel} from './customer-details-search.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerSearchService {

    private readonly refreshSearchResult = new Subject<any>();
    public refreshSearchResult$ = this.refreshSearchResult.asObservable();

    private webApiEndPoint: string;
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    
    constructor(
        private clientSetting: ClientSettingsService,
        private http: HttpClient
    ) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/CustomerDetail/`;
    }

    onRefreshSearchResult() {
        this.refreshSearchResult.next(); // This will trigger search API to fetch results again
    }

    getSearchResult(
        searchRequest: CustomerSearchRequestModel
    ): Observable<CustomerDetailSearchResultModel> {
        const searchResult = this.http
            .post<CustomerDetailSearchResultModel>(
                `${this.webApiEndPoint}search`,
                searchRequest,
                this.httpOptions
            )
            .pipe();
        return searchResult;
    }


}

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  private renderer: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  removeLoadingSpinner() {
    if (this.renderer.selectRootElement('#loader-center')) {
      const loaderElementParent = this.renderer.selectRootElement('#loader-center');
      if (loaderElementParent) {
        this.renderer.removeClass(loaderElementParent, 'loader-center');
        this.renderer.removeChild(loaderElementParent, '.loader');
      }
    }
  }
}

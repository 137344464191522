<div class="px-20">
  <app-breadcrumb [levelOneTitle]="pageName" [levelTwoTitle]="levelTwoTitle" [activeTitleLevel]="activeLevel">
  </app-breadcrumb>
</div>
<div class="">
    <mat-card class="main-card mat-elevation-z2">
        <mat-card-header>
            <div class="header-text w-full">
                <h4>MANUFACTURER SEARCH</h4>
            </div>
            <div class="additional-menu float-right">
                <button [matMenuTriggerFor]="menu" class="additional-menu-button" aria-label="Menu buttom">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="additional-menu-item" (click)="exportToExcel()"
                            [disabled]="!hasSearchActionOccurred || noSearchResults">
                        <mat-icon>upload_file</mat-icon>
                        <span>Export to Excel</span>
                    </button>
                </mat-menu>
            </div>
        </mat-card-header>
        <mat-card-content class="main-card-content">
            <form #mfrSearchForm="ngForm" (ngSubmit)="onSearch(mfrSearchForm.value)">
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inputFileNumber" class="form-label">Applicant File Number</label>
                        <div>
                            <input type="text" name="fileNumber" #fileNumber="ngModel" [(ngModel)]="mfrSearchModel.fileNumber"
                                   class="form-control" id="inputFileNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputFileVolume" class="form-label">Volume</label>
                        <div>
                            <input type="text" name="volume" #fileVolume="ngModel" [(ngModel)]="mfrSearchModel.volume"
                                   class="form-control" id="inputFileVolume" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputOwnerAccount" class="form-label">Applicant Account Number</label>
                        <div>
                            <input type="text" name="ownerAccount" #ownerAccount="ngModel" [(ngModel)]="mfrSearchModel.ownerAccount"
                                   class="form-control" id="inputOwnerAccount" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputpartysiteNumber" class="form-label">Manufacturer Party Site Number</label>
                        <div>
                            <input type="text" name="partySiteNumber" #partysiteNumber="ngModel"
                                   [(ngModel)]="mfrSearchModel.partySiteNumber" class="form-control" id="inputpartysiteNumber" />
                        </div>
                    </div>
                </div>
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inpuSubscriberNumber" class="form-label">Manufacturer Subscriber Number</label>
                        <div>
                            <input type="text" name="subscriberNumber" #subscriberNumber="ngModel"
                                   [(ngModel)]="mfrSearchModel.subscriberNumber" class="form-control" id="inputSubscriberNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputAccountNumber" class="form-label">Manufacturer Account Number</label>
                        <div>
                            <input type="text" name="mfrAccount" #mfrAccount="ngModel"
                                   [(ngModel)]="mfrSearchModel.mfrAccount" class="form-control" id="inputAccountNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputDeliverableItem" class="form-label">Deliverable Item Instance</label>
                        <div>
                            <input type="text" name="deliverableItemInstance" #deliverableItemInstance="ngModel"
                                   [(ngModel)]="mfrSearchModel.deliverableItemInstance" class="form-control" id="inputDeliverableItem" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="filterBy" class="form-label">Filter by Status</label>
                        <div>
                            <mat-select class="form-control" id="filterBy" name="filterBy" [(ngModel)]="mfrSearchModel.filterBy">
                                <mat-option [value]="options.value" *ngFor="let options of statusOptions">{{ options.key }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="md-3 row">
                    <div class="col-md-4">
                        <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty form-label" name="exactSearch"
                                      [(ngModel)]="mfrSearchModel.exactSearch">
                            Exact Search
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="text-right col-md-4">
                        <button class="mr-2 button form-clear-button" type="button" (click)="resetForm(mfrSearchForm)">Clear</button>
                        <button class="button form-button" type="submit">Search</button>
                    </div>
                </div>
            </form>
            <div class="w-full m-0" *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
                <hr />
                <app-paginator (sortingChange)='onSortByChange($event)' (viewChange)='onViewChange($event)' (paginationChange)='onSortPaginationChange($event)'
                               [isTableView]="isTableView" [length]="searchResultCount" [sortByOptions]="sortOptions" [showSortBy]="true"
                               [filterByOptions]="statusOptions" [showFilterBy]="false" [showPageSize]="true" [pageIndex]="0">
                </app-paginator>
            </div>
        </mat-card-content>
    </mat-card>
    <ng-template #mfrContainer>
    </ng-template>
    <div *ngIf="noSearchResults && hasSearchActionOccurred">
        <div class="ml-20">
            <div class="flex flex-col justify-center max-w-full mx-auto my-3">
                <div class="flex"><img src="../../../assets/images/noResults.png" class="Bitmap mx-auto" /></div>
                <div class="flex"><div class="feedback-header mx-auto title-no-data">No Manufacturer Details Found</div></div>
            </div>
        </div>
    </div>
    <div *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
        <div class="search-details-container">
            <div *ngIf="searchResult && searchResult.results && !isTableView">
                <app-mfr-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                            [wdrwlCodes]="withdrawalCodes" (withdrawOrDeleteAction)="onWithdrawOrDelete($event)">
                </app-mfr-search-result-card>
            </div>
            <div *ngIf="searchResult && searchResult.results && isTableView">
                <app-mfr-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                            [wdrwlCodes]="withdrawalCodes" (withdrawOrDeleteAction)="onWithdrawOrDelete($event)" (sortingChangeAction)="onSortingChange($event)">
                </app-mfr-search-result-card>
            </div>
        </div>
    </div>
    <div class="loader-center" *ngIf="loading">
        <div id="loader" class="loader"></div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IcDetailService } from '../../ic-details/ic-detail.service';
import { MFRIcLcDetailInformationModel } from '../../ic-details/ic-detail.model';

@Component({
    selector: 'app-iclc-information',
    templateUrl: './iclc-information.component.html',
    styleUrls: ['./iclc-information.component.scss']
})
export class IclcInformationComponent implements OnInit {

    @Input() partySiteNumber: string;
    isLoading: boolean = false;
    data: MFRIcLcDetailInformationModel = {
        ic: '',
        icNum: '',
        errorCode: 0,
        errorMessage: '',
        icInfo: '',
        lcInfo: ''
    };
    title = 'IC LC Information';

    constructor(public activeModal: NgbActiveModal, public icDetailService: IcDetailService) { }

    onCancel() {
        this.activeModal.dismiss();
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.icDetailService.getMfrIcLcInfo(this.partySiteNumber).subscribe(result => {
            this.data = result;
            this.isLoading = false;
        },
            error => {
                this.data.errorMessage = "Error while fetching the IC\LC Information";
                this.isLoading = false;
            });
    }

}

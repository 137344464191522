import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientSettingsService } from '../core/services/client-settings.service';
import { ICSearchRequest, ICDetailSearchResultModel, MFRIcLcDetailInformationModel } from './ic-detail.model';

@Injectable({
    providedIn: 'root'
})

export class IcDetailService {

    private webApiEndPoint: string;
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    private httpOptionsString = {
        headers: new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
        }),
        responseType: 'blob' as 'json'
    };

    constructor(
        private clientSetting: ClientSettingsService,
        private http: HttpClient) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/IcDetail/`;
    }

    getSearchResult(searchRequest: ICSearchRequest): Observable<ICDetailSearchResultModel> {
        const searchResult = this.http.post<ICDetailSearchResultModel>(`${this.webApiEndPoint}search`, searchRequest, this.httpOptions)
            .pipe();
        return searchResult;
    }

    exportToExcel = (searchRequest: ICSearchRequest) => {
        return this.http.post(`${this.webApiEndPoint}exportToExcel`, searchRequest, this.httpOptionsString).pipe();
    }

    getMfrIcLcInfo(partySiteNumber: string): Observable<MFRIcLcDetailInformationModel> {
        const result = this.http.get<MFRIcLcDetailInformationModel>(`${this.webApiEndPoint}get/${partySiteNumber}`, this.httpOptions)
            .pipe();
        return result;
    }
}

<div class="px-20">
    <app-breadcrumb [levelOneTitle]="pageName" [levelTwoTitle]="levelTwoTitle" [activeTitleLevel]="activeLevel">
    </app-breadcrumb>
  </div>
  <div class="">
      <mat-card class="main-card mat-elevation-z2">
          <mat-card-header>
              <div class="header-text w-full">
                  <h4>CUSTOMER SEARCH</h4>
              </div>
             <!--  <div class="additional-menu float-right">
                  <button [matMenuTriggerFor]="menu" class="additional-menu-button" aria-label="Menu buttom">
                      <mat-icon>menu</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                      <button mat-menu-item class="additional-menu-item" (click)="exportToExcel()"
                              [disabled]="!hasSearchActionOccurred || noSearchResults">
                          <mat-icon>upload_file</mat-icon>
                          <span>Export to Excel</span>
                      </button>
                  </mat-menu>
              </div>-->
          </mat-card-header> 
          <mat-card-content class="main-card-content">
              <form #customerSearchForm="ngForm" (ngSubmit)="onSearch(customerSearchForm.value)">
                  <div class="mb-4 row">
                      <div class="col-md-3">
                          <label for="inputCustomerName" class="form-label">Customer Name</label>
                          <div>
                              <input type="text" name="customerName" #customerName="ngModel" [(ngModel)]="customerSearchModel.customerName"
                                     class="form-control" id="inputCustomerName" placeholder="Customer Name" />
                          </div>
                      </div>
                      <div class="col-md-3">
                        <label for="inputAccountNumber" class="form-label"> Account Number</label>
                        <div>
                            <input type="text" name="accountNumber" #accountNumber="ngModel"
                                   [(ngModel)]="customerSearchModel.accountNumber" class="form-control" id="inputAccountNumber"
                                   placeholder="Account Number" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inpupartysiteNumber" class="form-label">Party Site Number</label>
                        <div>
                            <input type="text" name="partysiteNumber" #partysiteNumber="ngModel"
                                   [(ngModel)]="customerSearchModel.partySiteNumber" class="form-control" id="inputpartysiteNumber"
                                   placeholder="Party Site Number" />
                        </div>
                    </div>
                      
                      
                  </div>
                  <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inputCCN" class="form-label">CCN</label>
                        <div>
                            <input type="text" name="ccn" #partysiteNumber="ngModel"
                                   [(ngModel)]="customerSearchModel.ccn" class="form-control" id="inputCCN"
                                   placeholder="CCN" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty form-label mt-8" name="compCCN"
                                      [(ngModel)]="customerSearchModel.compCCN">
                          Include Comp CCN
                        </mat-checkbox>
                    </div>
                    
                      <div class="col-md-3">
                          <label for="filterBy" class="form-label">Filter by Status</label>
                          <div>
                              <mat-select class="form-control" id="filterBy" name="filterBy" [(ngModel)]="customerSearchModel.filterBy">
                                  <mat-option [value]="options.value" *ngFor="let options of statusOptions">{{ options.key }}</mat-option>
                              </mat-select>
                          </div>
                      </div>
                      <div class="col-md-3">
                          <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty form-label mt-8" name="check"
                                        [(ngModel)]="customerSearchModel.exactSearch">
                              Exact Search
                          </mat-checkbox>
                      </div>
                  </div>
                  <div class="md-3 row">
                      <div class="col-md-8"></div>
                      <div class="text-right col-md-4">
                          <button class="mr-2 button form-clear-button" type="reset" (click)="resetForm()">Clear</button>
                          <button class="button form-button" type="submit" [disabled]="!customerSearchForm.form.valid">Search</button>
                      </div>
                  </div>
              </form>


              <div class="w-full m-0"
                   *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
                  <hr />
                  <app-paginator (sortingChange)='onSortPaginationChange($event)' [isTableView]="isTableView" (viewChange)='onViewChange($event)'
                                 (paginationChange)='onSortPaginationChange($event)' [length]="searchResultCount" [pageIndex]="0"
                                 [sortByOptions]="sortOptions" [showSortBy]="true" [showFilterBy]="false" [showPageSize]="true">
                  </app-paginator>
              </div>


          </mat-card-content>
      </mat-card>
      <ng-template #customerSearchContainer>
      </ng-template>
      <div *ngIf="noSearchResults && hasSearchActionOccurred">
          <app-no-data-found></app-no-data-found>
      </div>
      <div *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
          <div class="search-details-container">
              <div *ngIf="searchResult && searchResult.results && !isTableView">
                  <app-customer-search-result-card *ngFor="let cust of searchResult.results; let i = index;" [model]="cust">
                  </app-customer-search-result-card>
              </div>
              <div *ngIf="searchResult && searchResult.results && isTableView">
                  <app-customer-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results">
                  </app-customer-search-result-card>
              </div>
          </div>
      </div>
      <!-- <div class="loader-center" *ngIf="loading">
          <div id="loader" class="loader"></div>
      </div> -->
  </div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDetailsViewComponent } from './file-detail-view/file-detail-view.component';
import { FileSearchComponent } from './file-search/file-search.component';
import { FileSearchResultCardComponent } from './file-search-result-card/file-search-result-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateFileComponent } from './create-file/create-file.component';
import { MaterialModule } from '../material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UtilityModule } from '../utility';
import { AppRoutingModule } from '../app-routing.module';
import { OwnerAccountValidatorDirective } from './create-file/validation/owner-account-validator.directive';
import { JoyrideModule } from 'ngx-joyride';
import { UppercaseDirectiveModule } from '../shared/uppercase.directive';

@NgModule({
    declarations: [
        FileSearchComponent,
        FileSearchResultCardComponent,
        FileDetailsViewComponent,
        CreateFileComponent,
        OwnerAccountValidatorDirective
    ],
    imports: [
        JoyrideModule.forRoot(),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FontAwesomeModule,
        UtilityModule,
        AppRoutingModule,
        UppercaseDirectiveModule
    ],
    exports: [
        FileSearchComponent,
        FileSearchResultCardComponent,
        FileDetailsViewComponent,
        CreateFileComponent
    ],
})
export class FileDetailsModule {}

<ng-template #CreateNewProduct>
</ng-template>
<div class="d-flex flex-column mx-3 px-3">
    <div class="d-flex my-3 justify-content-between align-items-center">
        <div class="d-flex">
            <i (click)="onClose()" class="material-icons md-36 action-item mr-2">chevron_left</i>
            <h2 class="capitalize">{{ action }} Product</h2>
        </div>
    </div>
    <div class="my-2 py-1 px-3">
        <ng-form #createEditProductform="ngForm">
            <input type="hidden" [(ngModel)]="model.productCcnRecId" name="productCcnRecId" />
            <div class="form-group row">
                <label for="productType" class="col-sm-4 col-form-label">Product Type<span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" name="productType" #productType="ngModel"
                           [(ngModel)]="model.productType" class="form-control" id="inputProductType"
                           required [disabled]="action==='Edit'"
                           [ngClass]="{'is-invalid': (productType.touched || productType.dirty) && !productType.valid }" />
                    <span class="invalid-feedback">
                        <span *ngIf="productType.errors?.required">
                            Please enter product type
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="productCcn" class="col-sm-4 col-form-label required">CCN<span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" name="productCcn" [disabled]="action==='Edit'" #productCcn="ngModel" required
                           [(ngModel)]="model.productCcn" class="form-control" id="productCcn"
                           [ngClass]="{'is-invalid': (productCcn.touched || productCcn.dirty) && !productCcn.valid }" 
                           minlength="4" maxlength="5" pattern="^[A-Za-z0-9]*$" appUppercase/>
                    <span class="invalid-feedback">
                        <span *ngIf="productCcn.errors?.required">
                            Please enter the ccn
                        </span>
                        <span *ngIf="productCcn.errors?.minlength">
                            The CCN value should be of minimum 4 characters long.
                        </span>
                        <span *ngIf="productCcn.errors?.pattern">
                            The CCN value can contain only letters and numbers.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="productDeliverable" class="col-sm-4 col-form-label">Deliverable</label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="productDeliverable"
                            name="deliverable" [(ngModel)]="model.deliverable" #deliverable="ngModel">
                        <option [value]="option" *ngFor="let option of deliverableOption">{{ option }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="productDeliverableType" class="col-sm-4 col-form-label">Deliverable Type</label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="productDeliverableType"
                            name="deliverableType" [(ngModel)]="model.deliverableType" #deliverableType="ngModel">
                        <option [value]="type" *ngFor="let type of deliverableTypes">{{ type }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="typeOfService" class="col-sm-4 col-form-label">Type of Service<span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="typeOfService" required
                            name="typeOfService" [(ngModel)]="model.typeOfService" #typeOfService="ngModel"
                            [ngClass]="{'is-invalid': (typeOfService.touched || typeOfService.dirty) && !typeOfService.valid }">
                        <option [value]="service" *ngFor="let service of serviceType">{{ service }}</option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="typeOfService.errors?.required">
                            Please select the type of service
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="fusSampleLines" class="col-sm-4 col-form-label">Fus Sample Lines</label>
                <div class="col-sm-8">
                    <input type="text" name="fusSampleLines" #fusSampleLines="ngModel"
                           [(ngModel)]="model.fusSampleLines" class="form-control" id="fusSampleLines" />
                </div>
            </div>
            <div class="form-group row">
                <label for="industry" class="col-sm-4 col-form-label">Industry</label>
                <div class="col-sm-8">
                    <input type="text" name="industry" #industry="ngModel"
                           [(ngModel)]="model.industry" class="form-control" id="industry" />
                    <!--<select matInput class="form-control" id="industry"
                    name="industry" [(ngModel)]="model.industry" #industry="ngModel">
                <option [value]="option.value" *ngFor="let option of industryOptions">{{ option.key }}</option>
            </select>-->
                </div>
            </div>
            <div class="form-group row">
                <label for="subIndustry" class="col-sm-4 col-form-label">Sub Industry</label>
                <div class="col-sm-8">
                    <input type="text" name="subIndustry" #subIndustry="ngModel"
                           [(ngModel)]="model.subIndustry" class="form-control" id="subIndustry" />
                </div>
            </div>
            <div class="form-group row">
                <label for="isMlProduct" class="col-sm-4 col-form-label">ML</label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="isMlProduct"
                            name="isML" [(ngModel)]="model.isML" #isML="ngModel">
                        <option [value]="option.value" *ngFor="let option of mlOptions">{{ option.key }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="invoiceText" class="col-sm-4 col-form-label">Invoice Text</label>
                <div class="col-sm-8">
                    <input type="text" name="invoiceText" #invoiceText="ngModel"
                           [(ngModel)]="model.invoiceText" class="form-control" id="invoiceText" />
                </div>
            </div>
            <div class="d-flex flex-row my-3">
                <button type="submit" class="btn btn-copy" (click)="onSubmit()" [disabled]="!createEditProductform.valid"
                        [ngClass]="{'cursor-not-allowed': !createEditProductform.valid}">
                    {{ action }}
                </button>
                <button type="submit" class="btn btn-outline-secondary ml-3" (click)="onClose()">
                    Cancel
                </button>
            </div>
        </ng-form>
    </div>
</div>
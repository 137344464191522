import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ToastNotificationService } from './shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from './shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationPlacement } from './shared/toastnotification/toast-notification-placement.model';
import { SideNavService } from './shared/side-nav/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { DataService } from './core/services/data.service';
import { ClientSettingsService } from './core/services/client-settings.service';
import { ModalDialogComponent } from './shared/modal-dialog/modal-dialog.component';
import { Constants } from './constants';
import { AppInsightsService } from './app-insights/app-insights.service';
import { PreviousRouteService } from './shared/previous-route.service';
import { InformationWindowService } from './shared/informationwindow/informationwindow.service';
import { InformationWindowComponent } from './shared/informationwindow/informationwindow.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('sideNav', { static: false }) sideNav: MatSidenav;
    title = 'IFVS';
    isExpanded = false;
    userLoggedIn = false;
    backgroundColor = Constants.PRIMARY_LIGHT;
    public message!: ToastNotificationMessage;
    public idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date | null = null;
    private _toastSubscription!: Subscription;
    private _authLoggedInSubscription!: Subscription;

    constructor(
        private authService: AuthService,
        private toastService: ToastNotificationService,
        private sideNavService: SideNavService,
        private dataService: DataService,
        private clientSetting: ClientSettingsService,
        private adalService: MsAdalAngular6Service,
        private idle: Idle,
        private keepalive: Keepalive,
        private modalService: NgbModal,
        private appInsightsService: AppInsightsService,
        private informationWindowService: InformationWindowService,
        private previousRouteService: PreviousRouteService
    ) {
        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(parseInt(clientSetting.clientSettings.idleTimeToDisplayModalInSeconds, 10));

        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(parseInt(clientSetting.clientSettings.idleTimeToLogoutAfterDisplayingModalInSeconds, 10));

        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
            console.log(this.idleState);
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            console.log(`User did not respond that they wanted to stay or logout,      so they are being automatically logged out of the system.  IdleState ${this.idleState}`);
            this.timedOut = true;
            this.onlogoutFromModal();
        });

        idle.onIdleStart.subscribe(() => {
            this.idleState = "You've gone idle!";
            console.log(`The User has gone idle.  idleState: ${this.idleState}  show Modal to user`);

            const dialogRef = this.modalService.open(ModalDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
            dialogRef.componentInstance.data = {
                title: Constants.confirmIdleAutoLogoutTitle,
                confirmationTitle: Constants.confirmIdleAutoLogoutHeader,
                confirmationHtmlLine1: Constants.confirmIdleAutoLogoutContentLine1,
                confirmationHtmlLine2: Constants.confirmIdleAutoLogoutContentLine2,
                cancelButton: Constants.confirmIdleAutoLogoutCancelText,
                confirmButton: Constants.confirmIdleAutoLogoutStayConfirmText,
            };
            dialogRef.result
                .then((result) => {
                    console.log('MODAL BUTTON RESULT', result);
                    if (result === 'submit') {
                        console.log('User wanted to logout');
                        this.onlogoutFromModal();
                    }
                })
                .catch((res) => {
                    console.log('MODAL CANCEL', res);
                    if (res === 'cancel') {
                        console.log('User wanted to Stay Logged In, so we will not automatically log them out.');
                        this.reset();
                    }
                });
        });

        idle.onTimeoutWarning.subscribe((countdown: number) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds and automatically be logged out.';
            console.log(this.idleState);
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

        this._authLoggedInSubscription = this.authService.getUserLoggedIn()
            .subscribe((isUserLoggedIn: boolean) => {
                if (isUserLoggedIn) {
                    idle.watch();
                    this.timedOut = false;
                } else {
                    idle.stop();
                }
            });
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    logout() {
        this.appInsightsService.clearUserId();
        this.authService.logout();
    }

    onlogoutFromModal() {
        this.logout();
    }

    isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

    get diagnostic() {
        return JSON.stringify(this.message);
    }

    ngOnInit(): void {
        this.userLoggedIn = this.isLoggedIn();
        this.dataService.getMessage().subscribe((backgroundColor) => (this.backgroundColor = backgroundColor));
        this._toastSubscription = this.toastService.toastNotificationMessage$.subscribe(
            (message: ToastNotificationMessage | null) => {
                // console.log(`Receive Toast Message: ${JSON.stringify(message)}`);
                if (message) {
                    if (message.placement === ToastNotificationPlacement.Body) {
                        // The broadcaster of this toast didn't indicate that it should be shown in the drawer only,
                        // so we will display it in the main body of the website.
                        this.message = message;
                    }
                }
            }
        );
    }

    ngAfterViewInit(): void {
        this.sideNavService.setSideNav(this.sideNav);
        if (this.adalService.isAuthenticated) {
            this.authService.setUserLoggedIn(true);

            this.informationWindowService.setUserName(this.authService.getUser().userName.split("@")[0]);
            this.informationWindowService.DataRefresh();
            if (this.informationWindowService.getPopupStatus()) {
                const popupModal = this.modalService.open(InformationWindowComponent, {
                    windowClass: 'modal-zindex infopopupmodal',
                    keyboard: false,
                    backdrop: 'static',
                    size: 'xl',
                    scrollable: true
                });

                popupModal.result.then(ResultData => {
                    if (ResultData.Action === 'Confirm') { this.informationWindowService.UpdateUserData(ResultData.Action); }
                    else if (ResultData.Action === 'Skip') { this.informationWindowService.UpdateUserData(ResultData.Action); }
                }).catch(ResultData => {
                    if (ResultData.Action === 'Confirm') { this.informationWindowService.UpdateUserData(ResultData.Action); }
                    else if (ResultData.Action === 'Skip') { this.informationWindowService.UpdateUserData(ResultData.Action); }
                });
            }
        }
        else { this.authService.setUserLoggedIn(false); }
    }

    ngOnDestroy(): void {
        if (this._toastSubscription) {
            this._toastSubscription.unsubscribe();
        }
        if (this._authLoggedInSubscription) {
            this._authLoggedInSubscription.unsubscribe();
        }
    }
    sideNavClosedStart(): void {
        this.sideNavService.closeSidenav();
    }
}

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ClientSettingsService } from '../services/client-settings.service';
import { WdrwlCodesResultModel, RespOfcCodesResultModel, GenerateAuthPageModel } from '../../shared/utility.model';
import { Constants } from '../../constants';
import { StandardDateModel } from '../../models/standard-date.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public subject = new Subject<any>();
    public currentMessage;
    private data = {};
    private color = Constants.PRIMARY_LIGHT;
    private modelData = new Subject<any>();
    private messageSource = new BehaviorSubject(this.color);

    private webApiEndPoint: string;
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private clientSetting: ClientSettingsService,
        private authService: AuthService,
        private http: HttpClient) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/`;
    }

    setModelData(modelData: any) {
        this.modelData.next(modelData);
    }

    clearModelData() {
        this.modelData.next(null);
    }

    getModelData(): Observable<any> {
        return this.modelData.asObservable();
    }

    setOption(option, value) {
        this.data[option] = value;
    }

    getOption(option) {
        return this.data[option];
    }

    getMessage() {
        return this.messageSource.asObservable();
    }
    changeMessage(message: string) {
        this.messageSource.next(message);
    }

    /**
     * To check if the form values
     * are empty
     * @param obj
     */
    isEmpty(obj) {
        let isValid = false;
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key !== 'exactSearch' && key !== 'pageSize' && key !== 'pageNumber'
                    && key !== 'sortDirection' && key !== 'sortBy' && key !== 'filterBy') {
                    if (obj[key] === '' || obj[key] == null || obj[key] == undefined) {
                        isValid = false;
                    } else {
                        isValid = true;
                        return isValid;
                    }
                }
            }
        }
        return isValid;
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError('Something bad happened; please try again later.');
    }

    /**
     * This function will get the
     * withdrawal codes
     */
    getWdrwlCodes(): Observable<WdrwlCodesResultModel> {
        const searchResult = this.http.get<WdrwlCodesResultModel>(`${this.webApiEndPoint}Utility/wdrwlCodes`, this.httpOptions).pipe();
        return searchResult;
    }

    /**
     * This function will get the
     * responsible office codes
     */
    getRespOfcCodes(): Observable<RespOfcCodesResultModel> {
        const searchResult = this.http.get<RespOfcCodesResultModel>(`${this.webApiEndPoint}Utility/respOfcCode`, this.httpOptions).pipe();
        return searchResult;
    }

    /**
     * This will return the last updated
     * date in the required format
     */
    getLastUpdatedDate(): string {
        const currentDate = new Date();
        let year, month, date;
        year = currentDate.getFullYear();
        month = currentDate.getMonth() + 1;
        date = currentDate.getDate();
        if (date.toString().length == 1) {
            date = `0${date}`;
        }
        if (month.toString().length == 1) {
            month = `0${month}`;
        }
        return `${year}-${month}-${date}`;
    }

    /**
     * This will return the last updated
     * by employee Id
     */
    getLastUpdatedBy(): string {
        return this.authService.getUser().userName.split("@")[0];
    }

    /**
     * This function will convert the Standard Date Model type
     * date to a custom date format or will take the current
     * date and format it to the custom format
     * @param dateString
     */
    customDateFormat(dateString): string {
        let year, month, date;
        if (dateString) {
            year = dateString.year.value ? dateString.year.value : dateString.year;
            month = dateString.month.value ? dateString.month.value : dateString.month;
            date = dateString.day.value ? dateString.day.value : dateString.day;
        } else {
            const _date = new Date();
            year = _date.getFullYear();
            month = _date.getMonth() + 1;
            date = _date.getDate();
        }
        if (date.toString().length == 1) {
            date = `0${date}`;
        }
        if (month.toString().length == 1) {
            month = `0${month}`;
        }
        return `${year}${month}${date}`;
    }

    /**
     * This function will return the date in the 
     * Standard Date Model type
     * @param date
     */
    getDate: (date: string) => any = function (date) {
        if (date) {
            const _date = new Date(date);
            const formatedDate: StandardDateModel = {
                year: _date.getFullYear(),
                month: _date.getMonth() + 1,
                day: _date.getDate()
            }
            return formatedDate;
        }
    };

    /**
     * This will return the current date
     * @param splitter
     */
    getCurrentDate(splitter: string = "/"): string {
        const _date = new Date();
        return `${_date.getFullYear()}${splitter}${_date.getMonth() + 1}${splitter}${_date.getDate()}`
    };

    /**
     * This will convert the give 
     * date object in to correct
     * format required to set in
     * the input field
     * @param dateObj
     */
    formatDate(dateObj: string, splitter: string = "/"): string {
        if (dateObj) {
            const year = dateObj.substring(0, 4);
            const month = dateObj.substring(4, 6);
            const date = dateObj.substring(6, dateObj.length);

            return `${year}${splitter}${month}${splitter}${date}`
        }
        return "";
    }

    /**
     * This function is used to scroll to 
     * the top of the page automatically
     */
    navigateToPageTop() {
        document.querySelector('mat-sidenav-content').scrollTop = 0;
    }

    /**
     * This method is used to scroll to the
     * top of the side nav page
     */
    navigateToInnerSideNavTop() {
        document.querySelector('mat-sidenav').scrollTop = 0;
    }
    /**
     * This function is to validate
     * whether the chosen date is
     * less than or equal to the 
     * current date, as future date 
     * cannot be selected
     * @param dateString
     */
    validateStandardDate(dateString) {
        if (dateString) {
            const _date = new Date();
            const selectedDate = new Date(`${dateString.year}/${dateString.month}/${dateString.day}`);

            if (selectedDate > _date)
                return true;
            else
                return false;
        }
        return false;
    }

    /**
     * This function will check if year, 
     * month and date is selected for the
     * date
     * @param dateString
     */
    checkIfDateIsEmpty(dateString) {
        let selectedYear, selectedMonth, selectedDate;
        if (dateString) {

            selectedYear = dateString.year;
            selectedMonth = dateString.month;
            selectedDate = dateString.day;

            if (selectedYear && selectedMonth && selectedDate)
                return false;
            else
                return true;
        }
    }

    /**
     * This method will return the
     * responsible office Id based 
     * on the value chosen
     * 
     * @param value
     * @param respOfcCodes
     */
    getRespOfficeId(value, respOfcCodes): number {
        let id = 0;
        for (let i = 0; i < respOfcCodes.length; i++) {
            if (respOfcCodes[i].respOfc == value) {
                id = respOfcCodes[i].respOfcRecId;
                break;
            }
        }
        return id;
    }

    /**
     * This method will return the 
     * withdrawal id based on the
     * code chosen
     * 
     * @param value
     * @param wdrwlCodes
     */
    getWithdrawalId(value, wdrwlCodes): number {
        let id = 0;
        wdrwlCodes.forEach((data) => {
            if (data.wdrwlCode == value) {
                id = data.wdrwlRecId;
            }
        });
        return id;
    }

    /**
     * This function will trigger
     * the Generate Auth page API
     */
    generateAuthPage(request: GenerateAuthPageModel): Observable<Object> {
        const searchResult = this.http.put<boolean>(`${this.webApiEndPoint}Utility/generateAuthPage`, request, this.httpOptions).pipe();
        return searchResult;
    }

    /**
     * This will give a comma seperated string
     * of comp ccn for the particular manufacturer
     * @param compCcn
     */
    formatCompCCN = (compCcn) => {
        if (compCcn) {
            let temp = "";
            compCcn.forEach(data => {
                if (data.compCCN != null) {
                    const record = data.compCCN.trim();
                    if (record != "")
                        temp += `${record}, `;
                }
            });
            return temp.substring(0, temp.length - 2);
        }
        return "";
    }

    isObjEquals = (object1, object2) => {
        if (object1 === object2) return true;
        if (object1 === null || object2 === null) return false;
        if (object1 !== object1 && object2 !== object2) return true;

        const objKeys1 = Object.keys(object1);
        const objKeys2 = Object.keys(object2);
        if (objKeys1.length !== objKeys2.length) return false;

        for (var key of objKeys1) {
            const value1 = object1[key];
            const value2 = object2[key];
            const isObjects = this.isObjCheck(value1) && this.isObjCheck(value2);
            if ((isObjects && !this.isObjEquals(value1, value2)) || (!isObjects && value1 !== value2)) { return false; }
        }
        return true;
    }

    isObjCheck = (object) => {
        return object != null && typeof object === "object";
    }
}

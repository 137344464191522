import {
    Component, OnInit, AfterViewInit, Input, Output,
    EventEmitter, ComponentFactoryResolver, ViewChild, ViewContainerRef
} from '@angular/core';
import { state, style, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { DataService } from '../../core/services/data.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import {
    FileVolumeSummary, WithdrawOrDeleteEventModel, ComplementaryCcn,
    ReactivateVolume, ReactivateVolumeOutput, FileVolumeSearchRequest
} from '../file-volume-detail-search.model';
import { WdrwlCodesSummary, GenerateAuthPageRecord, GenerateAuthPageModel } from '../../shared/utility.model';
import { Constants } from '../../constants';
import { WithdrawOrDeleteFilevolume } from '../withdraw-or-delete-filevolume';
import { CreateEditFileVolume } from '../create-file-volume/create-file-volume.model';
import { CreateListeeModel } from '../../listee/create-listee/create-listee.model';
import { CreateEditMfrModel } from '../../mfr-detail/create-new-mfr/create-mfr.model';
import { DeliverableItemInstance } from '../../mfr-detail/mfr-detail-search.model';
import { MatTableDataSource } from "@angular/material/table";
import { MfrDetailService } from "../../mfr-detail/mfr-detail.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { FileVolumeService } from '../file-volume.service';
import { MatSort } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';
import { FileDetailsService } from '../../file-details/file-details.service';

@Component({
    selector: 'app-file-volume-search-result-card',
    templateUrl: './file-volume-search-result-card.component.html',
    styleUrls: ['./file-volume-search-result-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
        ])
    ]
})
export class FileVolumeSearchResultCardComponent implements OnInit, AfterViewInit {
    @ViewChild('fileVolumeSearchContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    @ViewChild(MatSort) sort: MatSort;

    private tableDataSource = new MatTableDataSource<FileVolumeSummary>([]);
    columnsToDisplay = Constants.FILE_VOL_RESULT_TABLE_COLUMNS;
    expandedElement: FileVolumeSummary | null;
    previousUrl: string = null;
    sortBy = Constants.SortBy;
    indexExpanded: number = -1;
    isAdmin: boolean;
    isEdit: boolean;

    searchDataResults: FileVolumeSummary[] | null;
    searchRequest: FileVolumeSearchRequest = {
        fileNumber: '',
        volume: '',
        ownerAccount: '',
        ccn: '',
        compCCN: true,
        subscriberNumber: '',
        partySiteNumber: '',
        fileRecId: 0,
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: "asc",
        sortBy: "volume",
        filterBy: Constants.ALL,
        volumeType: Constants.ALL,
    };

    @Input() isTableView: boolean;
    @Input() wdrwlCodes: WdrwlCodesSummary[];
    @Input() set currentSort(value: Sort) {
        this.searchRequest.sortBy = value.active;
        this.searchRequest.sortDirection = value.direction;
    }
    @Input() set tableData(value: FileVolumeSummary[]) {
        if (this.isTableView) { this.tableDataSource = new MatTableDataSource(value); }
        else { this.searchDataResults = value; this.indexExpanded = -1; }
        this.checkIfSelected();
    }
    @Output() withdrawOrDeleteAction: EventEmitter<WithdrawOrDeleteEventModel> = new EventEmitter();
    @Output() sortingChangeAction: EventEmitter<FileVolumeSearchRequest> = new EventEmitter();

    constructor(private router: Router,
        private dataService: DataService,
        private withdrawOrDelete: WithdrawOrDeleteFilevolume,
        private sideNavService: SideNavService,
        private mfrService: MfrDetailService,
        private modalService: NgbModal,
        private resolver: ComponentFactoryResolver,
        private toastService: ToastNotificationService,
        private previousRouteService: PreviousRouteService,
        private volumeService: FileVolumeService,
        private fileService: FileDetailsService
    ) {
        this.previousUrl = this.previousRouteService.getPreviousUrl();
    }

    formatDate(dateObj: string, splitter: string = "-") {
        return this.dataService.formatDate(dateObj, splitter);
    }

    ngOnInit(): void {
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
    }

    ngAfterViewInit() {
        //this.checkIfSelected();
    }

    sortData(sort: Sort) {
        const SortByColumn = sort.active; // This will give the name of the column currently being sorted
        if (this.searchRequest.sortBy === SortByColumn) {
            switch (this.searchRequest.sortDirection) {
                case this.sortBy.ASC:
                    this.searchRequest.sortDirection = this.sortBy.DESC;
                    break;
                case this.sortBy.DESC:
                    this.searchRequest.sortDirection = this.sortBy.ASC;
                    break;
                default: break;
            }
        } else { this.searchRequest.sortDirection = this.sortBy.ASC; }
        this.searchRequest.sortBy = SortByColumn;
        this.sortingChangeAction.emit(this.searchRequest);
    }

    viewMfr(model: FileVolumeSummary): void {
        this.dataService.setModelData(model);
        const fileRecId = model.fileRecId;
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        const urlKeyword = this.previousUrl.split('/');
        if (urlKeyword.indexOf(Constants.FILE_DETAILS) != -1) {
            this.router.navigateByUrl(`/file-details/${fileRecId}/mfr-details/${model.fileVolRecId}`);
        } else {
            this.router.navigateByUrl(`/file-volume/${model.fileVolRecId}/mfr-details`);
        }
    }

    viewListee(model: FileVolumeSummary): void {
        this.dataService.setModelData(model);
        const fileRecId = model.fileRecId;
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        const urlKeyword = this.previousUrl.split('/');
        if (urlKeyword.indexOf(Constants.FILE_DETAILS) != -1) {
            this.router.navigateByUrl(`/file-details/${fileRecId}/listee-details/${model.fileVolRecId}`);
        } else {
            this.router.navigateByUrl(`/file-volume/${model.fileVolRecId}/listee-details`);
        }
    }

    createMfr(modelData: FileVolumeSummary) {
        let deliverableInstance: DeliverableItemInstance[];
        this.wdrwlCodes = this.dataService.getOption(Constants.WDRWL_CODES);

        deliverableInstance = [
            {
                deliverableItemInstanceId: 0,
                deliverableItemInstanceName: '',
                action: Constants.ADD
            },
        ];

        const model: CreateEditMfrModel = {
            fileRecId: modelData.fileRecId,
            fileVolRecId: modelData.fileVolRecId,
            mfrAssoRecId: 0,
            fileNumber: modelData.fileNumber,
            volume: modelData.volume,
            startDate: this.dataService.getCurrentDate(),
            endDate: null,
            deliverableItemInstance: deliverableInstance,
            mfrAccountNumber: modelData.ownerAccount,
            orgNumber: '',
            mfrPartySiteNumber: modelData.ownerPartySiteNumber,
            mfrPartyNumber: '',
            mfrSubscriberNumber: '',
            wdrwlRecId: 0,
            wdrwlCode: '',
            mfrCompanyName: '',
            mfrCompanyAddress: '',
            serviceContract: '',
            mfrType: null,
            mfrFactoryId: '',
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            mfrStatus: 'A',
            isAddToAllVolume: false,
            volumeList: '',
            mfrAddress1: '',
            mfrAddress2: '',
            mfrAddress3: '',
            mfrAddress4: '',
            mfrAddress5: '',
            mfrAddress6: '',
            mfrCity: '',
            mfrProvince: '',
            mfrState: '',
            mfrCountry: '',
            mfrPostalCode: ''
        };
        this.sideNavService.createEditMfr(model, this.wdrwlCodes);
    }

    addListee(modelData: FileVolumeSummary) {
        const model: CreateListeeModel = {
            fileVolRecId: modelData.fileVolRecId,
            listeeRecId: 0,
            listeeFileNumber: modelData.fileNumber,
            accountNumber: modelData.ownerAccount,
            orgNumber: '',
            listeePartySiteNumber: modelData.ownerPartySiteNumber,
            subscriberNumber: '',
            companyName: '',
            companyAddress: '',
            isActive: 'Y',
            listeePartyNumber: null,
            associationType: '',
            startDate: this.dataService.getCurrentDate(),
            endDate: null,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            listeeAddress1: '',
            listeeAddress2: '',
            listeeAddress3: '',
            listeeAddress4: '',
            listeeAddress5: '',
            listeeAddress6: '',
            listeeCity: '',
            listeeProvince: '',
            listeeState: '',
            listeeCountry: '',
            listeePostalCode: '',
            isAddToAllVolume: false
        };
        this.sideNavService.createListee(model);
    }

    editVolume(fileVolume: FileVolumeSummary) {
        let compCCN: ComplementaryCcn[];
        if (fileVolume.complementaryCCN) { compCCN = fileVolume.complementaryCCN; }
        else {
            compCCN = [
                {
                    compCCNRecId: 0,
                    compCCN: '',
                    fileVolRecId: null,
                    action: Constants.ADD
                },
            ];
        }
        const model: CreateEditFileVolume = {
            volumeType: fileVolume.volumeType,
            volume: fileVolume.volume,
            startDate: this.dataService.formatDate(fileVolume.startDate),
            endDate: this.dataService.formatDate(fileVolume.endDate),
            basicCcn: fileVolume.basicCcn,
            complementaryCCN: compCCN,
            ownerFileNo: fileVolume.fileNumber,
            ownerAccount: fileVolume.ownerAccount,
            ownerPartySiteNumber: fileVolume.ownerPartySiteNumber,
            volumeStatus: fileVolume.fileVolumeStatus,
            serviceContratctNumber: null,
            isListee: fileVolume.isListee,
            fileRecId: fileVolume.fileRecId,
            wdrlRecId: fileVolume.wdrlRecId,
            markCode: fileVolume.markCode,
            respOfcRecId: fileVolume.respOfcRecId,
            respOffice: fileVolume.responsibleOffice,
            wdrwlCode: fileVolume.wdrwlCode,
            isSetUpComplete: fileVolume.isSetupComplete,
            fileVolRecId: fileVolume.fileVolRecId,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            lastUpdatedOn: this.dataService.getLastUpdatedDate()
        };
        this.sideNavService.addVolume(model, this.wdrwlCodes, compCCN, 'edit');
    }

    withdrawVolume(fileVolRecId: number) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.withdrawFileVolume(fileVolRecId, this.wdrwlCodes, componentRef).subscribe(result => {
            componentRef.destroy(); // To destroy the load spinner component
            if (result) { this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: true }); }
            else { this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false }); }
        }, error => {
            componentRef.destroy(); // To destroy the load spinner component
            this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false });
        });
    }

    deleteVolume(fileVolRecId: number) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.deleteFileVolume(fileVolRecId, componentRef).subscribe(result => {
            componentRef.destroy(); // To destroy the load spinner component
            if (result) { this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: true }); }
            else { this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: false }); }
        }, error => {
            componentRef.destroy(); // To destroy the load spinner component
            this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: false });
        });
    }

    onAuthPageClick(event, fileNumber: string, volume: string, recId: number, modelData: FileVolumeSummary) {
        let tempArray = [];
        let tempRecord: GenerateAuthPageRecord = {
            fileNum: '',
            volume: '',
            fileRecId: '',
            fileVolRecId: ''
        };
        const currentRecord = this.dataService.getOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID);
        if (event.checked) {
            this.fileService.checkIfAgencyFile(modelData.fileRecId).subscribe(result => {
                if (result) {
                    const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                    dialogRef.componentInstance.isCancelButtonRequired = false;
                    dialogRef.componentInstance.data = {
                        title: 'Error Message',
                        confirmationInfo: 'Authpage cannot be generated for Agency file type volume(s)',
                        cancelButton: 'No',
                        confirmButton: 'Ok'
                    };
                    dialogRef.result
                        .then(result => {
                            event.source.checked = false;
                            modelData.isAuthPage = false;
                        });
                }
                else {
                    this.mfrService.getActiveMfr(recId).subscribe(result => {
                        if (result) {
                            tempRecord = {
                                fileNum: fileNumber,
                                volume: volume,
                                fileRecId: '',
                                fileVolRecId: ''
                            }
                            if (currentRecord && currentRecord.length > 0) {
                                currentRecord.push(tempRecord);
                                this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, currentRecord);
                            }
                            else {
                                tempArray.push(tempRecord);
                                this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, tempArray);
                            }
                        }
                        else {
                            const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                            dialogRef.componentInstance.isCancelButtonRequired = false;
                            dialogRef.componentInstance.data = {
                                title: 'Alert Message',
                                confirmationInfo: 'Invalid file volume record selected to generate Authpage.No Active MFR associated with the record',
                                cancelButton: 'No',
                                confirmButton: 'Ok'
                            };
                            dialogRef.result
                                .then(result => {
                                    event.source.checked = false;
                                    modelData.isAuthPage = false;
                                });
                        }
                    }, error => {
                        event.source.checked = false;
                        modelData.isAuthPage = false;
                        this.authpageError(volume);
                    })
                }
            }, error => {
                event.source.checked = false;
                modelData.isAuthPage = false;
                this.authpageError(volume);
            });
        }
        else {
            const index = currentRecord.findIndex(x => x.fileNum === fileNumber && x.volume === volume);
            if (index !== undefined) currentRecord.splice(index, 1);
            this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, currentRecord);
        }
    }

    checkIfSelected() {
        const existingRec = this.dataService.getOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID);
        if (!this.isTableView) {
            if (existingRec && existingRec.length > 0) {
                const length = existingRec.length;
                const modelLength = this.searchDataResults.length;
                for (let i = 0; i < modelLength; i++) {
                    for (let j = 0; j < length; j++) {
                        if (existingRec[j].fileNum == this.searchDataResults[i].fileNumber
                            && existingRec[j].volume == this.searchDataResults[i].volume) {
                            this.searchDataResults[i].isAuthPage = true;
                            break;
                        }
                    }
                }
            }
        }
        else {
            if (existingRec && existingRec.length > 0) {
                const length = existingRec.length;
                const modelLength = this.tableDataSource.data.length;
                for (let i = 0; i < modelLength; i++) {
                    for (let j = 0; j < length; j++) {
                        if (existingRec[j].fileNum == this.tableDataSource.data[i].fileNumber
                            && existingRec[j].volume == this.tableDataSource.data[i].volume) {
                            this.tableDataSource.data[i].isAuthPage = true;
                            break;
                        }
                    }
                }
            }
        }
    }

    generateAuthPage = (model) => {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.fileService.checkIfAgencyFile(model.fileRecId).subscribe(result => {
            if (result) {
                const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                dialogRef.componentInstance.isCancelButtonRequired = false;
                dialogRef.componentInstance.data = {
                    title: 'Error Message',
                    confirmationInfo: 'Authpage cannot be generated for Agency file type volume(s)',
                    cancelButton: 'No',
                    confirmButton: 'Ok'
                };
                dialogRef.result.then(result => { componentRef.destroy(); });
            }
            else {
                this.mfrService.getActiveMfr(model.fileVolRecId).subscribe(result => {
                    if (result) {
                        let tempArray = [];
                        let tempRecord: GenerateAuthPageRecord = {
                            fileNum: model.fileNumber,
                            volume: model.volume,
                            fileRecId: '',
                            fileVolRecId: model.fileVolRecId
                        };
                        tempArray.push(tempRecord);
                        const empId = this.dataService.getLastUpdatedBy();
                        const request: GenerateAuthPageModel = {
                            inputType: "FILE_VOL",
                            empId: empId,
                            records: tempArray
                        };
                        this.dataService.generateAuthPage(request).subscribe(result => {
                            componentRef.destroy();
                            if (result["OverallStatus"] == Constants.SUCCESS) {
                                const successMessage = `Successfully generated the Auth page for the file volume ${model.volume}.`;
                                const type = ToastNotificationType.success;
                                const placement = ToastNotificationPlacement.Body;
                                const message = new ToastNotificationMessage(successMessage, type, placement);
                                this.toastService.sendToastNotificationMessage(message);
                                this.dataService.navigateToPageTop();
                                this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, []);
                                this.volumeService.onRefreshSearchResult();
                            }
                            else {
                                var errorMessage = `Error while generating the Auth page for the file volume ${model.volume}.`;
                                if (result["ErrorMessage"] != "") {
                                    errorMessage = result["ErrorMessage"];
                                }
                                const type = ToastNotificationType.error;
                                const placement = ToastNotificationPlacement.Body;
                                const message = new ToastNotificationMessage(errorMessage, type, placement);
                                this.toastService.sendToastNotificationMessage(message);
                                this.dataService.navigateToPageTop();
                            }
                        }, error => {
                            componentRef.destroy();
                            this.authpageError(model.volume);
                        });
                    }
                    else {
                        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                        dialogRef.componentInstance.isCancelButtonRequired = false;
                        dialogRef.componentInstance.data = {
                            title: 'Alert Message',
                            confirmationInfo: 'Invalid file volume record selected to generate Authpage.No Active MFR associated with the record',
                            cancelButton: 'No',
                            confirmButton: 'Ok'
                        };
                        dialogRef.result.then(result => { componentRef.destroy(); });
                    }
                }, error => {
                    componentRef.destroy();
                    this.authpageError(model.volume);
                })
            }
        }, error => {
            componentRef.destroy();
            this.authpageError(model.volume);
        })
    }

    authpageError = (volume: string) => {
        const errorMessage = `Error while generating the Auth page for the file volume ${volume}.`;
        const type = ToastNotificationType.error;
        const placement = ToastNotificationPlacement.Body;
        const message = new ToastNotificationMessage(errorMessage, type, placement);
        this.toastService.sendToastNotificationMessage(message);
        this.dataService.navigateToPageTop();
    }

    reactivateVolume(model: FileVolumeSummary, fileNumber: string, volume: string) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Re-Activate Volume',
            confirmationInfo: `Are you sure you want to re-activate the volume ${volume} of ${fileNumber} file?`,
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        const input: ReactivateVolume = {
            fileVolRecId: model.fileVolRecId,
            fileRecId: model.fileRecId,
            volumeType: model.volumeType,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            lastUpdatedOn: this.dataService.getLastUpdatedDate()
        }
        dialogRef.result.then(result => {
            this.volumeService.reactivateVolume(input).subscribe(result => {
                componentRef.destroy(); // To destroy the load spinner component
                if (result.result) {
                    this.dataService.navigateToPageTop();
                    const successMessage = `The volume ${volume} of ${fileNumber} and associated listee file has been re-activated successfully.`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.volumeService.onRefreshSearchResult();
                }
                else {
                    this.dataService.navigateToPageTop();
                    const errorMessage = result.message;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                this.dataService.navigateToPageTop();
                componentRef.destroy(); // To destroy the load spinner component
                const errorMessage = result.message;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
        }).catch(res => {
            if (res.action === 'no') {
                componentRef.destroy(); // To destroy the load spinner component
            }
        });
    }
}
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileDetailsService } from './file-details.service';
import { AuthService } from '../core/services/auth.service';
import { DataService } from '../core/services/data.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { WdrwlCodesSummary } from '../shared/utility.model';
import { FileWithdrawRequest } from './file-detail-search.model';
import { Observable, Subject  } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';

@Injectable({
    providedIn: 'root'
})

export class WithdrawOrDeleteFile {
    constructor(
        private dataService: DataService,
        private fileDetailsService: FileDetailsService,
        private modalService: NgbModal,
        private authService: AuthService) { }

    withdrawFile(fileRecId: number, wdrwlCodes: WdrwlCodesSummary[], componentRef): Observable<boolean> {
        let subject = new Subject<boolean>();
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.wdrwlCodeDetails = wdrwlCodes;
        dialogRef.componentInstance.data = {
            title: 'Withdraw File',
            confirmationInfo: 'Are you sure you want to withdraw the file? If yes please choose the withdrawal code',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const lastUpdatedBy = this.authService.getUser().userName.split("@")[0];
                    const lastUpdatedDate = this.dataService.getLastUpdatedDate();
                    const endDate = this.dataService.customDateFormat(null);
                    const withdrawRequest: FileWithdrawRequest = {
                        fileRecId: fileRecId,
                        wdrlStatus: 'W',
                        fileVolStatus: 'W',
                        wdrlRecId: result.wdrwlId,
                        mfrStatus: 'W',
                        listeeIsActive: 'N',
                        lastUpdatedBy: lastUpdatedBy,
                        lastUpdatedOn: lastUpdatedDate,
                        endDate: endDate
                    }
                    this.fileDetailsService.withdrawFile(withdrawRequest).subscribe((result) => {
                        if (result) {
                            subject.next(true);
                        }
                        else
                        {
                            subject.next(false);
                        }
                    },
                        error => {
                            subject.next(false);
                        });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
        return subject.asObservable();
    }

    deleteFile(fileRecId: number, componentRef): Observable<boolean> {
        let subject = new Subject<boolean>();
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Delete File',
            confirmationInfo: 'Are you sure you want to delete the file?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const userId = this.authService.getUser().userName.split("@")[0];
                    this.fileDetailsService.deleteFile(fileRecId, userId).subscribe((result) => {
                        if (result) {
                            subject.next(true);
                        }
                        else {
                            subject.next(false);
                        }
                    },
                        error => {
                            subject.next(false);
                        });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
        return subject.asObservable();
    }
}

import { DeliverableItemInstance } from '../mfr-detail-search.model';

export enum ActionType {
    create = 'Add New',
    edit = 'Edit',
}

export interface CreateEditMfrModel {
    fileVolRecId?: number;
    mfrAssoRecId: number;
    fileNumber: string;
    volume: string;
    startDate: string;
    endDate: string;
    deliverableItemInstance?: DeliverableItemInstance[];
    mfrAccountNumber: string;
    orgNumber: string;
    mfrPartySiteNumber: string;
    mfrPartyNumber: string;
    mfrSubscriberNumber: string;
    wdrwlRecId: number;
    wdrwlCode: string;
    mfrCompanyName: string;
    mfrCompanyAddress: string;
    serviceContract: string;
    mfrType: string;
    mfrFactoryId: string;
    lastUpdatedBy: string;
    mfrStatus: string;
    isAddToAllVolume: boolean;
    mfrAddress1: string;
    mfrAddress2: string;
    mfrAddress3: string;
    mfrAddress4: string;
    mfrAddress5: string;
    mfrAddress6: string;
    mfrCity: string;
    mfrProvince: string;
    mfrState: string;
    mfrCountry: string;
    mfrPostalCode: string;
    fileRecId?: number;
    volumeList: string;
}

export interface CheckMfrExistsRequest {
    fileVolRecId: number;
    partySiteNumber: string;
}
import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { CreateFileComponent } from '../../file-details/create-file/create-file.component';
import { CreateFileVolumeComponent } from '../../file-volume/create-file-volume/create-file-volume.component';
import { CreateEditFileVolume } from '../../file-volume/create-file-volume/create-file-volume.model';
import { SideNavInjector } from './side-nav-injector';
import { SideNavRef } from './side-nav-ref';
import { SideNavComponent } from './side-nav.component';
import { SidenavConfig } from './sidenav-config';
import { SidenavInsertionDirective } from './sidenav-insertion.directive';
import { WdrwlCodesSummary } from '../Utility.model';
import { CreateListeeComponent } from '../../listee/create-listee/create-listee.component';
import { CreateListeeModel } from '../../listee/create-listee/create-listee.model';
import { CreateNewMfrComponent } from '../../mfr-detail/create-new-mfr/create-new-mfr.component';
import { CreateEditMfrModel } from '../../mfr-detail/create-new-mfr/create-mfr.model';
import { CreateProductCcnComponent } from '../../product-ccn-association/create-product-ccn/create-product-ccn.component'
import { CreateEditProductCcnModel } from '../../product-ccn-association/create-product-ccn/create-product-ccn.model'
import { CreateEditFile } from '../../file-details/create-file-detail.model';
import { ComplementaryCcn } from '../../file-volume/file-volume-detail-search.model'

@Injectable({
    providedIn: 'root',
})
export class SideNavService {
    @ViewChild(SidenavInsertionDirective) entry: ViewContainerRef;
    public sideNav: MatSidenav;
    sideNavComponentRef: ComponentRef<SideNavComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) { }

    openSideNavWithComponent(componentType: Type<any>, config: SidenavConfig) {
        this.appendComponentToSidenav(config);
        this.sideNavComponentRef.instance.childComponentType = componentType;
        this.showSidenav();
    }
    showSidenav() {
        this.sideNav.open();
    }
    appendComponentToSidenav(config: SidenavConfig) {
        const map = new WeakMap();
        map.set(SidenavConfig, config);

        const sidenavRef = new SideNavRef();
        map.set(SideNavRef, sidenavRef);

        const sub = sidenavRef.afterClosed$.subscribe(() => {
            this.removeSidenavComponentFromBody();
            sub.unsubscribe();
        });

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            SideNavComponent
        );
        const componentRef = componentFactory.create(
            new SideNavInjector(this.injector, map)
        );

        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;
        document.getElementById('sideNav').innerHTML = '';
        document.getElementById('sideNav').appendChild(domElem);

        this.sideNavComponentRef = componentRef;

        this.sideNavComponentRef.instance.onClose.subscribe(() => {
            // console.log('onclose sub');
            this.closeSidenav();
            this.removeSidenavComponentFromBody();
        });
    }
    closeSidenav() {
        this.sideNav.close();
        this.removeSidenavComponentFromBody();
    }

    public setSideNav(sidenav: MatSidenav) {
        if (sidenav) {
            this.sideNav = sidenav;
        }
    }
    removeSidenavComponentFromBody() {
        this.appRef.detachView(this.sideNavComponentRef.hostView);
        this.sideNavComponentRef.destroy();
    }

    createEditFile(createEditFileModel: CreateEditFile, action = 'create') {
        const config: SidenavConfig = {};
        const data: any = {};
        data.createEditFileModel = createEditFileModel;
        data.sideNav = this.sideNav;
        config.data = data;
        config.data.action = action;
        this.openSideNavWithComponent(CreateFileComponent, config);
    }

    addVolume(createEditVolumeModel: CreateEditFileVolume, wdrwlCodes: WdrwlCodesSummary[], compCCN: ComplementaryCcn[] = [], action = 'create') {
        const config: SidenavConfig = {};
        const data: any = {};
        data.createEditVolumeModel = createEditVolumeModel;
        data.sideNav = this.sideNav;
        config.data = data;
        config.data.action = action;
        config.data.wdwrlCode = wdrwlCodes;
        config.data.compCCN = compCCN;
        this.openSideNavWithComponent(CreateFileVolumeComponent, config);
    }

    createEditMfr(createEditMfrModel: CreateEditMfrModel, wdrwlCodes: WdrwlCodesSummary[], action = 'create') {
        const config: SidenavConfig = {};
        const data: any = {};
        data.createMfrModel = createEditMfrModel;
        data.sideNav = this.sideNav;
        config.data = data;
        config.data.action = action;
        config.data.wdwrlCode = wdrwlCodes;
        this.openSideNavWithComponent(CreateNewMfrComponent, config);
    }

    createListee(createListeeModel: CreateListeeModel, action = 'create') {
        const config: SidenavConfig = {};
        const data: any = {};
        data.createListeeModel = createListeeModel;
        data.sideNav = this.sideNav;
        config.data = data;
        config.data.action = action;
        this.openSideNavWithComponent(CreateListeeComponent, config);
    }

    createEditProductCcn(createEditProductModel: CreateEditProductCcnModel, action = 'create') {
        const config: SidenavConfig = {};
        const data: any = {};
        data.createEditProductModel = createEditProductModel;
        data.sideNav = this.sideNav;
        config.data = data;
        config.data.action = action;
        this.openSideNavWithComponent(CreateProductCcnComponent, config);
    }
}

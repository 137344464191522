import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ActionType, CheckVolumeRequest, CreateEditFileVolume } from './create-file-volume.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { SideNavLoaderComponent } from '../../shared/side-nav-loader/side-nav-loader.component';
import { WdrwlCodesSummary, RespOfcCodesSummary } from '../../shared/utility.model';
import { FileDetailsService } from '../../file-details/file-details.service';
import { ComplementaryCcn } from '../file-volume-detail-search.model';
import { SidenavConfig } from '../../shared/side-nav/sidenav-config';
import { DataService } from '../../core/services/data.service';
import { FileVolCcnModel } from './create-file-volume.model';
import { FileVolumeService } from '../file-volume.service';
import { Constants } from '../../constants';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-create-file-volume',
    templateUrl: './create-file-volume.component.html',
    styleUrls: ['./create-file-volume.component.scss']
})
export class CreateFileVolumeComponent implements OnInit {
    @ViewChild('CreateNewFileVolume', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

    mode: string;
    volumeEndDate = null;
    volumeStartDate = null;
    actionType = ActionType;
    complementaryCcn: FormArray;
    action = this.actionType.create;
    markCodes = Constants.MARK_CODE;
    models: CreateEditFileVolume[];
    wdrwlCodes: WdrwlCodesSummary[];
    respOfcCodes: RespOfcCodesSummary[];
    deletedCompCcns: ComplementaryCcn[];
    createEditFileVolForm: FormGroup;
    factory; componentRef; // Global variables for the creating and destroying the loading spinner
    fileVolumeType = Constants.FileVolumeType;

    model: CreateEditFileVolume = {
        fileVolRecId: 0,
        volumeType: '',
        volume: '',
        startDate: '',
        endDate: '',
        basicCcn: '',
        complementaryCCN: [],
        ownerFileNo: '',
        ownerAccount: '',
        ownerPartySiteNumber: '',
        volumeStatus: '',
        serviceContratctNumber: '',
        isListee: '',
        fileRecId: 0,
        wdrlRecId: 0,
        markCode: '',
        respOfcRecId: 0,
        respOffice: '',
        wdrwlCode: '',
        isSetUpComplete: '',
        lastUpdatedBy: '',
        lastUpdatedOn: '',
    };

    constructor(
        private dataService: DataService,
        private formBuilder: FormBuilder,
        public sidenavConfig: SidenavConfig,
        private fileVolumeService: FileVolumeService,
        private toastService: ToastNotificationService,
        private fileDetailService: FileDetailsService,
        private resolver: ComponentFactoryResolver
    ) { }

    ngOnInit(): void {
        this.models = [];
        this.deletedCompCcns = [];
        this.wdrwlCodes = this.sidenavConfig.data.wdwrlCode;
        this.factory = this.resolver.resolveComponentFactory(SideNavLoaderComponent);
        this.respOfcCodes = this.dataService.getOption(Constants.RESP_OFC_CODE);

        this.createEditFileVolForm = this.formBuilder.group({
            fileVolRecId: this.formBuilder.control(null),
            fileRecId: this.formBuilder.control(null),
            volumeType: this.formBuilder.control(null),
            volume: this.formBuilder.control({ value: null, disabled: false }),
            basicCcn: this.formBuilder.control(null, {
                validators: [Validators.required, Validators.minLength(4), Validators.pattern('^[A-Za-z0-9]*$')]
            }),
            ownerFileNo: this.formBuilder.control({ value: null, disabled: true }),
            ownerAccount: this.formBuilder.control(null),
            ownerPartySiteNumber: this.formBuilder.control(null),
            volumeStatus: this.formBuilder.control(null),
            serviceContratctNumber: this.formBuilder.control(null),
            complementaryCCN: this.formBuilder.array([this.createItem(null)]),
            wdrwlCode: this.formBuilder.control({ value: null, disabled: true }),
            wdrlRecId: this.formBuilder.control(null),
            startDate: this.formBuilder.control({ value: this.dataService.getCurrentDate() }),
            endDate: this.formBuilder.control({ value: null, disabled: true }),
            isListee: this.formBuilder.control(null),
            markCode: this.formBuilder.control(null),
            respOfcRecId: this.formBuilder.control(null),
            respOffice: this.formBuilder.control(null),
            lastUpdatedBy: this.formBuilder.control(null),
            isSetUpComplete: this.formBuilder.control(null),
        });

        if (this.sidenavConfig.data.action === 'create') {
            this.action = this.actionType.create;
        } else if (this.sidenavConfig.data.action === 'edit') {
            this.action = this.actionType.edit;
            this.disableFields();
        }
        this.setFormValues(this.sidenavConfig.data.createEditVolumeModel);
    }

    disableFields() {
        this.createEditFileVolForm.get('volume').disable();
        this.createEditFileVolForm.get('wdrwlCode').enable();
    }

    get complementaryCCN(): FormArray { return this.createEditFileVolForm.get('complementaryCCN') as FormArray; }

    createItem(item): FormGroup {
        if (item) {
            return this.formBuilder.group({
                compCCNRecId: item.compCCNRecId,
                compCCN: [item.compCCN, {
                    validators: [Validators.minLength(4), Validators.pattern('^[A-Za-z0-9]*$')]
                }],
                fileVolRecId: item.fileVolRecId,
                action: Constants.UPDATED
            });
        }
        return this.formBuilder.group({
            compCCNRecId: 0,
            compCCN: ['', {
                validators: [Validators.minLength(4), Validators.pattern('^[A-Za-z0-9]*$')]
            }],
            fileVolRecId: null,
            action: Constants.ADD
        });
    }

    setFormValues(data: CreateEditFileVolume) {
        this.complementaryCcn = this.createEditFileVolForm.get('complementaryCCN') as FormArray;
        this.complementaryCcn.removeAt(0);
        data.complementaryCCN.forEach(item => this.complementaryCcn.push(this.createItem(item)));
        let FileVol_CcnRecords: Array<FileVolCcnModel> = [];
        this.dataService.setOption("FileVol_CcnRecords", FileVol_CcnRecords);

        if (this.action == this.actionType.create) {
            this.createEditFileVolForm.patchValue({
                fileRecId: data.fileRecId,
                ownerFileNo: data.ownerFileNo,
                ownerAccount: data.ownerAccount,
                ownerPartySiteNumber: data.ownerPartySiteNumber,
                volumeStatus: data.volumeStatus,
                volumeType: null,
                serviceContratctNumber: data.serviceContratctNumber,
                wdrwlCode: data.wdrwlCode,
                wdrlRecId: data.wdrlRecId,
                startDate: new Date(data.startDate),
                endDate: data.endDate ? new Date(data.endDate) : null,
                lastUpdatedBy: data.lastUpdatedBy,
                isSetUpComplete: data.isSetUpComplete,
                lastUpdatedOn: data.lastUpdatedOn
            })
        }
        else {
            this.createEditFileVolForm.patchValue({
                fileVolRecId: data.fileVolRecId,
                fileRecId: data.fileRecId,
                volumeType: data.volumeType,
                volume: data.volume,
                basicCcn: data.basicCcn,
                ownerFileNo: data.ownerFileNo,
                ownerAccount: data.ownerAccount,
                ownerPartySiteNumber: data.ownerPartySiteNumber,
                volumeStatus: data.volumeStatus,
                serviceContratctNumber: data.serviceContratctNumber,
                wdrwlCode: data.wdrwlCode,
                wdrlRecId: data.wdrlRecId,
                startDate: new Date(data.startDate),
                endDate: data.endDate ? new Date(data.endDate) : null,
                isListee: data.isListee,
                markCode: data.markCode,
                respOfcRecId: data.respOfcRecId,
                respOffice: data.respOffice,
                lastUpdatedBy: data.lastUpdatedBy,
                isSetUpComplete: data.isSetUpComplete,
                lastUpdatedOn: data.lastUpdatedOn
            });
        }
    }

    onClose(): void {
        if (this.sidenavConfig.data.sideNav) {
            this.sidenavConfig.data.sideNav.close();
            this.fileVolumeService.onRefreshSearchResult();
            this.fileDetailService.onRefreshSearchResult();
        }
    }

    getDate = (date: string) => this.dataService.getDate(date);

    ccnExistsValidator(event, fControl, idx): void {
        let isControlValid = true;
        let isControlExist = false;
        let isValueChanged = true;
        let TempCcnRecord: FileVolCcnModel = {
            formControl: (fControl + "_" + idx),
            value: event.target.value, isValid: false
        };

        this.componentRef = this.entry.createComponent(this.factory);
        const CcnRecords = this.dataService.getOption("FileVol_CcnRecords") as FileVolCcnModel[];
        let index = CcnRecords.findIndex(x => x.formControl === (fControl + "_" + idx));

        if (index !== undefined && index >= 0) {
            isValueChanged = CcnRecords[index]?.value === event.target.value ? false : true;
            isControlValid = CcnRecords[index]?.isValid ? true : false;
            isControlExist = true;
        }

        if (fControl == "basicCcn") {
            const control = this.createEditFileVolForm.get(fControl);
            if (isValueChanged && control?.valid) {
                if (isControlExist) { CcnRecords.splice(index, 1); }
                control?.setErrors({ ccnExists: true });

                this.fileVolumeService.checkCcnExists(event.target.value).subscribe((result) => {
                    TempCcnRecord.isValid = result;
                    if (result) { control?.setErrors(null); }
                    else { control?.setErrors({ ccnExists: true }); }
                    CcnRecords.push(TempCcnRecord);
                    this.dataService.setOption("FileVol_CcnRecords", CcnRecords);
                }, error => { control?.setErrors({ ccnExists: true }); });
                control?.updateValueAndValidity();
            }
            else if (!isValueChanged && !isControlValid) {
                control?.setErrors({ ccnExists: true });
                control?.markAsDirty();
            }
        }
        else if (fControl == "compCCN") {
            const controlArray = this.createEditFileVolForm.get('complementaryCCN') as FormArray;
            const control = controlArray.controls[idx].get(fControl);

            if (isValueChanged && control?.valid) {
                if (isControlExist) { CcnRecords.splice(index, 1); }
                if (event.target.value.trim().length !== 0) {
                    control?.setErrors({ ccnExists: true });
                    this.fileVolumeService.checkCcnExists(event.target.value).subscribe((result) => {
                        TempCcnRecord.isValid = result;
                        if (result) { control?.setErrors(null); }
                        else { control?.setErrors({ ccnExists: true }); }
                        CcnRecords.push(TempCcnRecord);
                        this.dataService.setOption("FileVol_CcnRecords", CcnRecords);
                    }, error => { control?.setErrors({ ccnExists: true }); });
                    control?.updateValueAndValidity();
                }
                else {
                    TempCcnRecord.isValid = true;
                    CcnRecords.push(TempCcnRecord);
                    this.dataService.setOption("FileVol_CcnRecords", CcnRecords);
                }
            }
            else if (!isValueChanged && !isControlValid) {
                control?.setErrors({ ccnExists: true });
                control?.markAsDirty();
            }
        }
        setTimeout(() => { this.componentRef.destroy(); }, 300);
    }

    addNewCompCCN = (): void => {
        this.complementaryCcn = this.createEditFileVolForm.get('complementaryCCN') as FormArray;
        this.complementaryCcn.push(this.createItem(null));
    };

    removeCompCcn = (index): void => {
        this.complementaryCcn = this.createEditFileVolForm.get('complementaryCCN') as FormArray;
        const CcnRecords = this.dataService.getOption("FileVol_CcnRecords") as FileVolCcnModel[];

        this.complementaryCcn.controls.forEach((item, idx) => {
            let ccnIdx = CcnRecords.findIndex(x => x.formControl === ("compCCN_" + idx));
            if (ccnIdx !== undefined && ccnIdx >= 0) { CcnRecords.splice(ccnIdx, 1); }
        });

        if (this.complementaryCcn.value[index].compCCNRecId != 0) {
            this.complementaryCcn.value[index].action = Constants.DELETE;
            this.deletedCompCcns.push(this.complementaryCcn.value[index]);
        }
        this.complementaryCcn.removeAt(index);
        if (this.complementaryCcn.length < 1) { this.addNewCompCCN(); }

        this.complementaryCcn.controls.forEach((item, idx) => {
            const control = this.complementaryCcn.controls[idx].get("compCCN");
            let TempCcnRecord: FileVolCcnModel = {
                formControl: ("compCCN_" + idx),
                value: control.value, isValid: control.valid
            };
            CcnRecords.push(TempCcnRecord);
        });

        this.dataService.setOption("FileVol_CcnRecords", CcnRecords);
    };

    resetForm(): void {
        // This will reset the form
        this.createEditFileVolForm.patchValue({
            volumeType: null,
            volume: '',
            startDate: new Date(),
            endDate: null,
            basicCcn: '',
            serviceContratctNumber: '',
            isListee: '',
            markCode: null,
            respOfcRecId: 0,
            respOffice: null,
            complementaryCCN: []
        });
        this.createEditFileVolForm.markAsUntouched();
        this.createEditFileVolForm.markAsPristine();
    }

    onSubmit(data) {
        // call the Add API here
        this.componentRef = this.entry.createComponent(this.factory);
        this.model.fileVolRecId = data.fileVolRecId ? data.fileVolRecId : 0;
        this.model.fileRecId = data.fileRecId;
        this.model.basicCcn = this.createEditFileVolForm.get('basicCcn').value;
        this.model.ownerFileNo = this.createEditFileVolForm.get('ownerFileNo').value;
        this.model.isListee = data.isListee ? data.isListee : null;
        this.model.isSetUpComplete = data.isSetUpComplete ? data.isSetUpComplete : null;
        this.model.volumeStatus = data.volumeStatus ? data.volumeStatus : null;
        this.model.ownerAccount = data.ownerAccount ? data.ownerAccount : null;
        this.model.serviceContratctNumber = data.serviceContratctNumber ? data.serviceContratctNumber : null;
        this.model.markCode = this.createEditFileVolForm.get('markCode').value ? this.createEditFileVolForm.get('markCode').value : null;
        this.model.volume = this.createEditFileVolForm.get('volume').value ? this.createEditFileVolForm.get('volume').value : null;
        this.model.volumeType = this.createEditFileVolForm.get('volumeType').value ? this.createEditFileVolForm.get('volumeType').value : null;
        this.model.lastUpdatedBy = this.dataService.getLastUpdatedBy();
        this.model.lastUpdatedOn = this.dataService.getLastUpdatedDate();
        this.model.ownerPartySiteNumber = data.ownerPartySiteNumber ? data.ownerPartySiteNumber : null;
        // To format the date based on where start date is string or StandardDatePicker object
        this.model.startDate = this.dataService.customDateFormat(this.getDate(data.startDate));

        this.model.respOfcRecId = this.dataService.getRespOfficeId(
            this.createEditFileVolForm.get('respOffice').value,
            this.respOfcCodes
        );
        this.model.wdrlRecId = this.dataService.getWithdrawalId(
            this.createEditFileVolForm.get('wdrwlCode').value,
            this.wdrwlCodes
        );
        if (this.model.wdrlRecId != 0) {
            this.model.volumeStatus = 'W';
            this.model.endDate = this.dataService.customDateFormat(null);
        }
        this.model.wdrwlCode = this.createEditFileVolForm.get('wdrwlCode').value;
        this.model.complementaryCCN = this.createEditFileVolForm.get('complementaryCCN').value;
        this.deletedCompCcns.forEach(data => this.model.complementaryCCN.push(data));

        if (this.action == this.actionType.create) {
            this.fileVolumeService.addNewVolume(this.model).subscribe((result) => {
                if (result) {
                    const successMessage = `Successfully added Volume ${this.model.volume} to File ${this.model.ownerFileNo}`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.models.push(this.sidenavConfig.data.createEditVolumeModel);
                    this.volumeStartDate = null;
                    this.volumeEndDate = null;
                    this.model = this.models[this.models.length - 1];
                    this.dataService.navigateToInnerSideNavTop();
                    this.componentRef.destroy();
                    this.complementaryCcn = this.createEditFileVolForm.get('complementaryCCN') as FormArray;
                    this.complementaryCcn.clear(); // To clear the added complementary CCN
                    this.addNewCompCCN(); // TO add a blank complementary ccn block
                    this.resetForm();
                }
            }, error => {
                this.dataService.navigateToInnerSideNavTop();
                const errorMessage = `Error while trying to add the file volume details`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
                this.componentRef.destroy();
            });
        } else {
            this.fileVolumeService.updateFileVolume(this.model).subscribe((result) => {
                if (result) {
                    this.model.complementaryCCN = this.model.complementaryCCN.filter(data => (data.action != Constants.DELETE));
                    this.dataService.navigateToInnerSideNavTop();
                    const successMessage = `The File Volume details have been updated successfully`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.componentRef.destroy();
                }
            }, error => {
                this.dataService.navigateToInnerSideNavTop();
                const errorMessage = `Error while trying to update the file volume details`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
                this.componentRef.destroy();
            });
        }
    }

    checkVolumeExists = (volume: string): boolean => {
        // API call headers
        let volumeExists = true;
        const request: CheckVolumeRequest = { fileRecId: this.model.fileRecId, volume: volume };
        this.fileVolumeService.checkVolumeExists(request).subscribe((result) => (volumeExists = result));
        return volumeExists;
    };
}
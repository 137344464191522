import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';

@Component({
    selector: 'app-logged-out',
    templateUrl: './logged-out.component.html',
    styleUrls: ['./logged-out.component.scss'],
})
export class LoggedOutComponent implements OnInit, AfterViewInit {
    constructor(private loadingSpinnerService: LoadingSpinnerService) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.loadingSpinnerService.removeLoadingSpinner();
    }
}

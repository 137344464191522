import {
    Component, OnInit, ViewContainerRef,
    ViewChild,
    ComponentFactoryResolver
} from '@angular/core';
import { Constants } from '../../constants';
import {
    ActionType,
    CreateEditProductCcnModel,
} from './create-product-ccn.model';
import { SidenavConfig } from '../../shared/side-nav/sidenav-config';
import { ProductCcnService } from '../product-ccn.service';
import { DataService } from '../../core/services/data.service';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { SideNavLoaderComponent } from '../../shared/side-nav-loader/side-nav-loader.component';

@Component({
    selector: 'app-create-product-ccn',
    templateUrl: './create-product-ccn.component.html',
    styleUrls: ['./create-product-ccn.component.scss']
})
export class CreateProductCcnComponent implements OnInit {
    @ViewChild('CreateNewProduct', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

    actionType = ActionType;
    action = this.actionType.create;
    deliverableTypes: string[] = Constants.PRODUCT_DELIVERABLE_TYPES;
    deliverableOption: string[] = Constants.PRODUCT_DELIVERABLE_OPTIONS;
    serviceType: string[] = Constants.TYPE_OF_SERVICE;
    mlOptions = Constants.BOOLEAN_OPTIONS;
    industryOptions = Constants.BOOLEAN_OPTIONS;
    model: CreateEditProductCcnModel;
    models: CreateEditProductCcnModel[];
    factory; componentRef; // Global variables for the creating and destroying the loading spinner

    constructor(
        public sidenavConfig: SidenavConfig,
        public productService: ProductCcnService,
        private dataService: DataService,
        private toastService: ToastNotificationService,
        private resolver: ComponentFactoryResolver
    ) { }

    ngOnInit(): void {
        this.factory = this.resolver.resolveComponentFactory(
            SideNavLoaderComponent
        );
        this.models = [];
        if (this.sidenavConfig.data.action === 'create') {
            this.action = this.actionType.create;
        } else if (this.sidenavConfig.data.action === 'edit') {
            this.action = this.actionType.edit;
        }
        this.model = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.sidenavConfig.data.createEditProductModel))
        );
        this.models.push(this.model);
    }

    onClose(): void {
        if (this.sidenavConfig.data.sideNav) {
            this.sidenavConfig.data.sideNav.close();
            this.productService.onRefreshSearchResult();
        }
    }

    onSubmit() {
        // call the Add API here
        this.componentRef = this.entry.createComponent(this.factory);
        if (this.action == this.actionType.create) {
            //Call ADD api here
            this.productService.addProduct(this.model).subscribe((result) => {
                if (result) {
                    this.dataService.navigateToInnerSideNavTop();
                    const successMessage = `The Product details have been added successfully`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.componentRef.destroy();
                }
            },
                error => {
                    const errorMessage = `Error while trying to add new product details`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.componentRef.destroy();
                });

        } else {
            this.productService.updateProductDetail(this.model).subscribe((result) => {
                if (result) {
                    this.dataService.navigateToInnerSideNavTop();
                    const successMessage = `The Product details have been updated successfully`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.componentRef.destroy();
                }
            },
                error => {
                    const errorMessage = `Error while trying to update the product details`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.componentRef.destroy();
                });
        }
    }

}

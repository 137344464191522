import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../constants';
import { DataService } from '../../core/services/data.service';
import { FileSummary } from '../../file-details/file-detail-search.model';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ListeeSearchRequest, ListeeSearchResultModel, WithdrawOrDeleteEventModel } from '../listee.model';
import { ListeeService } from '../listee.service';
import { Subscription } from 'rxjs';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { GenerateAuthPageRecord } from '../../shared/utility.model';
import { GenerateAuthPage } from '../../shared/generate-auth-page/generate-auth-page';
import { MfrDetailService } from "../../mfr-detail/mfr-detail.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { FileVolumeService } from '../../file-volume/file-volume.service';
import { FileDetailsService } from '../../file-details/file-details.service';

@Component({
    selector: 'app-listee',
    templateUrl: './listee.component.html',
    styleUrls: ['./listee.component.scss']
})
export class ListeeComponent implements OnInit, AfterViewInit {
    @ViewChild('listeeContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public levelOneTitle = '';
    levelTwoTitle = '';
    levelThreeTitle = '';
    levelOneURL = '';
    levelTwoURL = '';
    activeLevel = 0;

    isSearch = false;
    isSearching = false;
    searchResultCount: number;
    hasSearchActionOccurred = false;
    noSearchResults = false;
    isAuthPageEnabled = true;
    isTableView = false;
    isAdmin: boolean;

    modelData: any;
    fileRecId: number = 0;
    previousUrl: string = null;
    searchResult: ListeeSearchResultModel;
    refreshSearchResultSubscription: Subscription;
    sortOptions = Constants.LISTEE_SEARCH_SORT_OPTIONS;
    filterOptions = Constants.FILTER_BY_LISTEE_STATUS;
    prevFilter: string = this.filterOptions[0].value;
    currentSort = { active: '', direction: '' };
    listeeSearchModel: ListeeSearchRequest = {
        fileNumber: '',
        ownerAccountNumber: '',
        volume: '',
        listeeType: '',
        listeeAccountNumber: '',
        listeeFileNumber: '',
        partySiteNumber: '',
        subscriberNumber: '',
        fileRecId: 0,
        fileVolRecId: 0,
        exactSearch: false,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0],
        filterBy: Constants.ALL
    };

    constructor(
        private dataService: DataService,
        private loadingSpinnerService: LoadingSpinnerService,
        private toastService: ToastNotificationService,
        private searchService: ListeeService,
        private resolver: ComponentFactoryResolver,
        private route: ActivatedRoute,
        private previousRouteService: PreviousRouteService,
        private authPageService: GenerateAuthPage,
        private mfrService: MfrDetailService,
        private modalService: NgbModal,
        private fileVolService: FileVolumeService,
        private fileService: FileDetailsService
    ) {
        this.previousUrl = this.previousRouteService.getPreviousUrl();
        this.dataService.getModelData().subscribe(result => this.modelData = result);
        if (!this.modelData) {
            this.modelData = <FileSummary>this.route.snapshot.data.modelData;
            this.dataService.setModelData(this.modelData);
        }
        this.refreshSearchResultSubscription = this.searchService.refreshSearchResult$.subscribe(res => {
            // Fetch the data again
            if (this.dataService.isEmpty(this.listeeSearchModel)) {
                this.getSearchResult(this.listeeSearchModel);
            }
        });
    }

    ngOnInit(): void {
        // Call the API here
        this.listeeSearchModel.fileVolRecId = Number(this.route.snapshot.paramMap.get('fileVolRecId'));
        const fileRecId = Number(this.route.snapshot.paramMap.get('fileRecId'));
        this.fileRecId = Number(this.route.snapshot.paramMap.get('fileRecId'));

        if (!this.listeeSearchModel.fileVolRecId && fileRecId) {
            this.listeeSearchModel.fileRecId = Number(this.route.snapshot.paramMap.get('fileRecId'));
        }

        // To check if the volume is active and not a ML volume
        if (this.listeeSearchModel.fileVolRecId != 0) {
            this.fileVolService.checkToEnableAuthPage(this.listeeSearchModel.fileVolRecId.toString())
                .subscribe(result => {
                    this.isAuthPageEnabled = result;
                });
        }
        // Change the bread crumb URL's here based on the route type.
        if (this.previousUrl) {
            const urlKeyword = this.previousUrl.split('/'); // To check from which level the search is made
            if (urlKeyword) {
                if (urlKeyword.indexOf(Constants.FILE_DETAILS) != -1
                    && urlKeyword.indexOf(Constants.FILE_VOL_DETAILS) != -1) {
                    this.levelOneTitle = 'File Details';
                    this.levelOneURL = `/file-details/${this.modelData.fileRecId}/details`;
                    this.levelTwoTitle = 'Volume Details';
                    this.levelTwoURL = `/file-details/${this.modelData.fileRecId}/file-volume`;
                    this.levelThreeTitle = `Listee Details`;
                    this.activeLevel = 3;
                }
                else if (urlKeyword.indexOf(Constants.FILE_DETAILS) != -1) {
                    this.levelOneTitle = 'File Details';
                    this.levelOneURL = `/file-details/${this.modelData.fileRecId}/details`;
                    this.levelTwoTitle = 'Listee Details';
                    this.activeLevel = 2;
                }
                else {
                    this.levelOneTitle = 'File Volume Search';
                    this.levelOneURL = '/file-volume';
                    this.levelTwoTitle = 'Listee Details';
                    this.activeLevel = 2;
                }
            }
        }
        else {
            if (fileRecId && this.listeeSearchModel.fileVolRecId) {
                this.levelOneTitle = 'File Details';
                this.levelOneURL = `/file-details/${this.modelData.fileRecId}/details`;
                this.levelTwoTitle = 'Volume Details';
                this.levelTwoURL = `/file-details/${this.modelData.fileRecId}/file-volume`;
                this.levelThreeTitle = `Listee Details`;
                this.activeLevel = 3;
            }
            else if (fileRecId) {
                this.levelOneTitle = 'File Details';
                this.levelOneURL = `/file-details/${this.modelData.fileRecId}/details`;
                this.levelTwoTitle = 'Listee Details';
                this.activeLevel = 2;
            }
            else if (this.listeeSearchModel.fileVolRecId) {
                this.levelOneTitle = 'File Volume Search';
                this.levelOneURL = '/file-volume';
                this.levelTwoTitle = 'Listee Details';
                this.activeLevel = 2;
            }
        }

        this.getSearchResult(this.listeeSearchModel);
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
    }

    ngOnDestroy(): void {
        if (this.refreshSearchResultSubscription) {
            this.refreshSearchResultSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    onViewChange = (sortingEvent: Paginator) => {
        this.isTableView = sortingEvent.isTableView;
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        this.listeeSearchModel.filterBy = sortingEvent.filterBy;
        this.listeeSearchModel.pageNumber = sortingEvent.page;
        this.listeeSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.listeeSearchModel);
    }

    onSortByChange = (sortingEvent: Paginator) => {
        if (this.prevFilter != sortingEvent.filterBy) {
            this.listeeSearchModel.pageNumber = sortingEvent.page;
        }
        this.listeeSearchModel.filterBy = sortingEvent.filterBy;
        this.listeeSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.listeeSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];
        this.getSearchResult(this.listeeSearchModel);
    }

    /* To sort the values based on the sort option chosen in the table headers - @param sortingEvent */
    onSortingChange = (sortingEvent: ListeeSearchRequest) => {
        this.listeeSearchModel.sortBy = sortingEvent.sortBy;
        this.listeeSearchModel.sortDirection = sortingEvent.sortDirection;
        this.getSearchResult(this.listeeSearchModel);
    }

    onSearch = (formValues) => {
        this.listeeSearchModel.pageNumber = 1;
        this.listeeSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
        this.listeeSearchModel.sortDirection = this.sortOptions[0].value.split('-')[1];
        this.listeeSearchModel.sortBy = this.sortOptions[0].value.split('-')[0];
        this.listeeSearchModel.filterBy = Constants.ALL;
        this.getSearchResult(formValues);
    }

    onWithdrawOrDelete = (withdrawOrDeleteEvent: WithdrawOrDeleteEventModel) => {
        document.querySelector('mat-sidenav-content').scrollTop = 0;
        if (withdrawOrDeleteEvent.value) {
            const successMessage = `The Listee association was ${withdrawOrDeleteEvent.action.toLowerCase()} successfully`;
            const type = ToastNotificationType.success;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(successMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.getSearchResult(this.listeeSearchModel);
        }
        else {
            const withdrawOrDeleteMsg = withdrawOrDeleteEvent.action === Constants.WITHDRAWN ? 'withdraw' : 'delete';
            const errorMessage = `Error while trying to ${withdrawOrDeleteMsg} the listee association. Please try again later`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            this.entry.clear();
            this.prevFilter = this.listeeSearchModel.filterBy;
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.searchService.getSearchResult(this.listeeSearchModel).subscribe((searchResult) => {
                componentRef.destroy();
                this.searchResult = searchResult;
                this.searchResultCount = searchResult.paging.totalRecords;
                this.currentSort = {
                    active: this.listeeSearchModel.sortBy,
                    direction: this.listeeSearchModel.sortDirection
                };

                if (this.searchResultCount === 0) {
                    const errorMessage = `No Data found for the specified search criteria`;
                    const type = ToastNotificationType.infoBlue;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.isSearch = false;
                    return;
                }
                else {
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = false;
                    this.isSearch = true;
                }
            }, error => {
                componentRef.destroy();
                const errorMessage = `Error while retrieving the data`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
                this.searchResultCount = 0;
                this.isSearch = false;
                this.hasSearchActionOccurred = true;
                this.noSearchResults = true;
                this.isTableView = false;
            });
        } else {
            const errorMessage = `Please enter the search criteria in the form below`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.searchResultCount = 0;
            this.isSearch = false;
            this.isTableView = false;
        }
    }

    generateAuthPage = () => {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        let tempArray = [];
        let inputType = "";
        let tempRecord: GenerateAuthPageRecord = {
            fileNum: '',
            volume: '',
            fileRecId: '',
            fileVolRecId: ''
        };
        if (this.listeeSearchModel.fileVolRecId != 0) {
            inputType = "FILE_VOL";
            tempRecord = {
                fileNum: this.modelData.fileNumber,
                volume: '',
                fileRecId: '',
                fileVolRecId: this.listeeSearchModel.fileVolRecId.toString()
            }
            tempArray.push(tempRecord);
            this.mfrService.getActiveMfr(this.listeeSearchModel.fileVolRecId).subscribe(result => {
                if (result) { this.authPageService.generateAuthPage(tempArray, inputType, componentRef); }
                else {
                    const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                    dialogRef.componentInstance.isCancelButtonRequired = false;
                    dialogRef.componentInstance.data = {
                        title: 'Alert Message',
                        confirmationInfo: 'Invalid file volume record selected to generate Authpage.No Active MFR associated with the record',
                        cancelButton: 'No',
                        confirmButton: 'Ok'
                    };
                    dialogRef.result.then(result => { componentRef.destroy(); });
                }
            })
        }
        else {
            inputType = "FILE";
            tempRecord = {
                fileNum: this.modelData.fileNumber,
                volume: '',
                fileRecId: '',
                fileVolRecId: ''
            }
            tempArray.push(tempRecord);
            this.fileService.checkIfAgencyFile(this.fileRecId).subscribe(result => {
                if (result) {
                    const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                    dialogRef.componentInstance.isCancelButtonRequired = false;
                    dialogRef.componentInstance.data = {
                        title: 'Error Message',
                        confirmationInfo: 'Authpage cannot be generated for Agency file type volume(s)',
                        cancelButton: 'No',
                        confirmButton: 'Ok'
                    };
                    dialogRef.result.then(result => { componentRef.destroy(); });
                }
                else { this.authPageService.generateAuthPage(tempArray, inputType, componentRef); }
            }, error => {
                const errorMessage = `Error while trying to generate the auth page. Please try again later`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
        }
    }
}
<div class="d-flex flex-row justify-content-between footer-height">
  <div class="d-flex float-left align-items-center">
    <a aria-label="UL Logo" class="px-3 ml-4" (click)="navigateToRootUrl()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 30 30" class="footer-logo">
        <title>UL Logo</title>
        <path
          d="M10,20C4.5,20,0,15.5,0,10C0,4.5,4.5,0,10,0c5.5,0,10,4.5,10,10C20,15.5,15.5,20,10,20 M10,18.1c4.5,0,8.1-3.6,8.1-8.1 c0-4.5-3.6-8.1-8.1-8.1c-4.5,0-8.1,3.6-8.1,8.1C1.9,14.5,5.5,18.1,10,18.1">
        </path>
        <path
          d="M5.1,4.6H7v5.9c0,0.4,0.1,0.7,0.6,0.7c0.5,0,0.6-0.4,0.6-0.8V4.6h1.8v5.7c0,1.3-0.6,2.4-2.5,2.4c-1.6,0-2.5-0.7-2.5-2.4 V4.6">
        </path>
        <polyline points="10.8,15.4 10.8,7.5 12.7,7.5 12.7,13.8 14.9,13.8 14.9,15.4 10.8,15.4"></polyline>
      </svg>
    </a>
    <span class="ul-copy">
      UL and the UL logo are trademarks of UL LLC © 2021 All Rights Reserved.
    </span>
  </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { UtilityModule } from '../utility';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IcDetailSearchComponent } from './ic-detail-search/ic-detail-search.component';
import { IcDetailSearchResultCardComponent } from './ic-detail-search-result-card/ic-detail-search-result-card.component';

@NgModule({
    declarations: [
        IcDetailSearchComponent,
        IcDetailSearchResultCardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        AppRoutingModule,
        UtilityModule,
    ],
})
export class IcDetailModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    constructor(private router: Router, private location: Location) {}

    ngOnInit(): void {}

    navigateToRootUrl(): void {
        this.location.go('/'); // This will change the href in the browsers address bar
        this.router.navigate(['/'], {
            replaceUrl: true,
        });
    }
}

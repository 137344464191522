import { FileTypeInterface } from '../../file-details/create-file-detail.model';
import { ComplementaryCcn } from '../file-volume-detail-search.model';

export enum ActionType {
    create = 'Add New',
    edit = 'Edit',
}

export interface CreateEditFileVolume {
    fileVolRecId?: number;
    volumeType: string;
    volume: string;
    startDate: string;
    endDate: string;
    basicCcn: string;
    complementaryCCN?: ComplementaryCcn[];
    ownerFileNo: string;
    ownerAccount: string;
    ownerPartySiteNumber: string;
    volumeStatus: string;
    serviceContratctNumber: string;
    isListee: string;
    fileRecId: number;
    wdrlRecId: number;
    markCode: string;
    respOfcRecId: number;
    respOffice: string;
    wdrwlCode: string;
    isSetUpComplete: string;
    lastUpdatedBy: string;
    lastUpdatedOn: string;
}

export interface CheckVolumeRequest {
    fileRecId: number;
    volume: string;
}

export interface FileVolCcnModel {
    formControl: string,
    value: string,
    isValid: boolean
}

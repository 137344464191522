import { adalConfig } from '../core/config/adal-conig';
import { AdalConfigModel } from '../core/model/adal-config.model';
import { ClientSettingsService } from '../core/services/client-settings.service';
export function MsAdalAngular6ConfigFactory(): AdalConfigModel {
    return adalConfig;
}

export function InitializeApp(clientSettingsService: ClientSettingsService) {
    return async () => {
        try {
            const response = await fetch('/api/clientsettings');
            const json = await response.json();
            clientSettingsService.clientSettings = json;
            if (
                clientSettingsService.clientSettings &&
                clientSettingsService.clientSettings.webApiEndpointUrl &&
                !clientSettingsService.clientSettings.webApiEndpointUrl.endsWith('/')
            ) {
                clientSettingsService.clientSettings.webApiEndpointUrl =
                    `${clientSettingsService.clientSettings.webApiEndpointUrl}/`;
            }
            // console.log('fetched ClientSettings from WebApp to configure front-end', json);

            adalConfig.clientId = `${json.clientId}`;
            adalConfig.tenant = 'ul.onmicrosoft.com';
            adalConfig.cacheLocation = 'localStorage';
            adalConfig.redirectUri = window.location.origin !== 'logged-out' ? window.location.origin : '/';
            adalConfig.navigateToLoginRequestUrl = true;
            adalConfig.postLogoutRedirectUri = `${window.location.origin}/logged-out`;
            // number of milliseconds of inactivity in the Iframe before a token renewal
            // response from Azure AD is considered timed out. The default value is 6 seconds
            adalConfig.loadFrameTimeout = '18000';

            adalConfig.endpoints = {
                'https://graph.microsoft.com': 'https://graph.microsoft.com',
                'https://graph.microsoft.com/User.Read': '00000003-0000-0000-c000-000000000000'
            };

            // Azure AD needs the value for the WebAPI for Authentication, not what
            // Azure API Managment wants to be exposed to the outside world.
            adalConfig.endpoints[`${json.webApiEndpointUrl}`] = `${json.webApiClientId}`;

            if (`${json.azureActiveDirectoryWebApiApplicationIdUri}` in adalConfig.endpoints === false) {
                adalConfig.endpoints[`${json.azureActiveDirectoryWebApiApplicationIdUri}`] = `${json.webApiClientId}`;
            }
            JSON.stringify(adalConfig);
        } catch (err) {
        }
    };
}
<div class="modal-header">
  <h2 class="modal-title">{{data.title}}</h2>
  <button class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="font-bold" *ngIf="data.confirmationTitle != ''"> {{data.confirmationTitle}}</div>
  <div class="m-4">
    <div> {{data.confirmationHtmlLine1}}</div>
    <div *ngIf="data.confirmationHtmlLine2 != ''"> {{data.confirmationHtmlLine2}}</div>
  </div>
</div>
<div class="modal-footer">
  <button class="button button-white w-28 float-right m-1" (click)="onCancel()">{{ data.cancelButton }}</button>
  <button type="button" class="button button-blue w-28 float-right m-1" mat-button cdkFocusInitial
          (click)="onConfirm()">
    {{ data.confirmButton }}
  </button>
</div>

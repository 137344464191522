export enum ActionType {
    create = 'Add New',
    edit = 'Edit',
}

export interface CreateListeeModel {
    fileVolRecId?: number;
    listeeRecId: number;
    listeeFileNumber: string;
    accountNumber: string;
    orgNumber: string;
    isActive: string;
    listeePartySiteNumber: string;
    listeePartyNumber: string;
    subscriberNumber: string;
    companyName: string;
    companyAddress: string;
    associationType: string;
    startDate: string;
    endDate: string;
    lastUpdatedBy: string;
    listeeAddress1: string;
    listeeAddress2: string;
    listeeAddress3: string;
    listeeAddress4: string;
    listeeAddress5: string;
    listeeAddress6: string;
    listeeCity: string;
    listeeProvince: string;
    listeeState: string;
    listeeCountry: string;
    listeePostalCode: string;
    isAddToAllVolume: boolean;
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../constants';
import { Location } from '@angular/common';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AuthService } from '../../core/services/auth.service';
import { DataService } from '../../core/services/data.service';
import { AppInsightsService } from '../../app-insights/app-insights.service';
import { JoyrideService } from 'ngx-joyride';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    loggedIn = true;
    helpLink = `https://ul.service-now.com/ulsp/?id=sc_cat_item&sys_id=162a74e80a0a3c4f01cfa204deb7247b
                    &sysparm_category=597ec252b50509401f89a1c887b6a917`;
    userInitials = '';
    isAdmin: boolean;
    userName = '';
    headerName = 'Integrated File Volume System';

    constructor(
        private router: Router,
        private location: Location,
        private adalService: MsAdalAngular6Service,
        private authService: AuthService,
        private appInsightsService: AppInsightsService,
        private readonly joyrideService: JoyrideService,
        private dataService: DataService
    ) {}

    ngOnInit(): void {
        this.adalService.handleCallback();

        this.loggedIn =
            this.adalService.userInfo && this.adalService.isAuthenticated;

        if (this.isLoggedIn()) {
            this.isAdmin = this.authService.isAdmin();
            this.dataService.setOption(Constants.IS_ADMIN_USER, this.authService.isAdmin());
            this.dataService.setOption(Constants.IS_EDIT_USER, this.authService.isEdit());
            this.dataService.setOption(Constants.IS_CREATEFILEONLY_USER, this.authService.isCreateFileOnly());

            if (this.adalService.userInfo && this.adalService.userInfo.userName) {
                this.appInsightsService.setUserId(this.adalService.userInfo.userName);
                // this.userEmail = this.adalService.userInfo.userName;
            }
            this.buildUserInitials();
        }
    }
    isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

    navigateToRootUrl(): void {
        this.location.go('/'); // This will change the href in the browsers address bar
        this.router.navigate(['/'], { replaceUrl: true, });
    }

    login(): void {
        // Just route the user to the home route, which has an authentication guard that checks the adalService for
        // this.adalService.isAuthentication and if false, then does the this.adalService.login();
        this.location.go('/'); // This will change the href in the browsers address bar
        this.router.navigate(['/'], { replaceUrl: true, });
    }

    logout(): void {
        this.adalService.logout();
    }

    private buildUserInitials(): void {
        const user = this.adalService.userInfo;
        this.userName = user.profile.name;
        if (user && user.profile) {
            const array = user.profile.name.split(' ');
            for (const userName of array) {
                this.userInitials += userName.substring(0, 1);
            }
        }
    }

    startTour() {
        const options = {
            steps: Constants.TOUR_STEPS,
            stepDefaultPosition: 'bottom',
            themeColor: '#002b45',
            showPrevButton: true,
            logsEnabled: false
        };
        this.joyrideService.startTour(options)
    }
}

import { Component, OnInit, AfterViewInit, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from '../../constants';
import { DataService } from '../../core/services/data.service';
import { IcDetailService } from '../ic-detail.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ICSearchRequest, ICDetailSearchResultModel } from '../ic-detail.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportConfirmDialogComponent } from '../../shared/export-confirm-dialog/export-confirm-dialog.component';
import * as FileSaver from 'file-saver';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-ic-detail-search',
    templateUrl: './ic-detail-search.component.html',
    styleUrls: ['./ic-detail-search.component.scss']
})
export class IcDetailSearchComponent implements OnInit, AfterViewInit {
    @ViewChild('icDetailsContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public pageName = '';
    levelTwoTitle = '';
    activeLevel = 0;
    isSearch = false;
    isSearching = false;
    sortOptions = Constants.IC_SEARCH_SORT_OPTIONS;
    searchResult: ICDetailSearchResultModel;
    searchResultCount: number;
    hasSearchActionOccurred = false;
    noSearchResults = false;
    icDetailSearchModel: ICSearchRequest = {
        postalCode: '',
        city: '',
        country: '',
        state: '',
        province: '',
        icNumber: '',
        areaManager: '',
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0]
    };

    isTableView = false;
    loading = false;
    currentSort = {
        active: '',
        direction: ''
    };
    constructor(
        private dataService: DataService,
        private loadingSpinnerService: LoadingSpinnerService,
        private toastService: ToastNotificationService,
        private searchService: IcDetailService,
        private resolver: ComponentFactoryResolver,
        private modalService: NgbModal    ) { }

    ngOnInit(): void {
        this.pageName = 'IC Search';
        this.activeLevel = 1;
        this.dataService.changeMessage('#e6ebf0');
        const obj = this.dataService.getOption(Constants.IC_SEARCH_REQUEST_OBJECT)
        if (this.dataService.getOption(Constants.IC_SEARCH_REQUEST_OBJECT)
                && Object.keys(obj).length > 0) {
            this.icDetailSearchModel = this.dataService.getOption(
                Constants.IC_SEARCH_REQUEST_OBJECT
            );
            if (this.dataService.isEmpty(this.icDetailSearchModel)) {
                this.onSearch(this.icDetailSearchModel);
            }
        }
    }

    ngAfterViewInit() {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    onViewChange = (sortingEvent: Paginator) => {
        this.isTableView = sortingEvent.isTableView;
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        this.icDetailSearchModel.pageNumber = sortingEvent.page;
        this.icDetailSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.icDetailSearchModel);
    };

    onSortByChange = (sortingEvent: Paginator) => {
        this.icDetailSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.icDetailSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];

        this.currentSort.active = this.icDetailSearchModel.sortBy;
        this.currentSort.direction = this.icDetailSearchModel.sortDirection;

        this.getSearchResult(this.icDetailSearchModel);
    }
    
    /**
    * To sort the values based
    * on the sort option chosen
    * in the table headers
    * @param sortingEvent
    */
    onSortingChange = (sortingEvent: ICSearchRequest) => {
        this.icDetailSearchModel.sortBy = sortingEvent.sortBy;
        this.icDetailSearchModel.sortDirection = sortingEvent.sortDirection;

        this.getSearchResult(this.icDetailSearchModel);
    }

    onSearch = (formValues) => {
        this.icDetailSearchModel.pageNumber = 1;
        this.icDetailSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
        this.icDetailSearchModel.sortDirection = this.sortOptions[0].value.split('-')[1];
        this.icDetailSearchModel.sortBy = this.sortOptions[0].value.split('-')[0];
        this.getSearchResult(formValues);
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.dataService.setOption(Constants.IC_SEARCH_REQUEST_OBJECT, formValues);
            this.searchService.getSearchResult(this.icDetailSearchModel).subscribe((searchResult) => {
                this.searchResult = searchResult;
                this.searchResultCount = searchResult.paging.totalRecords;
                componentRef.destroy();
                this.currentSort.active = this.icDetailSearchModel.sortBy;
                this.currentSort.direction = this.icDetailSearchModel.sortDirection;
                if (this.searchResultCount === 0) {
                    this.isSearch = false;
                    const errorMessage = `No Data found for the specified search criteria`;
                    const type = ToastNotificationType.infoBlue;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    return;
                }
                this.hasSearchActionOccurred = true;
                this.noSearchResults = false;
                this.isSearch = true;
            },
                error => {
                    componentRef.destroy();
                    const errorMessage = `Error while retrieving the data`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.searchResultCount = 0;
                    this.isSearch = false;
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.isTableView = false;
                });
        } else {
            const errorMessage = `Please enter the search criteria in the form below`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.searchResultCount = 0;
            this.isSearch = false;
            this.hasSearchActionOccurred = false;
            this.noSearchResults = false;
            this.isTableView = false;
        }
    }

    exportToExcel() {
        const fileName = `ICDetails-${this.dataService.customDateFormat(null)}.xlsx`;
        this.loading = true;
        const dialogRef = this.modalService.open(ExportConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    if (result.selectedValue === 'true') {
                        this.icDetailSearchModel.pageNumber = 1;
                        this.icDetailSearchModel.pageSize = this.searchResultCount;
                    }
                    this.searchService.exportToExcel(this.icDetailSearchModel).subscribe((object: Blob) => {
                        if (object.size && object.size > 0) {
                            FileSaver.saveAs(object, fileName);
                        }
                        else {
                            const errorMessage = `Error while trying to export the data to excel`;
                            const type = ToastNotificationType.error;
                            const placement = ToastNotificationPlacement.Body;
                            const message = new ToastNotificationMessage(errorMessage, type, placement);
                            this.toastService.sendToastNotificationMessage(message);
                        }
                        this.loading = false;
                    });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    this.loading = false;
                }
            });
    }

    resetForm(icDetailSearchForm: NgForm): void {
        // This will clear the previous search request data
        icDetailSearchForm.resetForm({
            postalCode: '',
            city: '',
            country: '',
            state: '',
            province: '',
            icNumber: '',
            areaManager: '',
            exactSearch: true
        });

        this.isSearch = false;
        this.searchResult.results = [];
        this.dataService.setOption(Constants.IC_SEARCH_REQUEST_OBJECT, {});
    }
}

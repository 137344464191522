import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { PaginatorComponent } from './shared/paginator/paginator.component';
import { StandarddatepickerComponent } from './shared/standarddatepicker/standarddatepicker.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { ExportConfirmDialogComponent } from './shared/export-confirm-dialog/export-confirm-dialog.component';
import { IclcInformationComponent } from './shared/iclc-information/iclc-information.component';
import { ImportFileComponent } from './shared/import-file/import-file.component';

@NgModule({
    declarations: [
        BreadcrumbComponent,
        PaginatorComponent,
        StandarddatepickerComponent,
        ConfirmDialogComponent,
        ExportConfirmDialogComponent,
        IclcInformationComponent,
        ImportFileComponent
    ],
    imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, MaterialModule, BrowserModule],
    exports: [
        BreadcrumbComponent,
        PaginatorComponent,
        StandarddatepickerComponent,
        ConfirmDialogComponent,
        ExportConfirmDialogComponent,
        IclcInformationComponent,
        ImportFileComponent
    ],
})
export class UtilityModule {}

/* eslint-disable @typescript-eslint/indent */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientSettingsService } from '../../core/services/client-settings.service';
import {
    CustomerDetailsModel,
    CustomerPartySiteAddressModel,
} from './customerdetails.model';

@Injectable({
    providedIn: 'root',
})
export class CustomerDetailsService {
    private webApiEndPoint: string;
    private httpOptions = {
        headers: new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
        }),
    };
    private httpOptionsString = {
        headers: new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
        }),
        responseType: 'text' as 'json'
    };
    constructor(
        private clientSetting: ClientSettingsService,
        private http: HttpClient
    ) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/CustomerDetail/`;
    }

    getOwnerAccountDetails(
        ownerAccountNumber: string
    ): Observable<CustomerDetailsModel[]> {
        const customerPartySite = this.http
            .get<CustomerDetailsModel[]>(
                `${this.webApiEndPoint}searchcustomer/${ownerAccountNumber}`,
                this.httpOptions
            )
            .pipe
            // catchError(this.dataService.handleError('SearchFile', searchRequest))
            ();
        return customerPartySite;
    }

    getCustomerAddress(
        locationId: number
    ): Observable<CustomerPartySiteAddressModel> {
        const address = this.http
            .get<CustomerPartySiteAddressModel>(
                `${this.webApiEndPoint}address/${locationId}`,
                this.httpOptions
            )
            .pipe
            // catchError(this.dataService.handleError('SearchFile', searchRequest))
            ();
        return address;
    }

    autoGenerateFileNumber(ccnPrefix: string): Observable<string> {
        const fileNumber = this.http
            .get<string>(
                `${this.webApiEndPoint}autogen/${ccnPrefix}`,
                this.httpOptionsString
            )
            .pipe
            // catchError(this.dataService.handleError('SearchFile', searchRequest))
            ();
        return fileNumber;
    }

    checkFilenumberAndCCN(ccnPrefix: string, fileNumber: string): Observable<string> {
        let dataInput: string = `${ccnPrefix}_${fileNumber}`;
        return this.http.get<string>(`${this.webApiEndPoint}autogen/${dataInput}`, this.httpOptionsString).pipe();
    }
}

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from '../../constants';
import { CustomerSearchRequestModel, CustomerDetailSearchResultModel } from '../customer-details-search.model';
import { DataService } from '../../core/services/data.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { CustomerSearchService } from '../customer-details.service';

@Component({
    selector: 'app-customer-search',
    templateUrl: './customer-search.component.html',
    styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit, AfterViewInit {
    @ViewChild('customerSearchContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public pageName = '';
    levelTwoTitle = '';
    activeLevel = 0;
    isSearch = false;
    isSearching = false;
    statusOptions = Constants.SHOW_BY_OPTIONS;
    sortOptions = Constants.CUSTOMER_SEARCH_SORT_OPTIONS;

    searchRequest: CustomerSearchRequestModel;
    customerSearchModel: CustomerSearchRequestModel = {
        customerName: '',
        accountNumber: '',
        partySiteNumber: '',
        ccn: '',
        compCCN: '',
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: '',
        sortBy: '',
        filterBy: Constants.ALL
    };
    isAdmin: boolean;
    isEdit: boolean;
    loading = false;
    searchResult: CustomerDetailSearchResultModel;
    searchResultCount: number;
    hasSearchActionOccurred = false;
    noSearchResults = false;
    isTableView = false;
    refreshSearchResultSubscription: Subscription;

    constructor(
        private dataService: DataService,
        private resolver: ComponentFactoryResolver,
        private loadingSpinnerService: LoadingSpinnerService,
        private toastService: ToastNotificationService,
        private searchService: CustomerSearchService) {

    }

    ngOnInit() {
        this.pageName = 'Customer Details';
        this.activeLevel = 1;
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        if (this.dataService.getOption(Constants.CUSTOMER_SEARCH_REQUEST_OBJECT)) {
            this.customerSearchModel = this.dataService.getOption(
                Constants.CUSTOMER_SEARCH_REQUEST_OBJECT
            );
            // this.onSearch(this.fileSearchModel);
        }
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
    }

    ngAfterViewInit() {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    onViewChange = (sortingEvent: Paginator) => {
        this.isTableView = sortingEvent.isTableView;
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        this.customerSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.customerSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];
        this.customerSearchModel.filterBy = sortingEvent.filterBy;
        this.customerSearchModel.pageNumber = sortingEvent.page;
        this.customerSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.customerSearchModel);
    }

    onSearch = (formValues) => {
        this.customerSearchModel.pageNumber = 1;
        this.customerSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
        this.customerSearchModel.sortDirection = this.sortOptions[0].value.split(
            '-'
        )[1];
        this.customerSearchModel.sortBy = this.sortOptions[0].value.split(
            '-'
        )[0];
        this.getSearchResult(formValues);
    };

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            const factory = this.resolver.resolveComponentFactory(
                LoaderComponent
            );
            const componentRef = this.entry.createComponent(factory);
            this.dataService.setOption(
                Constants.CUSTOMER_SEARCH_REQUEST_OBJECT,
                formValues
            );

            this.searchService
                .getSearchResult(this.customerSearchModel)
                .subscribe(
                    (searchResult) => {
                        this.searchResult = searchResult;//Constants.CUSTOMER_DETAIL_DATA;
                        this.searchResultCount = searchResult.paging.totalRecords;
                        componentRef.destroy();
                        if (this.searchResultCount === 0) {
                            this.isSearch = false;
                            const errorMessage =
                                'No Data found for the specified search criteria';
                            const type = ToastNotificationType.infoBlue;
                            const placement = ToastNotificationPlacement.Body;
                            const message = new ToastNotificationMessage(
                                errorMessage,
                                type,
                                placement
                            );
                            this.toastService.sendToastNotificationMessage(
                                message
                            );
                            this.hasSearchActionOccurred = true;
                            this.noSearchResults = true;
                            return;
                        }
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = false;
                        this.isSearch = true;
                    },
                    (error) => {
                        componentRef.destroy();
                        const errorMessage = 'Error while retrieving the data';
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(
                            errorMessage,
                            type,
                            placement
                        );
                        this.toastService.sendToastNotificationMessage(message);
                        this.searchResultCount = 0;
                        this.isSearch = false;
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = true;
                        this.isTableView = false;
                    }
                );

        } else {
            const errorMessage =
                'Please enter the search criteria in the form below';
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(
                errorMessage,
                type,
                placement
            );
            this.toastService.sendToastNotificationMessage(message);
            this.searchResultCount = 0;
            this.isSearch = false;
            this.isTableView = false;
        }
    };

    resetForm(): void {
        // This will clear the previous search request data
        this.dataService.setOption(Constants.CUSTOMER_SEARCH_REQUEST_OBJECT, {});
    }

}

<div class="w-full text-center pt-32">
  <div>
    <div>
      <img alt="logout-image" src="../../../assets/images/loggedOut.png" class="Bitmap mx-auto" />
    </div>
    <div>
      <div class="feedback-header mx-auto p-2.5">
        You have successfully logged out!
      </div>
    </div>
    <div>
      <div>
        If you wish to login again, click the Login link in the header.
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCcnSearchComponent } from './product-ccn-search/product-ccn-search.component';
import { ProductCcnSearchResultCardComponent } from './product-ccn-search-result-card/product-ccn-search-result-card.component';
import { ProductCcnViewComponent } from './product-ccn-view/product-ccn-view.component';
import { AppRoutingModule } from '../app-routing.module';
import { UtilityModule } from '../utility';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreateProductCcnComponent } from './create-product-ccn/create-product-ccn.component';
import { UppercaseDirectiveModule } from '../shared/uppercase.directive';

@NgModule({
    declarations: [
        ProductCcnSearchComponent,
        ProductCcnSearchResultCardComponent,
        ProductCcnViewComponent,
        CreateProductCcnComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        AppRoutingModule,
        UtilityModule,
        UppercaseDirectiveModule
    ],
})
export class ProductCcnModule { }

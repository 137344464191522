export enum ActionType {
    create = 'Add New',
    edit = 'Edit',
}

export interface CreateEditProductCcnModel {
    productCcnRecId: number;
    productCcn: string;
    productType: string;
    deliverable: string;
    deliverableType: string;
    industry: string;
    typeOfService: string;
    fusSampleLines: string;
    isML: string;
    invoiceText: string;
    subIndustry: string;
}

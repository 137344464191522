import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientSettingsService } from '../core/services/client-settings.service';
import { CheckVolumeRequest, CreateEditFileVolume } from './create-file-volume/create-file-volume.model';
import {
    FileVolumeSearchRequest, FileVolumeSearchResultModel, WithdrawFileVolume,
    FileVolumeSummary, ReactivateVolume, ReactivateVolumeOutput
} from './file-volume-detail-search.model';

@Injectable({ providedIn: 'root' })
export class FileVolumeService {
    private webApiEndPoint: string;
    private readonly refreshSearchResult = new Subject<any>();
    public refreshSearchResult$ = this.refreshSearchResult.asObservable();
    private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private httpOptionsString = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'blob' as 'json'
    };

    constructor(private clientSetting: ClientSettingsService, private http: HttpClient) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/FileVolume/`;
    }

    onRefreshSearchResult() {
        this.refreshSearchResult.next(); // This will trigger search API to fetch results again
    }

    getSearchResult(searchRequest: FileVolumeSearchRequest): Observable<FileVolumeSearchResultModel> {
        const searchResult = this.http.post<FileVolumeSearchResultModel>(`${this.webApiEndPoint}search`, searchRequest, this.httpOptions).pipe();
        return searchResult;
    }

    checkCcnExists(ccnValue: string): Observable<boolean> {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}CheckCcnExists`, JSON.stringify(ccnValue), this.httpOptions).pipe();
        return result;
    }

    checkVolumeExists = (request: CheckVolumeRequest): Observable<boolean> => {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}check`, request, this.httpOptions).pipe();
        return result;
    }

    withdrawFileVolume(withdrawFileVolRequest: WithdrawFileVolume) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}withdraw`, withdrawFileVolRequest, this.httpOptions).pipe();
        return result;
    }

    deleteFileVolume(fileVolRecId: number, userId: string) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}delete/${fileVolRecId}`, JSON.stringify(userId), this.httpOptions).pipe();
        return result;
    }

    addNewVolume = (request: CreateEditFileVolume): Observable<boolean> => {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}create`, request, this.httpOptions).pipe();
        return result;
    }

    updateFileVolume = (request: CreateEditFileVolume): Observable<boolean> => {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}update`, request, this.httpOptions).pipe();
        return result;
    }

    getFileVolDetails(fileVolRecId: number): Observable<FileVolumeSummary> {
        const fileDetails = this.http.get<FileVolumeSummary>(`${this.webApiEndPoint}${fileVolRecId}`, this.httpOptions).pipe();
        return fileDetails;
    }

    exportToExcel = (searchRequest: FileVolumeSearchRequest) => {
        return this.http.post(`${this.webApiEndPoint}exportToExcel`, searchRequest, this.httpOptionsString).pipe();
    }

    reactivateVolume = (reactivateVolumeInput: ReactivateVolume): Observable<ReactivateVolumeOutput> => {
        return this.http.put<ReactivateVolumeOutput>(`${this.webApiEndPoint}reactivate`,
            reactivateVolumeInput, this.httpOptions).pipe();
    }

    checkToEnableAuthPage = (fileVolRecId: string): Observable<boolean> => {
        return this.http.post<boolean>(`${this.webApiEndPoint}checkToEnableAuthPage`,
            JSON.stringify(fileVolRecId), this.httpOptions).pipe();
    }

    getVolumeList = (fileRecId: number): Observable<Object> => {
        return this.http.get<Object>(`${this.webApiEndPoint}getVolume/${fileRecId}`, this.httpOptions).pipe();
    }
}
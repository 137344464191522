<div class="px-20">
  <app-breadcrumb [levelOneTitle]="pageName" [levelOneURL]="levelOneURL" [activeTitleLevel]="activeLevel">
  </app-breadcrumb>
</div>
<div>
    <mat-card class="main-card mat-elevation-z2">
        <mat-card-header>
            <div class="header-text w-full">
                <h4>SEARCH FOR LISTEE ASSOCIATION</h4>
            </div>
            <div class="additional-menu float-right">
                <button [matMenuTriggerFor]="menu" class="additional-menu-button" aria-label="Menu buttom">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="additional-menu-item" (click)="exportToExcel()"
                            [disabled]="!hasSearchActionOccurred || noSearchResults">
                        <mat-icon>upload_file</mat-icon>
                        <span>Export to Excel</span>
                    </button>
                </mat-menu>
            </div>
        </mat-card-header>
        <mat-card-content class="main-card-content">
            <form #listeeAssociationSearchForm="ngForm" (ngSubmit)="onSearch(listeeAssociationSearchForm.value)">
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inputBasicFileNumber" class="form-label">Applicant File Number</label>
                        <div>
                            <input type="text" name="fileNumber" [(ngModel)]="listeeSearchModel.fileNumber"
                                   #fileNumber="ngModel" class="form-control" id="inputBasicFileNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputFileVolume" class="form-label">Volume</label>
                        <div>
                            <input type="text" name="volume" #fileVolume="ngModel" [(ngModel)]="listeeSearchModel.volume"
                                   class="form-control" id="inputFileVolume" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputOwnerAccount" class="form-label">Applicant Account Number</label>
                        <div>
                            <input type="text" name="ownerAccountNumber" [(ngModel)]="listeeSearchModel.ownerAccountNumber"
                                   #ownerAccountNumber="ngModel" class="form-control" id="inputOwnerAccount" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputListeeAccountNumber" class="form-label">Listee Account Number</label>
                        <div>
                            <input type="text" name="listeeAccountNumber" [(ngModel)]="listeeSearchModel.listeeAccountNumber"
                                   #listeeAccountNumber="ngModel" class="form-control" id="inputListeeAccountNumber" />
                        </div>
                    </div>
                </div>
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inputPartySiteNumber" class="form-label">Listee Party Site Number</label>
                        <div>
                            <input type="text" name="partySiteNumber" [(ngModel)]="listeeSearchModel.partySiteNumber"
                                   #subscriberNumber="ngModel" class="form-control" id="inputPartySiteNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputListeeFileNumber" class="form-label">Listee File Number</label>
                        <div>
                            <input type="text" name="listeeFileNumber" [(ngModel)]="listeeSearchModel.listeeFileNumber"
                                   #listeeFileNumber="ngModel" class="form-control" id="inputListeeFileNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputSubscriberNumber" class="form-label">Listee Subscriber Number</label>
                        <div>
                            <input type="text" name="subscriberNumber" [(ngModel)]="listeeSearchModel.subscriberNumber"
                                   #subscriberNumber="ngModel" class="form-control" id="inputSubscriberNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="filterBy" class="form-label">Filter by Status</label>
                        <div>
                            <mat-select class="form-control" id="filterBy" name="filterBy" [(ngModel)]="listeeSearchModel.filterBy">
                                <mat-option [value]="options.value" *ngFor="let options of statusOptions">{{ options.key }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="listeeType" class="form-label">Listee Type</label>
                        <div>
                            <mat-select class="form-control" id="listeeType" name="listeeType" [(ngModel)]="listeeSearchModel.listeeType">
                                <mat-option [value]="options.value" *ngFor="let options of listeeType">{{ options.key }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="md-3 row">
                    <div class="col-md-4">
                        <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty form-label" name="exactSearch"
                                        [(ngModel)]="listeeSearchModel.exactSearch">
                            Exact Search
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="text-right col-md-4">
                        <button class="mr-2 button form-clear-button" type="button" (click)="resetForm(listeeAssociationSearchForm)">Clear</button>
                        <button class="button form-button" type="submit">Search</button>
                    </div>
                </div>
            </form>
            <div class="w-full m-0" *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
                <hr />
                <app-paginator (sortingChange)='onSortByChange($event)' (viewChange)='onViewChange($event)' (paginationChange)='onSortPaginationChange($event)'
                               [isTableView]="isTableView" [length]="searchResultCount" [sortByOptions]="sortOptions" [showSortBy]="true"
                               [filterByOptions]="statusOptions" [showFilterBy]="false" [showPageSize]="true" [pageIndex]="0">
                </app-paginator>
            </div>
        </mat-card-content>
    </mat-card>
    <ng-template #listeeContainer>
    </ng-template>
    <div *ngIf="noSearchResults && hasSearchActionOccurred">
        <div class="ml-20">
            <div class="flex flex-col justify-center max-w-full mx-auto my-3">
                <div class="flex"><img src="../../../assets/images/noResults.png" class="Bitmap mx-auto" /></div>
                <div class="flex"><div class="feedback-header mx-auto title-no-data">No Listee Details Found</div></div>
            </div>
        </div>
    </div>
    <div *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
        <div class="search-details-paginator">
            <div *ngIf="searchResult && searchResult.results && !isTableView">
                <app-listee-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                               (withdrawOrDeleteAction)="onWithdrawOrDelete($event)">
                </app-listee-search-result-card>
            </div>
            <div *ngIf="searchResult && searchResult.results && isTableView">
                <app-listee-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                               (withdrawOrDeleteAction)="onWithdrawOrDelete($event)" (sortingChangeAction)="onSortingChange($event)">
                </app-listee-search-result-card>
            </div>
        </div>
    </div>
    <div class="loader-center" *ngIf="loading">
        <div id="loader" class="loader"></div>
    </div>
</div>
import {
    Component,
    OnInit,
    ViewContainerRef,
    ViewChild,
    ComponentFactoryResolver, ElementRef  } from '@angular/core';
import { ActionType, CreateEditMfrModel, CheckMfrExistsRequest } from './create-mfr.model';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormControl,
    FormGroup,
    FormArray,
    FormBuilder,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { SidenavConfig } from '../../shared/side-nav/sidenav-config';
import { DataService } from '../../core/services/data.service';
import { MfrDetailService } from '../mfr-detail.service';
import { Constants } from '../../constants';
import { WdrwlCodesSummary } from '../../shared/utility.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { DeliverableItemInstance } from '../mfr-detail-search.model';
import {
    CustomerDetailsModel,
    CustomerPartySiteModel,
} from '../../shared/customer-details/customerdetails.model';
import { CustomerDetailsService } from '../../shared/customer-details/customerdetails.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';
import { SideNavLoaderComponent } from '../../shared/side-nav-loader/side-nav-loader.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { FileVolumeService } from '../../file-volume/file-volume.service';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-create-new-mfr',
    templateUrl: './create-new-mfr.component.html',
    styleUrls: ['./create-new-mfr.component.scss'],
})
export class CreateNewMfrComponent implements OnInit {
    @ViewChild('CreateNewMfr', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    @ViewChild('partySiteSelectBox') partySiteSelectBox: ElementRef;
    @ViewChild('mfrSameAsApplicant') sameAsApplicantCheckBox: MatCheckbox;
    @ViewChild('selectedVolumes') selectedVolumes: MatSelect;

    actionType = ActionType;
    action = this.actionType.create;
    mfrAssoType = Constants.MFR_TYPE;
    model: CreateEditMfrModel = {
        fileRecId: 0,
        fileVolRecId: 0,
        mfrAssoRecId: 0,
        fileNumber: '',
        volume: '',
        startDate: '',
        endDate: '',
        deliverableItemInstance: [],
        mfrAccountNumber: '',
        orgNumber: '',
        mfrPartySiteNumber: '',
        mfrPartyNumber: '',
        mfrSubscriberNumber: '',
        wdrwlRecId: 0,
        wdrwlCode: '',
        mfrCompanyName: '',
        mfrCompanyAddress: '',
        serviceContract: '',
        mfrType: '',
        mfrFactoryId: '',
        lastUpdatedBy: '',
        mfrStatus: '',
        mfrAddress1: '',
        mfrAddress2: '',
        mfrAddress3: '',
        mfrAddress4: '',
        mfrAddress5: '',
        mfrAddress6: '',
        mfrCity: '',
        mfrProvince: '',
        mfrState: '',
        mfrCountry: '',
        mfrPostalCode: '',
        isAddToAllVolume: false,
        volumeList: ''
    };
    models: CreateEditMfrModel[];
    wdrwlCodes: WdrwlCodesSummary[];
    mfrTypes = Constants.MFR_TYPE;
    deliverableItems: FormArray;
    deletedDeliverableItem: DeliverableItemInstance[];
    customerPartySiteDetails: CustomerPartySiteModel[] = [];
    selectedcustomerPartySite: CustomerPartySiteModel;
    ownerAccountDetails: CustomerDetailsModel = {
        partyNumber: null,
        accountNumber: null,
        ownerCompanyName: null,
        customerPartySiteDetails: this.customerPartySiteDetails,
    };

    sameAsApplicantCheckBoxValue = false;
    mfrStartDate = null;
    mfrEndDate = null;
    rawData; // To save a snippet of the data to get address column wise
    createEditMfrForm: FormGroup;
    factory; componentRef; // Global variables for the creating and destroying the loading spinner
    volumeList;
    isSelectedVolumeDisabled = false; // This will toggle the volume select box when add to all volume chosen

    constructor(
        public sidenavConfig: SidenavConfig,
        private mfrService: MfrDetailService,
        private dataService: DataService,
        private toastService: ToastNotificationService,
        private formBuilder: FormBuilder,
        private customerDetailsService: CustomerDetailsService,
        private resolver: ComponentFactoryResolver,
        private volumeService: FileVolumeService
    ) {}

    ngOnInit(): void {
        this.factory = this.resolver.resolveComponentFactory(
            SideNavLoaderComponent
        );
        this.models = [];
        this.deletedDeliverableItem = [];

        this.createEditMfrForm = this.formBuilder.group({
            fileVolRecId: this.formBuilder.control(null),
            mfrAssoRecId: this.formBuilder.control(null),
            mfrAccountNumber: this.formBuilder.control(
                { value: null, disabled: false },
                {
                    validators: [Validators.required],
                    asyncValidators: [this.ownerAccountValidator()],
                    updateOn: 'blur',
                }
            ),
            orgNumber: this.formBuilder.control({ value: null, disabled: true }),
            mfrPartySiteNumber: this.formBuilder.control(null),
            mfrSubscriberNumber: this.formBuilder.control({ value: null, disabled: true }),
            mfrCompanyName: this.formBuilder.control({ value: null, disabled: true }),
            mfrCompanyAddress: this.formBuilder.control({
                value: null,
                disabled: true,
            }),
            fileNumber: this.formBuilder.control({ value: null, disabled: true }),
            volume: this.formBuilder.control({ value: null, disabled: true }),
            mfrType: this.formBuilder.control(null),
            deliverableItemInstance: this.formBuilder.array([this.createItem(null)]),
            wdrwlCode: this.formBuilder.control({ value: null, disabled: true }),
            wdrwlRecId: this.formBuilder.control(null),
            startDate: this.formBuilder.control({ value: this.dataService.getCurrentDate() }),
            endDate: this.formBuilder.control({ value: null, disabled: true }),
            mfrStatus: this.formBuilder.control(null),
            serviceContract: this.formBuilder.control(null),
            lastUpdatedBy: this.formBuilder.control(null),
            mfrFactoryId: this.formBuilder.control(null),
            mfrPartyNumber: this.formBuilder.control(null),
            isAddToAllVolume: this.formBuilder.control({ value: false }),
            volumeList: this.formBuilder.control(null)
        });

        this.wdrwlCodes = this.sidenavConfig.data.wdwrlCode;

        if (this.sidenavConfig.data.action === 'create') {
            this.action = this.actionType.create;
            this.getVolumeList(this.sidenavConfig.data.createMfrModel.fileRecId);
        } else if (this.sidenavConfig.data.action === 'edit') {
            this.action = this.actionType.edit;
            this.enableOrDisableFields();
        }
        this.setFormValues(this.sidenavConfig.data.createMfrModel);
    }

    /**
     * This will retrive the list of non ML volumes
     * for adding the MFR
     * @param fileRecId
     */
    getVolumeList = (fileRecId: number) => {
        this.componentRef = this.entry.createComponent(this.factory);
        this.volumeService.getVolumeList(fileRecId).subscribe(result => {
            if (result) {
                this.volumeList = result;
            }
            else
            {
                this.volumeList = [];
            }
            this.componentRef.destroy();
        },
            error => {
                this.volumeList = [];
                this.componentRef.destroy();
            });
    }

    createItem(item): FormGroup {
        if (item) {
            return this.formBuilder.group({
                deliverableItemInstanceId: item.deliverableItemInstanceId,
                deliverableItemInstanceName: item.deliverableItemInstanceName,
                action: Constants.UPDATED
            })
        }
        return this.formBuilder.group({
            deliverableItemInstanceId: 0,
            deliverableItemInstanceName: '',
            action: Constants.ADD
        });
    }

    enableOrDisableFields() {
        this.createEditMfrForm.get('mfrAccountNumber').disable();
        this.createEditMfrForm.get('mfrPartySiteNumber').disable();
        this.createEditMfrForm.get('mfrSubscriberNumber').disable();
        this.createEditMfrForm.get('wdrwlCode').enable();
    }

    setFormValues(data: CreateEditMfrModel) {
        const isEdit = this.sidenavConfig.data.action === 'edit';
        if (isEdit) {
            this.getPartySiteDetails(data.mfrAccountNumber, data.mfrPartySiteNumber);
        }
        this.deliverableItems = this.createEditMfrForm.get('deliverableItemInstance') as FormArray;
        this.deliverableItems.removeAt(0);
        data.deliverableItemInstance.forEach(item => this.deliverableItems.push(this.createItem(item)));

        this.createEditMfrForm.patchValue({
            fileVolRecId: data.fileVolRecId,
            mfrAssoRecId: data.mfrAssoRecId,
            mfrAccountNumber: isEdit ? data.mfrAccountNumber : '',
            orgNumber: isEdit ? data.orgNumber : null,
            mfrPartySiteNumber: isEdit ? data.mfrPartySiteNumber : null,
            mfrPartyNumber: data.mfrPartyNumber,
            mfrSubscriberNumber:'' ,
            mfrCompanyName: '',
            mfrCompanyAddress: '',
            fileNumber: data.fileNumber,
            volume: data.volume,
            mfrType: data.mfrType,
            wdrwlCode: data.wdrwlCode,
            wdrwlRecId: data.wdrwlRecId,
            startDate: new Date(data.startDate),
            endDate: data.endDate ? new Date(data.endDate) : null,
            mfrStatus: data.mfrStatus,
            serviceContract: data.serviceContract,
            mfrFactoryId: data.mfrFactoryId,
            isAddToAllVolume: false,
            lastUpdatedBy: data.lastUpdatedBy
        });
    }

    /**
     * This function will set the account number
     * and the partysite number of the applicant
     * file data.
     * Post that will set all the address details
     * and other details related to account
     * @param value
     */
    setAccountDetails(value) {
        this.sameAsApplicantCheckBoxValue = value;
        if (value) {
            const data = this.sidenavConfig.data.createMfrModel;
            this.createEditMfrForm.patchValue({
                mfrAccountNumber: data.mfrAccountNumber,
            });
        }
    }

    getCompanyAddress() {
        this.createEditMfrForm
            .get('mfrCompanyName')
            .setValue(this.ownerAccountDetails.ownerCompanyName);
        this.createEditMfrForm
            .get('mfrSubscriberNumber')
            .setValue(this.selectedcustomerPartySite.subscriberNumber);

        this.rawData = this.selectedcustomerPartySite; // To get the address based on the individual fields while saving

        let customerAddress = '';
        if (this.selectedcustomerPartySite.address1) {
            customerAddress = `${this.selectedcustomerPartySite.address1}`;
        }
        if (this.selectedcustomerPartySite.address2) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address2}`;
        }
        if (this.selectedcustomerPartySite.address3) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address3}`;
        }
        if (this.selectedcustomerPartySite.address4) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address4}`;
        }
        if (this.selectedcustomerPartySite.city) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.city}`;
        }
        if (this.selectedcustomerPartySite.province) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.province}`;
        }
        if (this.selectedcustomerPartySite.state) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.state}`;
        }
        if (this.selectedcustomerPartySite.county) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.county}`;
        }
        if (this.selectedcustomerPartySite.country) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.country}`;
        }
        if (this.selectedcustomerPartySite.postalCode) {
            customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.postalCode}`;
        }
        this.createEditMfrForm
            .get('mfrCompanyAddress')
            .setValue(customerAddress);
        this.componentRef.destroy();
    }

    changePartySiteNumber(e) {
        this.componentRef = this.entry.createComponent(this.factory);
        // Temp solution to set party site number on same as applicant
        const partySiteNumber = this.createEditMfrForm.controls['mfrPartySiteNumber'].value;
        this.selectedcustomerPartySite = this.ownerAccountDetails.customerPartySiteDetails.find(
            (f) => f.partySiteNumber === Number(partySiteNumber) //Number(e.target.value)
        );
        // call API for getting the customer address and set subscriber here.
        this.getCompanyAddress();
    }

    getPartySiteDetails(accountNumber: string, partySiteNumber: string) {
        this.componentRef = this.entry.createComponent(this.factory);
        this.customerDetailsService
            .getOwnerAccountDetails(accountNumber)
            .subscribe(result => {
                if (result && result.length > 0) {
                    this.createEditMfrForm
                        .get('orgNumber')
                        .setValue(result[0].partyNumber);
                    this.createEditMfrForm
                        .get('mfrPartyNumber')
                        .setValue(result[0].partyNumber);
                    this.ownerAccountDetails = result[0];
                    this.createEditMfrForm.controls['mfrPartySiteNumber'].setValue(Number(partySiteNumber));
                    if (this.sameAsApplicantCheckBoxValue) {
                        // When Same as Applicant is chosen we need to trigger the change listener
                        // for partysite number manually to check if it already exits for the 
                        // file volume combination
                        this.componentRef.destroy();
                        (this.partySiteSelectBox.nativeElement as HTMLSelectElement).dispatchEvent(
                            new Event("change")
                        );
                    }
                    else {
                        this.selectedcustomerPartySite = this.ownerAccountDetails.customerPartySiteDetails.find(
                            (f) => f.partySiteNumber === Number(partySiteNumber)
                        );
                        // call API for getting the customer address and set subscriber here.
                        this.getCompanyAddress();
                    }
                    return null;
                }
                else {
                    this.componentRef.destroy();
                    this.createEditMfrForm.controls['mfrAccountNumber'].setErrors({ 'invalidOwnerAcount': true});
                }
            },
            (error) => {
                this.componentRef.destroy();
                of({ invalidOwnerAcount: true })
            });
    }

    ownerAccountValidator(): AsyncValidatorFn {
        return (
            control: AbstractControl
        ):
            | Promise<{ [key: string]: any } | null>
            | Observable<{ [key: string]: any } | null> =>
            control.valueChanges.pipe(
                debounceTime(500),
                take(1),
                switchMap((_) => {
                    this.componentRef = this.entry.createComponent(this.factory);
                    this.createEditMfrForm.controls['mfrPartySiteNumber'].setValue(null);
                    this.createEditMfrForm.controls['mfrPartySiteNumber'].markAsUntouched();
                    this.createEditMfrForm.controls['mfrPartySiteNumber'].markAsPristine();

                    return this.customerDetailsService
                        .getOwnerAccountDetails(control.value)
                        .pipe(
                            map((result: CustomerDetailsModel[]) => {
                                if (result && result.length > 0) {
                                    this.componentRef.destroy();
                                    this.createEditMfrForm
                                        .get('orgNumber')
                                        .setValue(result[0].partyNumber);
                                    this.ownerAccountDetails = result[0];
                                    if (this.sameAsApplicantCheckBoxValue) {
                                        // When same as applicant checked we need to call the 
                                        // getPartySiteDetails function manually to set the company name
                                        // address and other details
                                        const data = this.sidenavConfig.data.createMfrModel;
                                        this.getPartySiteDetails(data.mfrAccountNumber,
                                            data.mfrPartySiteNumber);
                                    }
                                    return null;
                                } else {
                                    this.dataService.navigateToInnerSideNavTop();
                                    this.sameAsApplicantCheckBoxValue = false;
                                    this.createEditMfrForm
                                        .get('mfrAccountNumber')
                                        .setValue('');
                                    const errorMessage = `No Partysite number associated to given account number`;
                                    const type = ToastNotificationType.error;
                                    const placement = ToastNotificationPlacement.InnerSidenav;
                                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                                    this.toastService.sendToastNotificationMessage(message);
                                    this.componentRef.destroy();
                                    return { invalidOwnerAcount: true };
                                }
                            }),
                            catchError((error) => {
                                this.componentRef.destroy();
                                return of({ invalidOwnerAcount: true })
                            })
                        )
                })
            );
    }

    onClose(): void {
        if (this.sidenavConfig.data.sideNav) {
            this.sidenavConfig.data.sideNav.close();
            this.mfrService.onRefreshSearchResult();
        }
    }

    addNewDeliverableItem = (): void => {
        this.deliverableItems = this.createEditMfrForm.get('deliverableItemInstance') as FormArray;
        this.deliverableItems.push(this.createItem(null));
    };

    removeDeliverableItem = (index): void => {
        this.deliverableItems = this.createEditMfrForm.get('deliverableItemInstance') as FormArray;
        if (this.deliverableItems.value[index].deliverableItemInstanceId != 0) {
            this.deliverableItems.value[index].action = Constants.DELETE;
            this.deletedDeliverableItem.push(this.deliverableItems.value[index]);
        }
        this.deliverableItems.removeAt(index);
        if (this.deliverableItems.length < 1) {
            this.addNewDeliverableItem();
        }
    };

    getDate = (date: string) => {
        return this.dataService.getDate(date);
    }

    getWidrawalCodeId(value): number {
        let id = 0;
        this.wdrwlCodes.forEach((data) => {
            if (data.wdrwlCode == value) {
                id = data.wdrwlRecId;
            }
        });
        return id;
    }

    addToAllVolume(value) {
        this.model.isAddToAllVolume = value;
        this.isSelectedVolumeDisabled = value;
    }

    setAddressFields() {
        if (this.rawData) {
            if (this.rawData.address1) {
                this.model.mfrAddress1 = this.rawData.address1;
            }
            else {
                this.model.mfrAddress1 = null;
            }
            if (this.rawData.address2) {
                this.model.mfrAddress2 = this.rawData.address2;
            }
            else {
                this.model.mfrAddress2 = null;
            }
            if (this.rawData.address3) {
                this.model.mfrAddress3 = this.rawData.address3;
            }
            else {
                this.model.mfrAddress3 = null;
            }
            if (this.rawData.address4) {
                this.model.mfrAddress4 = this.rawData.address4;
            }
            else {
                this.model.mfrAddress4 = null;
            }
            if (this.rawData.address5) {
                this.model.mfrAddress5 = this.rawData.address5;
            }
            else {
                this.model.mfrAddress5 = null;
            }
            if (this.rawData.address6) {
                ;
                this.model.mfrAddress6 = this.rawData.address6;
            }
            else {
                this.model.mfrAddress6 = null;
            }
            if (this.rawData.city) {
                this.model.mfrCity = this.rawData.city;
            }
            else {
                this.model.mfrCity = null;
            }
            if (this.rawData.province) {
                this.model.mfrProvince = this.rawData.province;
            }
            else {
                this.model.mfrProvince = null;
            }
            if (this.rawData.state) {
                this.model.mfrState = this.rawData.state;
            }
            else {
                this.model.mfrState = null;
            }
            if (this.rawData.country) {
                this.model.mfrCountry = this.rawData.country;
            }
            else {
                this.model.mfrCountry = null;
            }
            if (this.rawData.postalCode) {
                this.model.mfrPostalCode = this.rawData.postalCode;
            }
            else {
                this.model.mfrPostalCode = null;
            }
        } else {
            this.model.mfrAddress1 = null;
            this.model.mfrAddress2 = null;
            this.model.mfrAddress3 = null;
            this.model.mfrAddress4 = null;
            this.model.mfrAddress5 = null;
            this.model.mfrAddress6 = null;
            this.model.mfrPostalCode = null;
            this.model.mfrCity = null;
            this.model.mfrProvince = null;
            this.model.mfrState = null;
            this.model.mfrCountry = null;
        }
    }

    resetForm(): void {
        // This will reset the form
        this.sameAsApplicantCheckBoxValue = false;
        this.isSelectedVolumeDisabled = false;
        this.createEditMfrForm.patchValue({
            mfrAccountNumber: '',
            orgNumber: '',
            mfrPartySiteNumber: null,
            mfrPartyNumber: '',
            mfrSubscriberNumber: '',
            mfrCompanyName: '',
            mfrCompanyAddress: '',
            mfrType: null,
            wdrwlCode: '',
            wdrwlRecId: 0,
            startDate: new Date(),
            endDate: null,
            mfrFactoryId: '',
            isAddToAllVolume: false,
            deliverableItemInstance: [],
            volumeList: ''
        });
        this.createEditMfrForm.markAsUntouched();
        this.createEditMfrForm.markAsPristine();
    }

    /**
     * This will convert the volume list array to 
     * a comma separated string
     * @param selectedVolumes
     */
    formatVolumeList = () => {
        let volumeList = "";
        const selectedVolumes = this.selectedVolumes.value;
        const currentVolume = this.createEditMfrForm.get('volume').value;
        if (selectedVolumes != null && selectedVolumes.length > 0) {
            if (selectedVolumes.indexOf(currentVolume) < 0)
                volumeList += `'${currentVolume}',`;

            selectedVolumes.forEach(volume => {
                volumeList += `'${volume}',`
            })
            volumeList = volumeList.substring(0, volumeList.length - 1);
        }
        return volumeList;
    }

    validateDeliverableInstance(instances) {
        let tempArray = [];
        let validFlag = true;
        const instanceLength = instances.length;

        for (let i = 0; i < instanceLength; i++)
        {
            if ((tempArray.indexOf(instances[i].deliverableItemInstanceName) > -1)
                && (instances[i].action == Constants.UPDATED ||
                    instances[i].action == Constants.ADD)) {
                // To check if the added or updated instance is similar to any other
                // instace in the given list
                validFlag = false;
                break;
            }
            if (instances[i].deliverableItemInstanceName.trim().length > 0) {
                tempArray.push(instances[i].deliverableItemInstanceName.trim());
            }
        }
        return validFlag;
    }
    onSubmit(data) {
        // call the Add API here
        this.componentRef = this.entry.createComponent(this.factory);

        this.model.fileVolRecId = data.fileVolRecId;
        this.model.mfrAssoRecId = data.mfrAssoRecId ? data.mfrAssoRecId : 0;
        this.model.fileNumber = this.createEditMfrForm.get('fileNumber').value;
        this.model.mfrAccountNumber = this.createEditMfrForm.get('mfrAccountNumber').value;
        this.model.orgNumber = this.createEditMfrForm.get('orgNumber').value ? this.createEditMfrForm.get('orgNumber').value : null;
        this.model.mfrStatus = data.mfrStatus ? data.mfrStatus : null;
        this.model.mfrPartySiteNumber = this.createEditMfrForm.get('mfrPartySiteNumber').value;
        this.model.mfrPartyNumber = this.createEditMfrForm.get('orgNumber').value ? this.createEditMfrForm.get('orgNumber').value : null;
        this.model.mfrSubscriberNumber = this.createEditMfrForm.get('mfrSubscriberNumber').value ? this.createEditMfrForm.get('mfrSubscriberNumber').value : null;
        this.model.mfrCompanyName = this.createEditMfrForm.get('mfrCompanyName').value ? this.createEditMfrForm.get('mfrCompanyName').value : null;
        this.model.lastUpdatedBy = this.dataService.getLastUpdatedBy();
        this.model.mfrType = data.mfrType;
        this.model.wdrwlRecId = this.getWidrawalCodeId(data.wdrwlCode);
        this.model.mfrFactoryId = data.mfrFactoryId;
        this.model.endDate = null;

        if (this.action == this.actionType.create)
        {
          this.model.volumeList = this.formatVolumeList();   
        }

        if (this.model.wdrwlRecId != 0) {
            this.model.mfrStatus = 'W';
            this.model.endDate = this.dataService.customDateFormat(null);
        }

        if (data.startDate) {
            this.model.startDate = this.dataService.customDateFormat(this.getDate(data.startDate));
        }
        else {
            this.model.startDate = this.dataService.customDateFormat(null);
        }

        if (this.mfrEndDate) {
            this.model.endDate = this.dataService.customDateFormat(this.getDate(this.mfrEndDate));
        }

        this.setAddressFields();

        this.model.deliverableItemInstance = this.createEditMfrForm.get('deliverableItemInstance').value;

        this.deletedDeliverableItem.forEach(data => this.model.deliverableItemInstance.push(data));

        const flag = this.validateDeliverableInstance(this.model.deliverableItemInstance);

        // To allow the insert or update when deliverable instance is valid
        if (flag) {
            if (this.action == this.actionType.create) {
                this.mfrService.addNewMfr(this.model).subscribe((result) => {
                    if (result) {
                        this.models.push(this.sidenavConfig.data.createMfrModel);
                        this.model = this.models[this.models.length - 1];
                        this.dataService.navigateToInnerSideNavTop();
                        const successMessage = `Manufacturer Details have been added successfully`;
                        const type = ToastNotificationType.success;
                        const placement = ToastNotificationPlacement.InnerSidenav;
                        const message = new ToastNotificationMessage(successMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.componentRef.destroy();
                        this.sameAsApplicantCheckBox.checked = false;
                        this.deliverableItems = this.createEditMfrForm.get('deliverableItemInstance') as FormArray;
                        this.deliverableItems.clear(); // To clear the added deliverable instance
                        this.addNewDeliverableItem(); // To add a empty deliverable instance block
                        this.resetForm();
                    }
                    else {
                        this.dataService.navigateToInnerSideNavTop();
                        const errorMessage = `Error while trying to add the manufacturer details`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.InnerSidenav;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.componentRef.destroy();
                    }
                },
                    error => {
                        this.dataService.navigateToInnerSideNavTop();
                        const errorMessage = `Error while trying to add the manufacturer details`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.InnerSidenav;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.componentRef.destroy();
                    });
            } else {
                this.mfrService.updateMfrDetail(this.model).subscribe((result) => {
                    if (result) {
                        this.model.deliverableItemInstance = this.model.deliverableItemInstance.filter(data => (data.action != Constants.DELETE));
                        this.dataService.navigateToInnerSideNavTop();
                        const successMessage = `Manufacturer Details have been updated successfully`;
                        const type = ToastNotificationType.success;
                        const placement = ToastNotificationPlacement.InnerSidenav;
                        const message = new ToastNotificationMessage(successMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.componentRef.destroy();
                    }
                    else {
                        this.dataService.navigateToInnerSideNavTop();
                        const errorMessage = `Error while trying to update the manufacturer details`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.InnerSidenav;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.componentRef.destroy();
                    }
                },
                    error => {
                        this.dataService.navigateToInnerSideNavTop();
                        const errorMessage = `Error while trying to update the manufacturer details`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.InnerSidenav;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.componentRef.destroy();
                    });
            }
        }
        else {
            this.dataService.navigateToInnerSideNavTop();
            const errorMessage = `A Manufacturer cannot have duplicate Deliverable instance ID's`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.InnerSidenav;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.componentRef.destroy();
        }
    }

    checkMfrExists = (partySiteNumber: string): boolean => {
        // API call headers
        const request: CheckMfrExistsRequest = {
            fileVolRecId: this.model.fileVolRecId,
            partySiteNumber: partySiteNumber.split(': ')[1], // Temp solution to send correct partysite value
        };
        let mfrExists = true;
        this.mfrService
            .checkMfrExists(request)
            .subscribe((result) => (mfrExists = result));
        return mfrExists;
    };
}

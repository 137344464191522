<div *ngIf="!isTableView">
    <div class="search-result-card flex flex-grow py-2 mt-2">
        <div class="flex flex-col flex-grow">
            <div class="flex flex-row items-center px-2">
                <div class="flex my-2 flex-wrap">
                    <ng-container *ngIf="model.icNumber">
                        <span class="font-bold">IC Number:&nbsp;</span>
                        <span>{{ model.icNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ic">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">IC Name:&nbsp;</span>
                        <span>{{ model.ic }}</span>
                    </ng-container>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                <ng-container>
                    <button (click)="isDetailsExpanded = !isDetailsExpanded" color="primary" class="text-black">
                        <fa-icon *ngIf="isDetailsExpanded" icon="caret-down" class="pr-2 text-xl"></fa-icon>
                        <fa-icon *ngIf="!isDetailsExpanded" icon="caret-right" class="pr-2 text-xl"></fa-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="model.city">
                    <span class="font-bold">City:&nbsp;</span>
                    <span>{{ model.city }}</span>
                </ng-container>
                <ng-container *ngIf="model.state">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold">State:&nbsp;</span>
                    <span>{{ model.state }}</span>
                </ng-container>
                <ng-container *ngIf="model.postalCode">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold">Postal Code:&nbsp;</span>
                    <span>{{ model.postalCode }}</span>
                </ng-container>
                <ng-container *ngIf="model.country">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold">Country:&nbsp;</span>
                    <span>{{model.country}}</span>
                </ng-container>
            </div>
            <mat-divider *ngIf="isDetailsExpanded"></mat-divider>
            <div class="flex flex-row my-1 file-details-body p-2 flex-wrap"
                 *ngIf="isDetailsExpanded; let showDivider = false;">
                <ng-container *ngIf="model.icInformation">
                    <span class="field-divider mx-2" *ngIf="showDivider; let showDivider = true">&nbsp;</span>
                    <div class="font-bold">IC Info:&nbsp;</div>
                    <span class="col-md-5">{{ model.icInformation }}</span>
                </ng-container>
                <ng-container *ngIf="model.lcInformation">
                    <span class="field-divider mx-2" *ngIf="showDivider; let showDivider = true;">&nbsp;</span>
                    <div class="font-bold">LC Info:&nbsp;</div>
                    <span class="col-md-5">{{ model.lcInformation }}</span>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="mat-elevation-z2 flex flex-grow mt-2" *ngIf="isTableView">
    <table mat-table
           [dataSource]="tableDataSource" multiTemplateDataRows matSort 
            (matSortChange)="sortData($event)"
            [matSortDisableClear]="false" [matSortActive]="searchRequest.sortBy"
            [matSortDirection]="searchRequest.sortDirection">
        <ng-container matColumnDef="icNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> IC Number </th>
            <td mat-cell *matCellDef="let element">
                {{element.icNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="icName">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> IC Name </th>
            <td mat-cell *matCellDef="let element">
                {{element.ic}}
            </td>
        </ng-container>

        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> City </th>
            <td mat-cell *matCellDef="let element">
                {{element.city}}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> State </th>
            <td mat-cell *matCellDef="let element">
                {{element.state}}
            </td>
        </ng-container>

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Country </th>
            <td mat-cell *matCellDef="let element">
                {{element.country}}
            </td>
        </ng-container>

        <ng-container matColumnDef="postalCode">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Postal Code </th>
            <td mat-cell *matCellDef="let element">
                {{element.postalCode}}
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="table-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="pl-4">
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container *ngIf="element.icInformation">
                                <span class="field-divider mx-2" *ngIf="showDivider; let showDivider = true">&nbsp;</span>
                                <div class="font-bold">IC Info:&nbsp;</div>
                                <span class="col-md-5">{{ element.icInformation }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.lcInformation">
                                <span class="field-divider mx-2" *ngIf="showDivider; let showDivider = true;">&nbsp;</span>
                                <div class="font-bold">LC Info:&nbsp;</div>
                                <span class="col-md-5">{{ element.lcInformation }}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
            class="table-element-row"
            [class.table-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
</div>

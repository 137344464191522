import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductCcnService } from '../../product-ccn-association/product-ccn.service';
import { LoaderComponent } from '../loader/loader.component';
import * as FileSaver from 'file-saver';
import { DataService } from '../../core/services/data.service';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent implements OnInit {

    @ViewChild('excelInputFile') excelInputFile;
    @ViewChild('uploadContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    title = "Upload Product CCN Excel Sheet";
    errorMessage = "";
    constructor(
        public activeModal: NgbActiveModal,
        public productService: ProductCcnService,
        private resolver: ComponentFactoryResolver,
        private dataService: DataService,
        private toastService: ToastNotificationService
    ) { }
    ngOnInit(): void {
    }
    uploadFile() {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const errorFileName = `ProductCCN-${this.dataService.customDateFormat(null)}-Error.xlsx`;
        let formData = new FormData();
        if (this.excelInputFile.nativeElement.files.length != 0) {
            const fileName = this.excelInputFile.nativeElement.files[0].name;
            const isXLXSFile = fileName.substring(fileName.length - 4, fileName.length) == "xlsx" ? true : false;
            const isXLSFile = fileName.substring(fileName.length - 3, fileName.length) == "xls" ? true : false;
            if (isXLXSFile || isXLSFile) {
                this.errorMessage = "";
                formData.append('upload', this.excelInputFile.nativeElement.files[0]);
                this.productService.uploadExcelFile(formData).subscribe(async (result: Blob) => {
                    const isJsonBlob = (result) => result instanceof Blob && result.type === "application/json";

                    if (isJsonBlob(result)) {
                        const responseData = JSON.parse(await (result).text());
                        componentRef.destroy();
                        if (responseData.didError) {
                            this.errorMessage = responseData.errorMessage;
                        }
                        else {
                            const successMessage = responseData.message;
                            const type = ToastNotificationType.success;
                            const placement = ToastNotificationPlacement.Body;
                            const message = new ToastNotificationMessage(successMessage, type, placement);
                            this.toastService.sendToastNotificationMessage(message);
                            this.activeModal.dismiss();
                            this.productService.onRefreshSearchResult();
                        }
                    }
                    else
                    {
                        if (result.size && result.size > 0) {
                            componentRef.destroy();
                            const errorMessage = "Please check the downloaded error file for validation errors";
                            const type = ToastNotificationType.error;
                            const placement = ToastNotificationPlacement.Body;
                            const message = new ToastNotificationMessage(errorMessage, type, placement);
                            this.toastService.sendToastNotificationMessage(message);
                            this.activeModal.dismiss();
                            FileSaver.saveAs(result, errorFileName);
                        }
                    }
                })
            } else {
                this.errorMessage = "Please upload a file with xlsx or xls file extension";
                componentRef.destroy();
            }
        }
        else {
            this.errorMessage = "Please select the file to be uploaded";
            componentRef.destroy();
        }
    }
    onCancel() {
        this.activeModal.dismiss();
    }

}

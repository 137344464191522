import { AdalConfigModel } from '../model/adal-config.model';

export const adalConfig: AdalConfigModel = {
    clientId: '',
    tenant: '',
    cacheLocation: 'localStorage',
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: '',
    endpoints: {
        '<web-api-url-goes-here>': '<azure-ad-client-id-to-the-web-api-goes-here>'
    },
    redirectUri: '',
    loadFrameTimeout: ''
};

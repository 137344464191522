import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ClientSettingsService } from '../services/client-settings.service';

@Injectable()
export class InsertAuthTokenInterceptor implements HttpInterceptor {
  requestCounts = 0;
  constructor(private router: Router, private adal: MsAdalAngular6Service, private clientSettingsService: ClientSettingsService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.requestCounts++;
    // get api url from adal config
    const resource = this.adal.GetResourceForEndpoint(request.url);
    if (!resource) {
      return next.handle(request);
    }

    if (!this.adal.isAuthenticated) {
    }

    // This internally uses a "substring of the req.url".  DO NOT pass in the resource as it will default to the clientId
    return this.adal.acquireToken(request.url).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error) {
          console.error(`errors attempted to adal.acquireToken${request.url} because status: ${JSON.stringify(error)}`);
        } else {
          console.error(`errors attempted to adal.acquireToken${request.url}`);
        }
        if (this.adal.isAuthenticated) {
          // User was no longer authenticated, we need to re-route them to home in order to force the authorization flow.;
          this.router.navigate(['']);
          return throwError(EMPTY);
        }
        return throwError(error);
      }),
        mergeMap((token: string) => {
            let headers = {
                setHeaders: {
                    'Authorization': `Bearer ${token}`,
                    'Ocp-Apim-Subscription-Key': `${this.clientSettingsService.clientSettings.ocpApimSubscriptionKey}`,
                    'Ocp-Apim-Trace': `${this.clientSettingsService.clientSettings.ocpApimTrace}`,
                    'Content-Type': 'application/json'
                }
            }
            if (request.url.includes("api/ProductCcnAssociation/importProductData")) {
                delete headers.setHeaders["Content-Type"];
            }
            const authorizedRequest = request.clone(headers);
        /*const authorizedRequest = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`,
            'Ocp-Apim-Subscription-Key': `${this.clientSettingsService.clientSettings.ocpApimSubscriptionKey}`,
            'Ocp-Apim-Trace': `${this.clientSettingsService.clientSettings.ocpApimTrace}`,
            'Content-Type': 'application/json'
          }
        });*/
        return next.handle(authorizedRequest).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // console.log(`event--->>> status ${event.status}` );
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              // If we get a Token Expiration Failure, we will tell the adal library to renew the token and then we
              // will redirect back to the default route, which should require the user to re - authenticate
              // console.log(`Unauthorized error status:  ${error.status}, message:  ${error.message}`)
              this.adal.RenewToken(request.url);
              this.router.navigate(['']);
              return throwError(EMPTY);
            } else {
              // console.log(`error status:  ${error.status}, message:  ${error.message}`)
              return throwError(error);
            }
          })
        );
      }));
  }
}

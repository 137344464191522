import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UtilityModule } from '../utility';
import { AppRoutingModule } from '../app-routing.module';
import { JoyrideModule } from 'ngx-joyride';
import { CustomerSearchResultCardComponent } from './customer-search-result-card/customer-search-result-card.component';

@NgModule({
    declarations: [
        CustomerSearchComponent,
        CustomerSearchResultCardComponent
    ],
    imports: [
        JoyrideModule.forRoot(),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FontAwesomeModule,
        UtilityModule,
        AppRoutingModule,
    ],
    exports: [
        CustomerSearchComponent,
        CustomerSearchResultCardComponent
    ],
})
export class CustomerDetailsModule {}

<nav aria-label="breadcrumb" class="pt-4">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="['/']">IFVS</a></li>
    <li class="breadcrumb-item" [ngClass]="{'active':activeTitleLevel==1}" aria-current="page"
        *ngIf="levelOneTitle!=''">
        <a routerLink="{{levelOneURL}}" *ngIf="levelOneURL!=''">{{ levelOneTitle }}</a>
        <p *ngIf="levelOneURL==''">{{ levelOneTitle }}</p>
    </li>
    <li class="breadcrumb-item" [ngClass]="{'active':activeTitleLevel==2}" aria-current="page"
      *ngIf="levelTwoTitle!=''">
      <a routerLink="{{levelTwoURL}}" *ngIf="levelTwoURL!=''">{{ levelTwoTitle }}</a>
      <p *ngIf="levelTwoURL==''">{{ levelTwoTitle }}</p>
    </li>
    <li class="breadcrumb-item" [ngClass]="{'active':activeTitleLevel==3}" aria-current="page"
        *ngIf="levelThreeTitle!=''">
        <a routerLink="{{levelThreeURL}}" *ngIf="levelThreeURL!=''">{{ levelThreeTitle }}</a>
        <p *ngIf="levelThreeURL==''">{{ levelThreeTitle }}</p>
    </li>
  </ol>
</nav>
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientSettingsService } from '../../core/services/client-settings.service';

@Component({
    selector: 'app-informationwindow',
    templateUrl: './informationwindow.component.html'
})

export class InformationWindowComponent implements OnInit {
    welcomeBannerReleaseDate: Date = new Date();

    constructor(
        public activeModal: NgbActiveModal,
        private clientSetting: ClientSettingsService) {
        if (this.isDate(new Date(this.clientSetting.clientSettings.welcomeBannerReleaseDate))) {
            this.welcomeBannerReleaseDate = new Date(this.clientSetting.clientSettings.welcomeBannerReleaseDate);
        }
        else { this.welcomeBannerReleaseDate = new Date(); }
    }

    ngOnInit(): void { }

    onConfirm() {        
        this.activeModal.close({ Action: 'Confirm' });
    }

    onSkip() {
        this.activeModal.close({ Action: 'Skip' });
    }

    isDate = (date: Date | string): boolean => {
        return isFinite(+(date instanceof Date ? date : new Date(date)));
    }
}

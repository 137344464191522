/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientSettingsService } from '../core/services/client-settings.service';
import {
    ListeeSearchRequest, ListeeSearchResultModel, WithdrawListeeRequest, ListeeSummary,
    ListeeDeleteModel, ReactivateListeeOutput, ReactivateListeeModel, ListeeTypeDetailsResultModel
} from './listee.model';
import { CreateListeeModel } from './create-listee/create-listee.model';

@Injectable({ providedIn: 'root' })
export class ListeeService {
    private readonly refreshSearchResult = new Subject<any>();
    public refreshSearchResult$ = this.refreshSearchResult.asObservable();

    private webApiEndPoint: string;
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    private httpOptionsString = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }), // eslint-disable-next-line @typescript-eslint/naming-convention
        responseType: 'blob' as 'json'
    };
    constructor(
        private clientSetting: ClientSettingsService,
        private http: HttpClient) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/ListeeAssociation/`;
    }

    onRefreshSearchResult() {
        this.refreshSearchResult.next(); // This will trigger search API to fetch results again
    }

    getSearchResult(searchRequest: ListeeSearchRequest): Observable<ListeeSearchResultModel> {
        const searchResult = this.http.post<ListeeSearchResultModel>(`${this.webApiEndPoint}search`, searchRequest, this.httpOptions)
            .pipe();
        return searchResult;
    }

    withdrawListee(withdrawListeeRequest: WithdrawListeeRequest) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}withdraw`, withdrawListeeRequest, this.httpOptions).pipe();
        return result;
    }

    deleteListee(deleteRequest: ListeeDeleteModel) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}delete`, deleteRequest, this.httpOptions).pipe();
        return result;
    }

    getListeeDetail(listeeRecId: number): Observable<ListeeSummary> {
        const listeeDetail = this.http.get<ListeeSummary>(`${this.webApiEndPoint}${listeeRecId}`, this.httpOptions).pipe();
        // catchError(this.dataService.handleError('SearchFile', searchRequest))
        return listeeDetail;
    }

    addListee = (request: CreateListeeModel): Observable<boolean> => {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}create`, request, this.httpOptions).pipe();
        return result;
    }

    updateListeeDetail = (request: CreateListeeModel): Observable<boolean> => {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}update`, request, this.httpOptions).pipe();
        return result;
    }

    exportToExcel = (searchRequest: ListeeSearchRequest) => {
        return this.http.post(`${this.webApiEndPoint}exportToExcel`, searchRequest, this.httpOptionsString).pipe();
    }

    getFileListeeTypeDetails(fileVolRecId: number): Observable<ListeeTypeDetailsResultModel> {
        const searchResult = this.http.post<ListeeTypeDetailsResultModel>(`${this.webApiEndPoint}getFileListeeTypeDetails`, fileVolRecId, this.httpOptions).pipe();
        return searchResult;
    }

    checkIfListeeExist = (fileVolRecId: number): Observable<boolean> => {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}checkIfListeeExist`, fileVolRecId, this.httpOptions).pipe();
        return result;
    }

    reactivateListee(reactivateListee: ReactivateListeeModel): Observable<ReactivateListeeOutput> {
        const output = this.http.put<ReactivateListeeOutput>(`${this.webApiEndPoint}reactivate`, reactivateListee, this.httpOptions).pipe();
        return output;
    }
}

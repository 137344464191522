<ng-template #ProductSearchContainer>
</ng-template>
<div *ngIf="!isTableView">
    <div class="search-result-card flex flex-grow py-2 mt-2">
        <div class="flex flex-col flex-grow">
            <div class="flex flex-row items-center px-2">
                <div class="flex my-2 flex-wrap">
                    <ng-container *ngIf="model.ccn">
                        <span class="font-bold">CCN:&nbsp;</span>
                        <span>{{ model.ccn }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.productType">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Product Type:&nbsp;</span>
                        <span>{{ model.productType }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.deliverable">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Deliverable:&nbsp;</span>
                        <span>{{ model.deliverable }}</span>
                    </ng-container>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="flex flex-row my-1 product-details-body p-2 flex-wrap">
                <ng-container *ngIf="model.typeOfService">
                    <span class="font-bold">Type of Service:&nbsp;</span>
                    <span>{{ model.typeOfService }}</span>
                </ng-container>
                <ng-container *ngIf="model.invoiceText">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold">Invoice Text:&nbsp;</span>
                    <span>{{ model.invoiceText }}</span>
                </ng-container>
                <ng-container *ngIf="model.deliverableType">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold">Deliverable Type:&nbsp;</span>
                    <span>{{ model.deliverableType }}</span>
                </ng-container>
                <ng-container *ngIf="model.fusSampleLines">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold">Fus Sample Lines:&nbsp;</span>
                    <span>{{ model.fusSampleLines }}</span>
                </ng-container>
            </div>
            <div class="flex flex-row product-details-body pl-2 flex-wrap">
                <ng-container *ngIf="model.isML">
                    <span class="font-bold">ML:&nbsp;</span>
                    <span>{{ model.isML }}</span>
                </ng-container>
                <ng-container *ngIf="model.industry">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold">Industry:&nbsp;</span>
                    <span>{{ model.industry }}</span>
                </ng-container>
                <ng-container *ngIf="model.subIndustry">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold">Sub Industry:&nbsp;</span>
                    <span>{{ model.subIndustry }}</span>
                </ng-container>
            </div>
        </div>
        <div class="flex flex-col flex-grow-0 justify-start" *ngIf="isAdmin || isEdit">
            <div class="flex flex-row items-center">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="additional-menu-item"
                            (click)="editProductDetail(model)" *ngIf="isAdmin || isEdit">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item class="additional-menu-item" (click)="deleteProduct(model.productCcnRecId)"
                            *ngIf="isAdmin">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>

<div class="mat-elevation-z2 flex flex-grow mt-2" *ngIf="isTableView">
    <table mat-table [dataSource]="tableDataSource" multiTemplateDataRows matSort 
            (matSortChange)="sortData($event)"
            [matSortDisableClear]="false" [matSortActive]="searchRequest.sortBy"
            [matSortDirection]="searchRequest.sortDirection">
        <ng-container matColumnDef="ccn">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> CCN </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.ccn}}
            </td>
        </ng-container>
        <ng-container matColumnDef="productType">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Product Type </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.productType}}
            </td>
        </ng-container>
        <ng-container matColumnDef="deliverable">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Deliverable </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.deliverable}}
            </td>
        </ng-container>
        <ng-container matColumnDef="deliverableType">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Deliverable Type </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.deliverableType}}
            </td>
        </ng-container>
        <ng-container matColumnDef="typeOfService">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Type of Service </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.typeOfService}}
            </td>
        </ng-container>
        <ng-container matColumnDef="fusSampleLines">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Fus Sample Lines </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.fusSampleLines}}
            </td>
        </ng-container>
        <ng-container matColumnDef="industry">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Industry </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.industry}}
            </td>
        </ng-container>
        <ng-container matColumnDef="subIndustry">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Sub Industry </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.subIndustry}}
            </td>
        </ng-container>
        <ng-container matColumnDef="isML">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> ML </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.isML}}
            </td>
        </ng-container>

        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef class="table-header"> Options </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                        *ngIf="isAdmin || isEdit">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="additional-menu-item"
                            (click)="editProductDetail(element)" *ngIf="isAdmin || isEdit">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item class="additional-menu-item" (click)="deleteProduct(element.productCcnRecId)"
                            *ngIf="isAdmin">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="table-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="pl-4">
                        <div class="flex flex-row my-1 p-2 flex-wrap">
                            <ng-container *ngIf="element.invoiceText">
                                <div class="font-bold">Invoice Text: &nbsp;</div>
                                <span>{{ element.invoiceText }}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
            class="table-element-row"
            [class.table-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
</div>

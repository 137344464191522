import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from '../../constants';
import { DataService } from '../../core/services/data.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ListeeSearchRequest, ListeeSearchResultModel, WithdrawOrDeleteEventModel } from '../listee.model';
import { ListeeService } from '../listee.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportConfirmDialogComponent } from '../../shared/export-confirm-dialog/export-confirm-dialog.component';
import * as FileSaver from 'file-saver';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-listee-search',
    templateUrl: './listee-search.component.html',
    styleUrls: ['./listee-search.component.scss']
})
export class ListeeSearchComponent implements OnInit, AfterViewInit {
    @ViewChild('listeeContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public pageName = '';
    levelOneURL = '';
    activeLevel = 0;

    loading = false;
    isSearch = false;
    isTableView = false;
    noSearchResults = false;
    hasSearchActionOccurred = false;
    searchResultCount = 0;

    searchResult: ListeeSearchResultModel;
    refreshSearchResultSubscription: Subscription;
    prevSearchRequest: ListeeSearchRequest = {} as ListeeSearchRequest;
    sortOptions = Constants.LISTEE_SEARCH_SORT_OPTIONS;
    statusOptions = Constants.FILTER_BY_LISTEE_STATUS;
    listeeType = Constants.LISTEE_ASSOCIATION_TYPE;
    currentSort = { active: '', direction: '' };
    listeeSearchModel: ListeeSearchRequest = {
        fileNumber: '',
        ownerAccountNumber: '',
        volume: '',
        listeeType: Constants.ALL,
        listeeAccountNumber: '',
        listeeFileNumber: '',
        partySiteNumber: '',
        subscriberNumber: '',
        fileRecId: 0,
        fileVolRecId: 0,
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0],
        filterBy: Constants.ALL,
    };

    constructor(
        private dataService: DataService,
        private loadingSpinnerService: LoadingSpinnerService,
        private toastService: ToastNotificationService,
        private searchService: ListeeService,
        private resolver: ComponentFactoryResolver,
        private modalService: NgbModal,
        private previousRouteService: PreviousRouteService
    ) {
        this.refreshSearchResultSubscription = this.searchService.refreshSearchResult$.subscribe(res => {
            // Fetch the data again
            if (this.dataService.isEmpty(this.listeeSearchModel)) {
                this.getSearchResult(this.listeeSearchModel);
            }
        });
    }

    ngOnInit(): void {
        this.activeLevel = 1;
        this.pageName = `Listee Association`;
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        const obj = this.dataService.getOption(Constants.LISTEE_SEARCH_REQUEST_OBJECT)
        if (this.dataService.getOption(Constants.LISTEE_SEARCH_REQUEST_OBJECT) && Object.keys(obj).length > 0) {
            this.listeeSearchModel = this.dataService.getOption(Constants.LISTEE_SEARCH_REQUEST_OBJECT);
            if (this.dataService.isEmpty(this.listeeSearchModel)) { this.onSearch(this.listeeSearchModel); }
        }
    }

    ngOnDestroy(): void {
        if (this.refreshSearchResultSubscription) {
            this.refreshSearchResultSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    onViewChange = (sortingEvent: Paginator) => {
        this.isTableView = sortingEvent.isTableView;
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.listeeSearchModel);
        if (result) { this.listeeSearchModel.pageNumber = sortingEvent.page; }
        else { this.listeeSearchModel.pageNumber = 1; }
        this.listeeSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.listeeSearchModel);
    }

    onSortByChange = (sortingEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.listeeSearchModel);
        if (!result) { this.listeeSearchModel.pageNumber = 1; }
        this.listeeSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.listeeSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];
        this.getSearchResult(this.listeeSearchModel);
    }

    /* To sort the values based on the sort option chosen in the table headers - @param sortingEvent */
    onSortingChange = (sortingEvent: ListeeSearchRequest) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.listeeSearchModel);
        if (!result) { this.listeeSearchModel.pageNumber = 1; }
        this.listeeSearchModel.sortBy = sortingEvent.sortBy;
        this.listeeSearchModel.sortDirection = sortingEvent.sortDirection;
        this.getSearchResult(this.listeeSearchModel);
    }

    onSearch = (formValues) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.listeeSearchModel);
        if (!this.hasSearchActionOccurred) {
            this.listeeSearchModel.pageNumber = 1;
            this.listeeSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
            this.listeeSearchModel.sortDirection = this.sortOptions[0].value.split('-')[1];
            this.listeeSearchModel.sortBy = this.sortOptions[0].value.split('-')[0];
        }
        else if (this.hasSearchActionOccurred && !result) {
            this.listeeSearchModel.pageNumber = 1;
        }
        this.getSearchResult(formValues);
    }

    onWithdrawOrDelete = (withdrawOrDeleteEvent: WithdrawOrDeleteEventModel) => {
        document.querySelector('mat-sidenav-content').scrollTop = 0;
        if (withdrawOrDeleteEvent.value) {
            const successMessage = `The Listee association was ${withdrawOrDeleteEvent.action.toLowerCase()} successfully`;
            const type = ToastNotificationType.success;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(successMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.getSearchResult(this.listeeSearchModel);
        }
        else {
            const withdrawOrDeleteMsg = withdrawOrDeleteEvent.action === Constants.WITHDRAWN ? 'withdraw' : 'delete';
            const errorMessage = `Error while trying to ${withdrawOrDeleteMsg} the listee association. Please try again later`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            this.entry.clear();
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.prevSearchRequest = Object.assign({}, this.listeeSearchModel);
            this.dataService.setOption(Constants.LISTEE_SEARCH_REQUEST_OBJECT, this.listeeSearchModel);
            this.searchService.getSearchResult(this.listeeSearchModel)
                .subscribe((searchResult) => {
                    componentRef.destroy();
                    this.searchResult = searchResult;
                    this.searchResultCount = searchResult.paging.totalRecords;
                    this.currentSort = {
                        active: this.listeeSearchModel.sortBy,
                        direction: this.listeeSearchModel.sortDirection
                    };

                    if (this.searchResultCount === 0) {
                        const errorMessage = `No Data found for the specified search criteria`;
                        const type = ToastNotificationType.infoBlue;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = true;
                        this.isSearch = false;
                        return;
                    }
                    else {
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = false;
                        this.isSearch = true;
                    }
                }, (error) => {
                    componentRef.destroy();
                    const errorMessage = `Error while retrieving the data`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.searchResultCount = 0;
                    this.isSearch = false;
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.isTableView = false;
                });
        }
        else {
            const errorMessage = `Please enter the search criteria in the form below`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.searchResultCount = 0;
            this.isSearch = false;
            this.hasSearchActionOccurred = false;
            this.noSearchResults = false;
            this.isTableView = false;
        }
    }

    resetForm(listeeAssociationSearchForm: NgForm): void {
        // This will clear the previous search request data
        listeeAssociationSearchForm.resetForm({
            fileNumber: '',
            volume: '',
            listeeType: Constants.ALL,
            listeeAccountNumber: '',
            listeeFileNumber: '',
            partySiteNumber: '',
            subscriberNumber: '',
            fileRecId: 0,
            fileVolRecId: 0,
            exactSearch: true,
            filterBy: Constants.ALL
        });

        this.isSearch = false;
        this.isTableView = false;
        this.searchResultCount = 0;
        this.hasSearchActionOccurred = false;
        this.searchResult = {} as ListeeSearchResultModel;
        this.prevSearchRequest = {} as ListeeSearchRequest;
        this.dataService.setOption(Constants.LISTEE_SEARCH_REQUEST_OBJECT, {});
    }

    exportToExcel() {
        this.loading = true;
        const fileName = `ListeeAssociation-${this.dataService.customDateFormat(null)}.xlsx`;
        const dialogRef = this.modalService.open(ExportConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.result.then(result => {
            if (result.action === 'yes') {
                if (result.selectedValue === 'true') {
                    this.listeeSearchModel.pageNumber = 1;
                    this.listeeSearchModel.pageSize = this.searchResultCount;
                }
                this.searchService.exportToExcel(this.listeeSearchModel).subscribe((object: Blob) => {
                    if (object.size && object.size > 0) { FileSaver.saveAs(object, fileName); }
                    else {
                        const errorMessage = `Error while retrieving to export the data to excel`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                    this.loading = false;
                });
            }
        }).catch(res => {
            if (res.action === 'no') { this.loading = false; }
        });
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MfrDetailComponent } from './mfr-detail/mfr-detail.component';
import { MfrSearchComponent } from './mfr-search/mfr-search.component';
import { MfrSearchResultCardComponent } from './mfr-search-result-card/mfr-search-result-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { AppRoutingModule } from '../app-routing.module';
import { UtilityModule } from '../utility';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreateNewMfrComponent } from './create-new-mfr/create-new-mfr.component';
import { MfrExistsDirective } from './create-new-mfr/validation/mfr-exists.directive';

@NgModule({
    declarations: [
        MfrDetailComponent,
        MfrSearchResultCardComponent,
        MfrSearchComponent,
        CreateNewMfrComponent,
        MfrExistsDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        AppRoutingModule,
        FontAwesomeModule,
        UtilityModule,
    ],
    exports: [],
})
export class MfrDetailModule {}

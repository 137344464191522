<div>
    <div class="bg-white px-20">
        <app-breadcrumb [levelOneTitle]="levelOneTitle" [levelTwoTitle]="levelTwoTitle"
                        [levelThreeTitle]="levelThreeTitle" [activeTitleLevel]="activeLevel" [levelOneURL]="levelOneURL"
                        [levelTwoURL]="levelTwoURL">
        </app-breadcrumb>
        <div class="w-103">
            <mat-card class="px-0">
                <mat-card-content>
                    <div>
                        <ng-container *ngIf="modelData.fileNumber">
                            <span class="font-bold">File Number:&nbsp;</span>
                            <span class="font-normal">{{ modelData.fileNumber }}</span>
                            <span class="field-divider mx-2">&nbsp;</span>
                        </ng-container>
                        <ng-container *ngIf="modelData.ownerAccount">
                            <span class="font-bold">Account Number:&nbsp;</span>
                            <span class="font-normal">{{ modelData.ownerAccount }}</span>
                            <span class="field-divider mx-2">&nbsp;</span>
                        </ng-container>
                        <ng-container *ngIf="modelData.ownerCompanyName">
                            <span class="font-bold">Company Name:&nbsp;</span>
                            <span class="font-normal">{{ modelData.ownerCompanyName }}</span>
                        </ng-container>
                        <div class="float-right">
                            <div class="items-center">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="z-10000">
                                    <button mat-menu-item class="additional-menu-item" (click)="generateAuthPage()"
                                            [disabled]="!(isAuthPageEnabled && !(!hasSearchActionOccurred || noSearchResults))">
                                        <mat-icon>assignment</mat-icon>
                                        <span>Generate Authpage</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <div class="w-full mt-2">
                <hr />
                <app-paginator (sortingChange)='onSortByChange($event)' (viewChange)='onViewChange($event)' (paginationChange)='onSortPaginationChange($event)'
                               [isTableView]="isTableView" [length]="searchResultCount" [sortByOptions]="sortOptions" [showSortBy]="true"
                               [filterByOptions]="filterOptions" [showFilterBy]="true" [showPageSize]="true" [pageIndex]="0">
                </app-paginator>
                <hr />
            </div>
        </div>
    </div>
    <ng-template #listeeContainer>
    </ng-template>
    <div *ngIf="noSearchResults && hasSearchActionOccurred">
        <div class="ml-20">
            <div class="flex flex-col justify-center max-w-full mx-auto my-3">
                <div class="flex"><img src="../../../assets/images/noResults.png" class="Bitmap mx-auto" /></div>
                <div class="flex"><div class="feedback-header mx-auto title-no-data">No Listee Details Found</div></div>
            </div>
        </div>
    </div>
    <div *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
        <div class="search-details-container">
            <div *ngIf="searchResult && searchResult.results && !isTableView">
                <app-listee-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                               (withdrawOrDeleteAction)="onWithdrawOrDelete($event)">
                </app-listee-search-result-card>
            </div>
            <div *ngIf="searchResult && searchResult.results && isTableView">
                <app-listee-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                               (withdrawOrDeleteAction)="onWithdrawOrDelete($event)" (sortingChangeAction)="onSortingChange($event)">
                </app-listee-search-result-card>
            </div>
        </div>
    </div>
</div>
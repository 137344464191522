import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientSettingsService } from '../core/services/client-settings.service';
import {
    ManufacturerSearchRequest,
    ManufacturerSearchResultModel,
    WithdrawMFRRequest,
    ManufacturerSummary,
    DeleteMfrRequest,
    ReactivateMfr,
    ReactivateMfrOutput
} from './mfr-detail-search.model';
import { CreateEditMfrModel, CheckMfrExistsRequest } from './create-new-mfr/create-mfr.model';

@Injectable({
    providedIn: 'root'
})
export class MfrDetailService {

    private readonly refreshSearchResult = new Subject<any>();
    public refreshSearchResult$ = this.refreshSearchResult.asObservable();

    private webApiEndPoint: string;
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    private httpOptionsString = {
        headers: new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
        }),
        responseType: 'blob' as 'json'
    };
    constructor(
        private clientSetting: ClientSettingsService,
        private http: HttpClient) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/MfrAssociation/`;
    }

    onRefreshSearchResult() {
        this.refreshSearchResult.next(); // This will trigger search API to fetch results again
    }

    getSearchResult(searchRequest: ManufacturerSearchRequest): Observable<ManufacturerSearchResultModel> {
        const searchResult = this.http.post<ManufacturerSearchResultModel>(`${this.webApiEndPoint}search`, searchRequest, this.httpOptions)
            .pipe();
        return searchResult;
    }

    withdrawMfr(withdrawMfrRequest: WithdrawMFRRequest) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}withdraw`, withdrawMfrRequest, this.httpOptions).pipe();
        return result;
    }
    
    deleteMfr(mfrRecId: number, userId: string) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}delete/${mfrRecId}`, JSON.stringify(userId), this.httpOptions).pipe();
        return result;
    }

    getMfrDetail(mfrRecId: number): Observable<ManufacturerSummary> {
        const mfrDetail = this.http.get<ManufacturerSummary>(`${this.webApiEndPoint}${mfrRecId}`, this.httpOptions).pipe(
            // catchError(this.dataService.handleError('SearchFile', searchRequest))
        );
        return mfrDetail;
    }

    addNewMfr(request: CreateEditMfrModel) {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}create`, request, this.httpOptions).pipe();
        return result;
    }

    updateMfrDetail(editRequest: CreateEditMfrModel) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}update`, editRequest, this.httpOptions).pipe();
        return result;
    }

    checkMfrExists = (request: CheckMfrExistsRequest): Observable<boolean> => {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}check`,request,this.httpOptions).pipe();
        return result;
    }

    checkMfrIsWithdrawn = (request: CheckMfrExistsRequest): Observable<boolean> => {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}isMfrWithdrawn`, request, this.httpOptions).pipe();
        return result;
    }

    getActiveMfr = (fileVolRecId: number): Observable<boolean> => {
        const isActiveMfr = this.http.post<boolean>(`${this.webApiEndPoint}getActiveMfr`, fileVolRecId, this.httpOptions).pipe();
        return isActiveMfr;
    }

    exportToExcel = (searchRequest: ManufacturerSearchRequest) => {
        return this.http.post(`${this.webApiEndPoint}exportToExcel`, searchRequest, this.httpOptionsString).pipe();
    }

    withdrawMfrFromAllVolume(withdrawMfrRequest: WithdrawMFRRequest) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}withdraw/all`, withdrawMfrRequest, this.httpOptions).pipe();
        return result;
    }

    deleteMfrFromAllVolume(deleteRequest: DeleteMfrRequest) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}delete/all`, deleteRequest , this.httpOptions).pipe();
        return result;
    }

    reactivateMfr = (reactivateMfrInput: ReactivateMfr): Observable<ReactivateMfrOutput> => {
        return this.http
            .put<ReactivateMfrOutput>(
                `${this.webApiEndPoint}reactivate`,
                reactivateMfrInput,
                this.httpOptions
            )
            .pipe();
    }

    reactivateMfrOfAllVolume(reactivateMfrInput: ReactivateMfr): Observable<ReactivateMfrOutput> {
        const result = this.http.put<ReactivateMfrOutput>(`${this.webApiEndPoint}reactivate/all`, reactivateMfrInput, this.httpOptions).pipe();
        return result;
    }
}

import {
    Component, OnInit, Input, Output, EventEmitter,
    ViewContainerRef,
    ViewChild,
    ComponentFactoryResolver} from '@angular/core';
import { state, style, trigger } from '@angular/animations';
import { ProductSummary, DeleteEventModel, ProductCcnSearchRequest } from '../product-ccn.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../core/services/data.service';
import { CreateEditProductCcnModel } from '../create-product-ccn/create-product-ccn.model';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ProductCcnService } from '../product-ccn.service';
import { Constants } from '../../constants';
import { MatTableDataSource } from "@angular/material/table";
import { LoaderComponent } from '../../shared/loader/loader.component';
import { MatSort } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-product-ccn-search-result-card',
    templateUrl: './product-ccn-search-result-card.component.html',
    styleUrls: ['./product-ccn-search-result-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
        ]),
    ]
})

export class ProductCcnSearchResultCardComponent implements OnInit {
    @ViewChild('ProductSearchContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    @ViewChild(MatSort) sort: MatSort;

    private tableDataSource;

    @Input() model: ProductSummary;
    @Input() currentSort: Sort;
    @Output() deleteAction: EventEmitter<DeleteEventModel> = new EventEmitter();
    @Output() sortingChangeAction: EventEmitter<ProductCcnSearchRequest> = new EventEmitter();
    @Input() set tableData(value: ProductSummary[]) {
        this.tableDataSource = new MatTableDataSource(value);
        this.tableDataSource.sort = this.sort;
    }
    @Input() isTableView: boolean;
    columnsToDisplay = Constants.PRODUCT_RESULT_TABLE_COLUMNS;
    expandedElement: ProductSummary | null;
    isAdmin: boolean;
    isEdit: boolean;

    sortBy = Constants.SortBy;
    searchRequest: ProductCcnSearchRequest = {
        productName: '',
        productCcn: '',
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: "asc",
        sortBy: "ccn",
        filterBy: Constants.ALL,
    };
    constructor(
        private sideNavService: SideNavService,
        private dataService: DataService,
        private modalService: NgbModal,
        private productService: ProductCcnService,
        private resolver: ComponentFactoryResolver
    ) { }

    sortData(sort: Sort) {
        const SortByColumn = sort.active; // This will give the name of the column currently being sorted
        if (this.searchRequest.sortBy === SortByColumn) {
            switch (this.searchRequest.sortDirection) {
                case this.sortBy.ASC:
                    this.searchRequest.sortDirection = this.sortBy.DESC;
                    break;
                case this.sortBy.DESC:
                    this.searchRequest.sortDirection = this.sortBy.ASC;
                    break;
                default: break;
            }
        } else {
            this.searchRequest.sortDirection = this.sortBy.ASC;
        }
        this.searchRequest.sortBy = SortByColumn;

        this.sortingChangeAction.emit(this.searchRequest);
    }

    editProductDetail(modelData: ProductSummary) {
        const model: CreateEditProductCcnModel = {
            productCcnRecId: modelData.productCcnRecId,
            productCcn: modelData.ccn,
            productType: modelData.productType,
            deliverable: modelData.deliverable ? modelData.deliverable.trim() : modelData.deliverable,
            deliverableType: modelData.deliverableType ? modelData.deliverableType.trim() : modelData.deliverableType,
            industry: modelData.industry ? modelData.industry.trim() : modelData.industry,
            typeOfService: modelData.typeOfService ? modelData.typeOfService.trim() : modelData.typeOfService,
            fusSampleLines: modelData.fusSampleLines,
            isML: modelData.isML ? modelData.isML.trim() : modelData.isML,
            invoiceText: modelData.invoiceText,
            subIndustry: modelData.subIndustry
        };
        this.sideNavService.createEditProductCcn(model, 'edit');
    }

    deleteProduct(prodRecId: number) {
        const factory = this.resolver.resolveComponentFactory(
            LoaderComponent
        );
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Delete Product',
            confirmationInfo: 'Are you sure you want to delete the product?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const userId = this.dataService.getLastUpdatedBy();
                    this.productService.deleteProduct(prodRecId, userId).subscribe((result) => {
                        componentRef.destroy(); // To destroy the load spinner component
                        if (result) {
                            this.deleteAction.emit({ action: Constants.DELETE, value: true });
                        }
                        else {
                            this.deleteAction.emit({ action: Constants.DELETE, value: false });
                        }
                    },
                        error => {
                            componentRef.destroy(); // To destroy the load spinner component
                            this.deleteAction.emit({ action: Constants.DELETE, value: false });
                        });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
    }

    ngOnInit(): void {
        this.dataService.clearModelData();
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
        this.searchRequest.sortBy = this.currentSort.active;
        this.searchRequest.sortDirection = this.currentSort.direction;
    }

}

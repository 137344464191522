import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../constants';
import { FileVolumeSearchRequest, FileVolumeSearchResultModel, FileVolumeSummary } from '../file-volume-detail-search.model';
import { Paginator } from '../../shared/paginator/paginator.model';
import { DataService } from '../../core/services/data.service';
import { FileVolumeService } from '../file-volume.service';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { FileSummary, WithdrawOrDeleteEventModel } from '../../file-details/file-detail-search.model';
import { CreateEditFileVolume } from '../create-file-volume/create-file-volume.model';
import { WdrwlCodesSummary, GenerateAuthPageModel } from '../../shared/utility.model';
import { PreviousRouteService } from '../../shared/previous-route.service';

@Component({
    selector: 'app-file-volume',
    templateUrl: './file-volume.component.html',
    styleUrls: ['./file-volume.component.scss'],
})
export class FileVolumeComponent implements OnInit, AfterViewInit {
    @ViewChild('fileViewVolumeContainer', { static: true, read: ViewContainerRef, }) entry: ViewContainerRef;
    public pageName = '';
    levelTwoTitle = '';
    levelOneURL = '';
    levelTwoURL = '';
    activeLevel = 0;

    loading = false;
    isSearch = false;
    isSearching = false;
    noSearchResults = false;
    hasSearchActionOccurred = false;
    searchResultCount: number;
    isTableView = false;
    isAdmin: boolean;
    isEdit: boolean;

    prevSort: string = '';
    withdrawalCodes: WdrwlCodesSummary[];
    modelData: FileSummary & FileVolumeSummary;
    refreshSearchResultSubscription: Subscription;
    filterOptions = Constants.SHOW_BY_OPTIONS;
    sortOptions = Constants.FILE_VOLUME_SEARCH_SORT_OPTIONS;
    prevFilter: string = this.filterOptions[0].value;
    searchResult: FileVolumeSearchResultModel;
    currentSort = { active: '', direction: '' };
    fileVolumeSearchModel: FileVolumeSearchRequest = {
        fileNumber: '',
        ownerAccount: '',
        volume: '',
        ccn: '',
        compCCN: true,
        subscriberNumber: '',
        partySiteNumber: '',
        fileRecId: null,
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0],
        filterBy: Constants.ALL,
        volumeType: ''
    };

    constructor(
        private sideNavService: SideNavService,
        private router: Router,
        private dataService: DataService,
        private toastService: ToastNotificationService,
        private searchService: FileVolumeService,
        private resolver: ComponentFactoryResolver,
        private route: ActivatedRoute,
        private loadingSpinnerService: LoadingSpinnerService,
        private previousRouteService: PreviousRouteService
    ) {
        this.dataService.getModelData().subscribe((result) => (this.modelData = result));
        if (!this.modelData) {
            this.modelData = <FileSummary & FileVolumeSummary>(this.route.snapshot.data.modelData);
            this.dataService.setModelData(this.modelData);
        }
        this.refreshSearchResultSubscription = this.searchService.refreshSearchResult$.subscribe(res => {
            // Fetch the data again
            if (this.dataService.isEmpty(this.fileVolumeSearchModel)) {
                this.getSearchResult(this.fileVolumeSearchModel);
            }
        });
    }

    ngOnInit(): void {
        this.activeLevel = 2;
        this.pageName = 'File Details';
        this.levelTwoTitle = 'Volume Details';
        this.fileVolumeSearchModel.fileRecId = Number(this.route.snapshot.paramMap.get('fileRecId'));
        this.levelOneURL = `/file-details/${this.fileVolumeSearchModel.fileRecId}/details`;
        this.getSearchResult(this.fileVolumeSearchModel);
        this.withdrawalCodes = this.dataService.getOption(Constants.WDRWL_CODES)
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
        this.getWithdrawalCodeDetails();
        this.getRespOfficeCodes();
    }

    ngOnDestroy(): void {
        if (this.refreshSearchResultSubscription) {
            this.refreshSearchResultSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        // Add 'implements AfterViewInit' to the class.
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    /*** This method will get the         ***
     *** withdrawal codes and description ***/
    getWithdrawalCodeDetails = () => {
        this.dataService.getWdrwlCodes().subscribe((details) => {
            this.withdrawalCodes = details.results;
            this.dataService.setOption(Constants.WDRWL_CODES, details.results);
        });
    }

    getRespOfficeCodes = () => {
        this.dataService.getRespOfcCodes().subscribe((details) => {
            this.dataService.setOption(Constants.RESP_OFC_CODE, details.results);
        });
    }

    addVolume(modelData: FileSummary) {
        const compCCN = {
            compCCNRecId: 0,
            compCCN: "",
            fileVolRecId: 0,
            action: Constants.ADD
        };
        const model: CreateEditFileVolume = {
            volumeType: '',
            volume: '',
            startDate: this.dataService.getCurrentDate(),
            endDate: null,
            wdrwlCode: '',
            basicCcn: '',
            complementaryCCN: [compCCN],
            ownerFileNo: modelData.fileNumber,
            ownerAccount: modelData.ownerAccount,
            ownerPartySiteNumber: modelData.ownerPartySiteNumber,
            volumeStatus: 'A',
            serviceContratctNumber: null,
            isListee: null,
            fileRecId: modelData.fileRecId,
            wdrlRecId: 0,
            markCode: null,
            respOfcRecId: 1,
            respOffice: 'NBK',
            isSetUpComplete: null,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            lastUpdatedOn: this.dataService.getLastUpdatedDate(),
        };
        this.sideNavService.addVolume(model, this.withdrawalCodes);
    }

    onViewMfr() {
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.router.navigateByUrl(`/file-details/${this.modelData.fileRecId}/mfr-details`);
    }

    onViewListee() {
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.router.navigateByUrl(`/file-details/${this.modelData.fileRecId}/listee-details`);
    }

    /*createMfr(modelData: FileVolumeSummary) {
        let deliverableInstance: DeliverableItemInstance[];
        deliverableInstance = [
            {
                deliverableItemInstanceId: null,
                deliverableItemInstanceName: ''
            },
        ];

        const model: CreateEditMfrModel = {
            fileVolRecId: modelData.fileVolRecId,
            mfrAssoRecId: null,
            fileNumber: modelData.fileNumber,
            volume: modelData.volume,
            startDate: this.dataService.getCurrentDate(),
            endDate: null,
            deliverableItems: deliverableInstance,
            mfrAccountNumber: '',
            orgNumber: '',
            mfrPartySiteNumber: '',
            mfrPartyNumber: '',
            mfrSubscriberNumber: '',
            wdrwlRecId: 0,
            wdrwlCode: '',
            companyName: '',
            serviceContract: '',
            mfrType: '',
            mfrFactoryId: '',
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
        };
        this.sideNavService.createEditMfr(model, this.dataService.getOption(Constants.WDRWL_CODES));
    }

    addListee(modelData: FileVolumeSummary) {
        const model: CreateListeeModel = {
            fileVolRecId: modelData.fileVolRecId,
            listeeRecId: 0,
            fileNumber: modelData.fileNumber,
            listeeFileNumber: '',
            accountNumber: '',
            orgNumber: '',
            listeePartySiteNumber: '',
            subscriberNumber: '',
            companyName: '',
            companyAddress: '',
            associationType: null,
            startDate: this.dataService.getCurrentDate(),
            endDate: null,
            lastUpdatedBy: this.dataService.getLastUpdatedBy()
        };
        this.sideNavService.createListee(model);
    }*/

    onViewChange = (sortingEvent: Paginator) => {
        this.isTableView = sortingEvent.isTableView;
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        this.fileVolumeSearchModel.pageNumber = sortingEvent.page;
        this.fileVolumeSearchModel.filterBy = sortingEvent.filterBy;
        this.fileVolumeSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.fileVolumeSearchModel);
    }

    onSortByChange = (sortingEvent: Paginator) => {
        if (this.prevFilter != sortingEvent.filterBy) {
            this.fileVolumeSearchModel.pageNumber = sortingEvent.page;
        }
        this.fileVolumeSearchModel.filterBy = sortingEvent.filterBy;
        this.fileVolumeSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.fileVolumeSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];
        this.getSearchResult(this.fileVolumeSearchModel);
    }

    /* To sort the values based on the sort option chosen in the table headers - @param sortingEvent */
    onSortingChange = (sortingEvent: FileVolumeSearchRequest) => {
        this.fileVolumeSearchModel.sortBy = sortingEvent.sortBy;
        this.fileVolumeSearchModel.sortDirection = sortingEvent.sortDirection;
        this.getSearchResult(this.fileVolumeSearchModel);
    }

    onSearch = (formValues) => {
        this.fileVolumeSearchModel.pageNumber = 1;
        this.fileVolumeSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
        this.fileVolumeSearchModel.sortDirection = this.sortOptions[0].value.split('-')[1];
        this.fileVolumeSearchModel.sortBy = this.sortOptions[0].value.split('-')[0];
        this.fileVolumeSearchModel.filterBy = Constants.ALL;
        this.getSearchResult(formValues);
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.prevFilter = this.fileVolumeSearchModel.filterBy;
            this.searchService.getSearchResult(this.fileVolumeSearchModel).subscribe((searchResult) => {
                componentRef.destroy();
                this.searchResult = searchResult;
                this.searchResultCount = searchResult.paging.totalRecords;
                this.currentSort = {
                    active: this.fileVolumeSearchModel.sortBy,
                    direction: this.fileVolumeSearchModel.sortDirection
                };

                if (this.searchResultCount === 0) {
                    const errorMessage = 'No Data found for the specified search criteria';
                    const type = ToastNotificationType.infoBlue;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.isSearch = false;
                    return;
                }
                else {
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = false;
                    this.isSearch = true;
                }
            }, (error) => {
                componentRef.destroy();
                const errorMessage = 'Error while retrieving the data';
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
                this.hasSearchActionOccurred = true;
                this.searchResultCount = 0;
                this.noSearchResults = true;
                this.isTableView = false;
                this.isSearch = false;
            });
        }
        else {
            const errorMessage = 'Please enter the search criteria in the form below';
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.searchResultCount = 0;
            this.isSearch = false;
            this.isTableView = false;
        }
    }

    onWithdrawOrDelete = (withdrawOrDeleteEvent: WithdrawOrDeleteEventModel) => {
        document.querySelector('mat-sidenav-content').scrollTop = 0;
        if (withdrawOrDeleteEvent.value) {
            const successMessage = `The File volume detail was ${withdrawOrDeleteEvent.action.toLowerCase()} successfully`;
            const type = ToastNotificationType.success;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(successMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.getSearchResult(this.fileVolumeSearchModel);
        }
        else {
            const withdrawOrDeleteMsg = withdrawOrDeleteEvent.action === Constants.WITHDRAWN ? 'withdraw' : 'delete';
            const errorMessage = `Error while trying to ${withdrawOrDeleteMsg} the file volume. Please try again later`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    generateAuthPage = () => {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const fileVolRec = this.dataService.getOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID);
        const empId = this.dataService.getLastUpdatedBy();
        if (fileVolRec != undefined && fileVolRec.length > 0) {
            // Call the api to generate the auth page
            const request: GenerateAuthPageModel = {
                inputType: "FILE_VOL",
                empId: empId,
                records: fileVolRec
            };
            this.dataService.generateAuthPage(request).subscribe(result => {
                componentRef.destroy();
                if (result["OverallStatus"] == Constants.SUCCESS) {
                    const successMessage = `Successfully generated the Auth page for selected file volume(s)`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, []);
                    this.searchService.onRefreshSearchResult();
                }
                else {
                    var errorMessage = `Error while generating the Auth page for selected file volume(s)`;
                    if (result["ErrorMessage"] != "") { errorMessage = result["ErrorMessage"]; }
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                componentRef.destroy();
                const errorMessage = `Error while generating the Auth page for selected file volume(s)`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
        }
        else {
            componentRef.destroy();
            const errorMessage = `Please select the file volume(s) to proceed.`;
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }
}
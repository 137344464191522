<div class="px-20">
    <app-breadcrumb [levelOneTitle]="pageName" [levelTwoTitle]="levelTwoTitle" [activeTitleLevel]="activeLevel">
    </app-breadcrumb>
</div>
<div class="">
    <mat-card class="main-card mat-elevation-z2">
        <mat-card-header>
            <div class="header-text w-full">
                <h4>SEARCH FOR INSPECTION CENTER (IC)</h4>
            </div>
            <div class="additional-menu float-right">
                <button [matMenuTriggerFor]="menu" class="additional-menu-button" aria-label="Menu buttom">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="additional-menu-item" (click)="exportToExcel()"
                            [disabled]="!hasSearchActionOccurred || noSearchResults">
                        <mat-icon>upload_file</mat-icon>
                        <span>Export to Excel</span>
                    </button>
                </mat-menu>
            </div>
        </mat-card-header>
        <mat-card-content class="main-card-content">
            <form #icDetailSearchForm="ngForm" (ngSubmit)="onSearch(icDetailSearchForm.value)">
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inputPostalCode" class="form-label">Postal Code</label>
                        <div>
                            <input type="text" name="postalCode" [(ngModel)]="icDetailSearchModel.postalCode"
                                   #postalCode="ngModel" class="form-control" id="inputPostalCode" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputState" class="form-label">State</label>
                        <div>
                            <input type="text" name="state" [(ngModel)]="icDetailSearchModel.state"
                                   #state="ngModel" class="form-control" id="inputState" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputCity" class="form-label">City</label>
                        <div>
                            <input type="text" name="city" [(ngModel)]="icDetailSearchModel.city"
                                   #city="ngModel" class="form-control" id="inputCity" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputProvince" class="form-label">Province</label>
                        <div>
                            <input type="text" name="province" [(ngModel)]="icDetailSearchModel.province"
                                   #province="ngModel" class="form-control" id="inputProvince" />
                        </div>
                    </div>
                </div>
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inputCountry" class="form-label">Country</label>
                        <div>
                            <input type="text" name="country" [(ngModel)]="icDetailSearchModel.country"
                                   #country="ngModel" class="form-control" id="inputCountry" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputAreaManager" class="form-label">Area Manager</label>
                        <div>
                            <input type="text" name="areaManager" [(ngModel)]="icDetailSearchModel.areaManager"
                                   #areaManager="ngModel" class="form-control" id="inputAreaManager" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputICNumber" class="form-label">IC Number</label>
                        <div>
                            <input type="text" name="icNumber" [(ngModel)]="icDetailSearchModel.icNumber"
                                   #icNumber="ngModel" class="form-control" id="inputICNumber" />
                        </div>
                    </div>
                </div>
                <div class="md-3 row">
                    <div class="col-md-4">
                        <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty form-label" name="exactSearch"
                                      [(ngModel)]="icDetailSearchModel.exactSearch">
                            Exact Search
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="text-right col-md-4">
                        <button class="mr-2 button form-clear-button" type="button" (click)="resetForm(icDetailSearchForm)">Clear</button>
                        <button class="button form-button" type="submit">Search</button>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <div class="w-full m-0"
             *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
            <hr />
            <app-paginator (sortingChange)='onSortByChange($event)' [isTableView]="isTableView" (viewChange)='onViewChange($event)'
                           (paginationChange)='onSortPaginationChange($event)' [length]="searchResultCount" [pageIndex]="0"
                           [sortByOptions]="sortOptions" [showSortBy]="true" [showFilterBy]="false" [showPageSize]="true">
            </app-paginator>
        </div>
    </mat-card>
    <ng-template #icDetailsContainer>
    </ng-template>
    <div *ngIf="noSearchResults && hasSearchActionOccurred">
        <div class="ml-20">
            <div class="flex flex-col justify-center max-w-full mx-auto my-3">
                <div class="flex">
                    <img src="../../../assets/images/noResults.png" class="Bitmap mx-auto" />
                </div>
                <div class="flex">
                    <div class="feedback-header mx-auto title-no-data">No Inspection Center Details Found</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
        <div class="search-details-container">
            <div *ngIf="searchResult && searchResult.results && !isTableView">
                <app-ic-detail-search-result-card *ngFor="let icDetail of searchResult.results; let i = index;" [model]="icDetail"
                                                  [currentSort]="currentSort">
                </app-ic-detail-search-result-card>
            </div>
            <div *ngIf="searchResult && searchResult.results && isTableView">
                <app-ic-detail-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                                  (sortingChangeAction)="onSortingChange($event)">
                </app-ic-detail-search-result-card>
            </div>
        </div>
    </div>
    <div class="loader-center" *ngIf="loading">
        <div id="loader" class="loader"></div>
    </div>
</div>
<div *ngIf="message && message.message" [ngClass]="message && message.message && (message.placement != 'InnerSidenav') ? 'pl-20 w-full':''">

    <ngb-alert [type]="message.type" [ngClass]="message.cssClass" (closed)="close()">
        <div class="d-flex flex-row">
            <div
                [ngClass]="['d-flex', 'align-items-center', 'alert-icon-wrapper', 'justify-content-around', message.iconWrapperCssClass]">
                <i class="material-icons inline-mat-icon md-18">info_outline</i>
            </div>
            <div class="d-flex align-items-center pl-3">
                {{ message.message }}
            </div>
        </div>
    </ngb-alert>

</div>

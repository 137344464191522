import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientSettingsService } from '../core/services/client-settings.service';
import {
    ProductCcnSearchRequest,
    ProductSearchResultModel,
    ProductSummary,
    ProductEditRequest
} from './product-ccn.model';
import { CreateEditProductCcnModel } from './create-product-ccn/create-product-ccn.model';

@Injectable({
  providedIn: 'root'
})
export class ProductCcnService {

    private readonly refreshSearchResult = new Subject<any>();
    public refreshSearchResult$ = this.refreshSearchResult.asObservable();

    private webApiEndPoint: string;
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    private httpOptionsString = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        responseType: 'blob' as 'json'
    };

    private fileImportResponseString = {
        responseType: 'blob' as 'json'
    };

    constructor(
        private clientSetting: ClientSettingsService,
        private http: HttpClient) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/ProductCcnAssociation/`;
    }

    onRefreshSearchResult() {
        this.refreshSearchResult.next(); // This will trigger search API to fetch results again
    }

    getSearchResult(searchRequest: ProductCcnSearchRequest): Observable<ProductSearchResultModel> {
        const searchResult = this.http.post<ProductSearchResultModel>(`${this.webApiEndPoint}search`, searchRequest, this.httpOptions)
            .pipe();
        return searchResult;
    }

    getProductDetails(prodRecId: number): Observable<ProductSummary> {
        const productDetail = this.http.get<ProductSummary>(`${this.webApiEndPoint}${prodRecId}`, this.httpOptions).pipe(
            // catchError(this.dataService.handleError('SearchFile', searchRequest))
        );
        return productDetail;
    }

    updateProductDetail = (request: CreateEditProductCcnModel): Observable<boolean> => {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}update`,request,this.httpOptions).pipe();
        return result;
    };

    addProduct = (request: CreateEditProductCcnModel): Observable<boolean> => {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}create`,request,this.httpOptions).pipe();
        return result;
    };

    deleteProduct(productRecId: number, userId: string) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}delete/${productRecId}`, JSON.stringify(userId), this.httpOptions).pipe();
        return result;
    }

    exportToExcel = (searchRequest: ProductCcnSearchRequest) => {
        return this.http.post(`${this.webApiEndPoint}exportToExcel`, searchRequest, this.httpOptionsString).pipe();
    }

    uploadExcelFile = (formData: FormData) => {
        const result = this.http.post<any>(`${this.webApiEndPoint}importProductData`, formData, this.fileImportResponseString).pipe();
        return result;
    }
    
    exportAllData = () => {
        return this.http.get(`${this.webApiEndPoint}exportToExcel/all`, this.httpOptionsString).pipe();
    }
}

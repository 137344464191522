import { Directive, ElementRef, HostListener, Output, EventEmitter, Input, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appUppercase]'
})
export class UppercaseDirective {
  @Input() condition: boolean = true; 
  
  constructor(private readonly control: NgControl) { }

  @HostListener('input', ['$event.target'])
  public onInput(input: HTMLInputElement): void {
    if (this.condition) {
      const pos = input.selectionStart;
      this.control.control.setValue(input.value.toUpperCase());
      input.setSelectionRange(pos, pos);
    }
  }
}

@NgModule({
    declarations: [UppercaseDirective],
    exports: [UppercaseDirective]
})

export class UppercaseDirectiveModule { }
<ng-template #CreateNewFileVolume>
</ng-template>
<div class="d-flex flex-column mx-3 px-3">
    <div class="d-flex my-3 justify-content-between align-items-center">
        <div class="d-flex">
            <i (click)="onClose()" class="material-icons md-36 action-item mr-2">chevron_left</i>
            <h2 class="capitalize">{{ action }} Volume</h2>
        </div>
    </div>
    <div class="ml-3.5">
        <span class="required-field">* Indicates Required Fields</span>
    </div>
    <div class="my-2 py-1 px-3">
        <ng-form [formGroup]="createEditFileVolForm">
            <input type="hidden" formControlName="fileVolRecId" name="fileRecId" />
            <div class="form-group row">
                <label for="ownerFileNo" class="col-sm-4 col-form-label">File<span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" name="ownerFileNo" formControlName="ownerFileNo"
                           id="ownerFileNo" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="volume" class="col-sm-4 col-form-label">Volume<span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" name="volume" formControlName="volume" required id="volume" class="form-control"
                           [ngClass]="{'is-invalid': (createEditFileVolForm.get('volume').touched || createEditFileVolForm.get('volume').dirty)
                           && !createEditFileVolForm.get('volume').valid }" volumeExists
                           [fileRecId]="createEditFileVolForm.get('fileRecId').value" [validateFormControl]="createEditFileVolForm.controls['volume']" />
                    <span class="invalid-feedback">
                        <span *ngIf="createEditFileVolForm.get('volume').errors?.required">
                            Please enter Volume Number.
                        </span>
                        <span *ngIf="createEditFileVolForm.get('volume').errors?.volumeExists">
                            Entered volume exists. Please enter Volume Number.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="volumeType" class="col-sm-4 col-form-label">Volume Type <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select class="form-control" name="volumeType" formControlName="volumeType" id="volumeType" required
                            [ngClass]="{'is-invalid': (createEditFileVolForm.get('volumeType').touched || createEditFileVolForm.get('volumeType').dirty)
                           && !createEditFileVolForm.get('volumeType').valid }">
                        <option [value]="null" [disabled]=true [selected]="this.action === 'Add New'">Choose Volume Type</option>
                        <option *ngFor="let type of fileVolumeType" [value]="type.value">{{type.name}}</option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditFileVolForm.get('volumeType').errors?.required">
                            Please select Volume Type.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="basicCcn" class="col-sm-4 col-form-label">Base CCN <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" name="basicCcn" formControlName="basicCcn" id="basicCcn" class="form-control" required (blur)="ccnExistsValidator($event,'basicCcn',0)"
                           [ngClass]="{'is-invalid': (createEditFileVolForm.get('basicCcn').touched || createEditFileVolForm.get('basicCcn').dirty)
                           && !createEditFileVolForm.get('basicCcn').valid }" maxlength="5" appUppercase />
                    <span class="invalid-feedback">
                        <span class='block' *ngIf="createEditFileVolForm.get('basicCcn').errors?.required">
                            Please enter the CCN value.
                        </span>
                        <span class='block' *ngIf="createEditFileVolForm.get('basicCcn').errors?.minlength">
                            The CCN value should be of minimum 4 characters long.
                        </span>
                        <span class='block' *ngIf="createEditFileVolForm.get('basicCcn').errors?.pattern">
                            The CCN value can contain only letters and numbers.
                        </span>
                        <span class='block' *ngIf="createEditFileVolForm.get('basicCcn').errors?.ccnExists">
                            Entered CCN doesn't exist. Please enter a valid Base CCN.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="compCCN" class="col-sm-4 col-form-label">Comp CCN</label>
                <div class="col-sm-8" formArrayName="complementaryCCN">
                    <div *ngFor="let item of createEditFileVolForm.get('complementaryCCN')['controls']; let i = index;" class="col p-0 mb-2">
                        <div class="input-group" [formGroupName]="i">
                            <input type="text" name="item_compCCN_{{i}}" formControlName="compCCN" id="item_compCCN_{{i}}" class="form-control"
                                   [ngClass]="{'is-invalid': (complementaryCCN.controls[i].get('compCCN').touched || complementaryCCN.controls[i].get('compCCN').dirty)
                                   && !complementaryCCN.controls[i].get('compCCN').valid }" maxlength="5" appUppercase (blur)="ccnExistsValidator($event,'compCCN',i)" />
                            <div class="cursor-pointer input-group-append ml-2">
                                <i (click)="addNewCompCCN()" class="material-icons md-36 pr-3">add_circle_outline</i>
                                <i (click)="removeCompCcn(i)" class="material-icons md-36">remove_circle_outline</i>
                            </div>
                        </div>
                        <span class="invalid-error">
                            <span class="block" *ngIf="complementaryCCN.controls[i].get('compCCN').errors?.minlength">
                                The CCN value should be of minimum 4 characters long.
                            </span>
                            <span class="block" *ngIf="complementaryCCN.controls[i].get('compCCN').errors?.pattern">
                                The CCN value can contain only letters and numbers.
                            </span>
                            <span class="block" *ngIf="complementaryCCN.controls[i].get('compCCN').errors?.ccnExists">
                                Entered CCN doesn't exist. Please enter a valid Comp CCN.
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="startDate" class="col-sm-4 col-form-label">Start Date <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <mat-form-field appearance="none">
                        <input matInput [matDatepicker]="startDatePicker" required [value]="createEditFileVolForm.controls.startDate.value" 
                               id="startDate" formControlName="startDate" name="startDate" class="form-date-picker"
                                [ngClass]="{'is-invalid': (createEditFileVolForm.get('startDate').touched || createEditFileVolForm.get('startDate').dirty)
                           && !createEditFileVolForm.get('startDate').valid }">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditFileVolForm.get('startDate').errors?.required">
                            Please enter the start date
                        </span>
                    </span>
                </div>
            </div>
            <!--<div class="form-group row">
                <label for="endDate" class="col-sm-4 col-form-label">End Date</label>
                <div class="col-sm-8">
                    <mat-form-field appearance="none">
                        <input matInput [matDatepicker]="endDatePicker"
                               id="mfrEndDate" formControlName="endDate" name="endDate" class="form-date-picker">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>-->
            <div class="form-group row">
                <label for="withdrawalCode" class="col-sm-4 col-form-label">Withdrawal Code</label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="wdrwlCode"
                            name="wdrwlCode" formControlName="wdrwlCode">
                        <option [value]="option.wdrwlCode" *ngFor="let option of wdrwlCodes">{{ option.wdrwlCode }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="respOfcRecId" class="col-sm-4 col-form-label">Responsible Office <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="respOfcRecId"
                            name="respOffice" formControlName="respOffice" required
                             [ngClass]="{'is-invalid': (createEditFileVolForm.get('respOffice').touched || createEditFileVolForm.get('respOffice').dirty)
                           && !createEditFileVolForm.get('respOffice').valid }">
                        <option [value]="null" [disabled]=true>Choose Responsible Office</option>
                        <option *ngFor="let option of respOfcCodes" [value]="option.respOfc">{{ option.respOfc }}</option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditFileVolForm.get('respOffice').errors?.required">
                            Please select the Responsible office
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="markCode" class="col-sm-4 col-form-label">Mark Code <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="markCode" required
                            name="markCode" formControlName="markCode"
                             [ngClass]="{'is-invalid': (createEditFileVolForm.get('markCode').touched || createEditFileVolForm.get('markCode').dirty)
                           && !createEditFileVolForm.get('markCode').valid }">
                        <option [value]="null" [disabled]=true>Choose Marking Code</option>
                        <option *ngFor="let option of markCodes" [value]="option.value">{{ option.key }}</option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditFileVolForm.get('markCode').errors?.required">
                            Please select the Mark Code
                        </span>
                    </span>
                </div>
            </div>
            <div class="d-flex flex-row my-3">
                <button type="submit" class="btn btn-copy" (click)="onSubmit(createEditFileVolForm.value)" [disabled]="!createEditFileVolForm.valid"
                        [ngClass]="{'cursor-not-allowed': !createEditFileVolForm.valid}">
                    {{ action }} Volume
                </button>
                <button type="reset" class="btn btn-outline-secondary ml-3" (click)="onClose()">
                    Cancel
                </button>
            </div>
        </ng-form>
    </div>
</div>
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { state, style, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { DataService } from '../../core/services/data.service';
import { CustomerDetailSummary } from '../customer-details-search.model';
import { WdrwlCodesSummary } from '../../shared/utility.model';
import { Constants } from '../../constants';
import { CreateListeeModel } from '../../listee/create-listee/create-listee.model';
import { CreateEditMfrModel } from '../../mfr-detail/create-new-mfr/create-mfr.model';
import { DeliverableItemInstance } from '../../mfr-detail/mfr-detail-search.model';
import { MatTableDataSource } from "@angular/material/table";
import { MfrDetailService } from "../../mfr-detail/mfr-detail.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-customer-search-result-card',
    templateUrl: './customer-search-result-card.component.html',
    styleUrls: ['./customer-search-result-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
        ]),
    ]
})
export class CustomerSearchResultCardComponent implements OnInit, AfterViewInit {
    private tableDataSource;
    @Input() isTableView: boolean;
    @Input() model: CustomerDetailSummary;
    @Input() set tableData(value: CustomerDetailSummary[]) {
        this.tableDataSource = new MatTableDataSource(value);
        this.checkIfSelected();
    }
    columnsToDisplay = Constants.CUSTOMER_RESULT_TABLE_COLUMNS;
    isAdmin: boolean;
    isEdit: boolean;
    isCustomerExpanded = false;
    constructor(private router: Router,
        private dataService: DataService,
        private sideNavService: SideNavService,
        private modalService: NgbModal){

    }
    formatDate(dateObj: string, splitter: string = "-") {
        return this.dataService.formatDate(dateObj, splitter);
    }

    ngOnInit(){
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
    }

    ngAfterViewInit(){
       // this.checkIfSelected();
    }
    checkIfSelected() {
        // const existingRecId = this.dataService.getOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID);
        // if (!this.isTableView) {
        //     if (existingRecId) {
        //         const length = existingRecId.length;
        //         for (let i = 0; i < length; i++) {
        //             if (existingRecId[i] === this.model.fileVolRecId) {
        //                 this.model.isAuthPage = true;
        //                 break;
        //             }
        //         }
        //     }
        // }
        // else {
        //     if (existingRecId) {
        //         const length = existingRecId.length;
        //         const modelLength = this.tableDataSource.data.length;
        //         for (let i = 0; i < modelLength; i++) {
        //             for (let j = 0; j < length; j++) {
        //                 if (existingRecId[j] == this.tableDataSource.data[i].fileVolRecId) {
        //                     this.tableDataSource.data[i].isAuthPage = true;
        //                     break;
        //                 }
        //             }
        //         }
        //     }
        // }
    }

}
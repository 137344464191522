<div>
  <mat-card class="p-0">
    <mat-card-content class="">
      <div class="row">
        <div class="col-md-3" *ngIf="showSortBy">
          <label for="sortBy" class="form-label">Sort By</label>
          <div>
            <mat-select class="form-control" id="sortBy" required name="sortBy" [(ngModel)]="sortBy"
              (selectionChange)="onSortingChange()" >
              <mat-option [value]="option.value" *ngFor="let option of pageSortByOptions">{{ option.key }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="col-md-2" *ngIf="showFilterBy">
          <label for="filterBy" class="form-label">Filter By</label>
          <div>
            <mat-select class="form-control" id="filterBy" required name="filterBy" [(ngModel)]="filterBy"
              (selectionChange)="onSortingChange()">
              <mat-option [value]="options.value" *ngFor="let options of pageShowByOptions">{{ options.key }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="col-md-2">
            <label for="isTableOrCardView" class="form-label">Choose the view</label>
            <div class="mt-2">
                <mat-radio-group id="isTableOrCardView" required name="viewType" [(ngModel)]="viewType"
                                 aria-label="Select view type" (change)="onViewTypeChange($event)">
                    <mat-radio-button class="pr-4" value="Card" [checked]="!isTableView">Card</mat-radio-button>
                    <mat-radio-button value="Table" [checked]="isTableView">Table</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="col-md-3" *ngIf="showPageSize">
          <label for="pageSize" class="form-label">Page Size</label>
          <div>
            <mat-select class="form-control" id="pageSize" required name="pageSize" [(ngModel)]="pageSize"
              (selectionChange)="onPageSizeChange($event.value)">
              <mat-option [value]="page" *ngFor="let page of pageSizeOptions">{{ page }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="mr-0 ml-auto" *ngIf="showRangePageLabel">
          <table>
            <tr class="row">
              <td>
                <div class="mt-9 mr-2 text-xs">
                  <label class="form-label italic">{{ pageRangeLabel }}</label>
                </div>
              </td>
              <td>
                <label for="pageNumber" class="form-label">Pages</label>
                <mat-select class="form-control" id="pageNumber" required [(ngModel)]="page" name="page"
                  (selectionChange)="onPageChange($event.value)">
                  <mat-option [value]="page" *ngFor="let page of numberOfPages">{{ page }}</mat-option>
                </mat-select>
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                <div class="mt-4">
                  <div style="width:100px;">
                    <div style="float: left; width: 40px;">
                      <button class="button form-clear-button" (click)="onPageChange(page - 1)" [disabled]="page==1" [ngClass]="{'btn-disabled-pagination': page==1}">
                        &laquo;
                      </button>
                    </div>
                    <div style="width: 80px;">
                        <button class="button form-clear-button" (click)="onPageChange(page + 1)" [ngClass]="{'btn-disabled-pagination': page==numberOfPages.length}" [disabled]="page==numberOfPages.length">
                            &raquo;
                        </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { InfoWindowDates, InfoWindowUserStatus, InfoWindowUserData } from './informationwindow.model';
import { ClientSettingsService } from '../../core/services/client-settings.service';
import { AuthService } from '../../core/services/auth.service';

@Injectable({ providedIn: 'root' })

export class InformationWindowService {
    private infoStatusObj: InfoWindowDates;
    private infoStatusArray: InfoWindowDates[];
    private infoUserStatus: InfoWindowUserStatus;
    private infoUserDataArray: InfoWindowUserStatus[];
    private infoUserData: InfoWindowUserData;

    private UserPopup: boolean = false;
    private UserPopupCount: number = 2;

    private infoUserID: string = "";
    private TimeNow: Date = new Date();
    private MinDate: Date = new Date();
    private MaxDate: Date = new Date();
    private TodayDate: Date = new Date();

    constructor(private authService: AuthService,
        private clientSetting: ClientSettingsService) {
        let UsrEntries = 0;
        let UsrDates = 0;
        let UsrPopup = false;
        this.TimeNow = new Date();

        if (this.isDate(new Date(this.clientSetting.clientSettings.welcomeBannerReleaseDate))) {
            this.MinDate = new Date(this.clientSetting.clientSettings.welcomeBannerReleaseDate);
        }
        else { this.MinDate = new Date(2000, this.TimeNow.getMonth(), this.TimeNow.getDate()) }

        this.MaxDate.setDate(this.MinDate.getDate() + 7);
        this.TodayDate = new Date(this.TimeNow.getFullYear(), this.TimeNow.getMonth(), this.TimeNow.getDate());

        this.infoStatusObj = {} as InfoWindowDates;
        this.infoStatusArray = new Array<InfoWindowDates>();
        this.infoUserStatus = {} as InfoWindowUserStatus;
        this.infoUserDataArray = new Array<InfoWindowUserStatus>();
        this.infoUserData = {} as InfoWindowUserData;
    }

    setUserName(username: string) {
        this.infoUserID = username;
    }

    isDate = (date: Date | string): boolean => {
        return isFinite(+(date instanceof Date ? date : new Date(date)));
    }

    getPopupStatus(): boolean {
        if (typeof this.infoUserID != 'undefined'
            && this.infoUserID.trim().length == 0) { return false; }
        else { return this.UserPopup; }
    }

    UpdateUserData(ActionType: string) {
        if (window.localStorage) {
            let ObjInfoUserStatus = {} as InfoWindowUserStatus;
            ObjInfoUserStatus.StatusData = this.infoStatusArray.slice();
            ObjInfoUserStatus.UserID = this.infoUserID;

            if (ActionType == "Skip") { ObjInfoUserStatus.DayPopup = true; }
            else if (ActionType == "Confirm") { ObjInfoUserStatus.DayPopup = false; }
            else { ObjInfoUserStatus.DayPopup = false; }

            let ObjInfoStatusObj = {} as InfoWindowDates;
            ObjInfoStatusObj.PopupDate = this.TodayDate;
            ObjInfoUserStatus.StatusData.push(ObjInfoStatusObj);

            let DataStore = {} as InfoWindowUserData;
            DataStore.Data = this.infoUserDataArray.slice();
            DataStore.Data.push(ObjInfoUserStatus);
            localStorage.setItem("ReleaseInfoUserData", JSON.stringify(DataStore));
            this.DataRefresh();
        }
    }

    DataRefresh(): void {
        let UsrEntries = 0;
        let UsrDates = 0;
        let UsrPopup = false;
        let UsrSameDay = false;
        
        this.infoStatusObj = {} as InfoWindowDates;
        this.infoStatusArray = new Array<InfoWindowDates>();
        this.infoUserStatus = {} as InfoWindowUserStatus;
        this.infoUserDataArray = new Array<InfoWindowUserStatus>();
        this.infoUserData = {} as InfoWindowUserData;

        if (this.TodayDate.getTime() <= this.MaxDate.getTime()
            && this.TodayDate.getTime() >= this.MinDate.getTime()) { UsrPopup = true; }

        if (window.localStorage && UsrPopup) {
            //localStorage.removeItem("ReleaseInfoUserData");
            this.infoUserData = JSON.parse(localStorage.getItem("ReleaseInfoUserData")) as InfoWindowUserData;

            if (this.infoUserData === undefined || this.infoUserData == null) {
                this.infoStatusObj = {} as InfoWindowDates;
                this.infoStatusArray = new Array<InfoWindowDates>();
                this.infoUserStatus = {} as InfoWindowUserStatus;
                this.infoUserDataArray = new Array<InfoWindowUserStatus>();
                this.infoUserData = {} as InfoWindowUserData;
            }

            if (Array.isArray(this.infoUserData.Data) && this.infoUserData.Data.length) {
                this.infoStatusObj = {} as InfoWindowDates;
                this.infoStatusArray = new Array<InfoWindowDates>();
                this.infoUserStatus = {} as InfoWindowUserStatus;
                this.infoUserDataArray = new Array<InfoWindowUserStatus>();

                this.infoUserData.Data.forEach((element, index) => {
                    if (element.UserID === undefined) {
                        UsrEntries = 0;
                        UsrDates = 0;
                        UsrPopup = false;
                        UsrSameDay = false;
                    }
                    else if (element.UserID == this.infoUserID) {
                        UsrEntries = 0;
                        UsrDates = 0;
                        UsrPopup = false;
                        UsrEntries = UsrEntries + 1;
                        this.infoStatusArray = new Array<InfoWindowDates>();

                        element.StatusData.forEach((arrVal, arrIndx) => {
                            UsrSameDay = false;
                            let arrPopupDate = new Date(arrVal.PopupDate);

                            if (arrPopupDate.getTime() != this.TodayDate.getTime()
                                && arrPopupDate.getTime() > this.MinDate.getTime()) {
                                this.infoStatusArray.push(arrVal);
                                UsrDates = UsrDates + 1;
                            }
                            else if (arrPopupDate.getTime() == this.TodayDate.getTime()) {
                                UsrSameDay = true;
                            }
                        });

                        if (UsrDates >= this.UserPopupCount) { UsrPopup = false; }
                        else if (!UsrSameDay) { UsrPopup = true; }
                        else if (UsrSameDay && element.DayPopup) { UsrPopup = true; }
                        else if (UsrSameDay && !element.DayPopup) { UsrPopup = false; }
                        else { UsrPopup = false; }                        
                    }
                    else if (element.UserID != this.infoUserID
                        && element.UserID.length > 0 && this.infoUserID != "") {
                        this.infoUserDataArray.push(element);
                    }
                });
            }

            if (UsrEntries < this.UserPopupCount && UsrPopup == true) {
                let ObjInfoUserStatus = {} as InfoWindowUserStatus;
                ObjInfoUserStatus.DayPopup = true;
                ObjInfoUserStatus.UserID = this.infoUserID;
                ObjInfoUserStatus.StatusData = this.infoStatusArray.slice();

                let ObjInfoStatusObj = {} as InfoWindowDates;
                ObjInfoStatusObj.PopupDate = this.TodayDate;
                ObjInfoUserStatus.StatusData.push(ObjInfoStatusObj);
                this.infoUserStatus = ObjInfoUserStatus;
                this.UserPopup = true;
            }
            else { this.UserPopup = false; }

            if (this.UserPopup) {
                let DataStore = {} as InfoWindowUserData;
                DataStore.Data = this.infoUserDataArray.slice();
                DataStore.Data.push(this.infoUserStatus);
                localStorage.setItem("ReleaseInfoUserData", JSON.stringify(DataStore));
            }
        }
    }
}
import { Injectable } from '@angular/core';
import {
    Router,
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    ActivatedRoute,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { FileVolumeSummary } from '../file-volume/file-volume-detail-search.model';
import { FileSummary } from './file-detail-search.model';
import { FileDetailsService } from './file-details.service';

@Injectable({
    providedIn: 'root',
})
export class FileDetailsResolver implements Resolve<any> {
    constructor(
        private router: ActivatedRoute,
        private fileDetailsService: FileDetailsService,
        private dataService: DataService
    ) {}
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        let fileDetails: any;
        this.dataService
            .getModelData()
            .subscribe((modelData) => (fileDetails = modelData));
        if (fileDetails) {
            return of(fileDetails);
        } else {
            // Call the API here
            const fileRecId = route.params.fileRecId;
            return this.fileDetailsService
                .getFileDetails(fileRecId)
                .pipe((result) => result);
        }
    }
}

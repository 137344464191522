<div class="modal-header">
    <h2 class="modal-title">{{data.title}}</h2>
</div>
<div class="modal-body">
    <div class="font-bold mt-4">
        <div> {{data.confirmationInfo}}</div>
    </div>
    <div class="mt-3 mb-2 row" *ngIf="wdrwlCodeDetails">
        <div class="col-md-4">
            <label for="inputWdrwlCodes" class="form-label">Withdrawal Codes</label>
            <select matInput class="form-control" id="inputWdrwlCodes" required
                    name="wdrwlCodes" (change)="onCodeChange($event)">
                <option *ngFor="let code of wdrwlCodeDetails">{{ code.wdrwlCode }}</option>
            </select>
        </div>
        <div class="col-md-8">
            <label class="form-label">Withdrawal Description</label>
            <label class="description-label">{{ withdrawalDescription }}</label>
        </div>
    </div>
    <div class="mt-1 mb-2" *ngIf="showWithdrawOrDeleteAll" [hidden]="false">
        <div class="col-md-4">
            <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty font-bold" name="check"
                          [(ngModel)]="showWithdrawOrDeleteValue" (change)="onValueChange($event)">
                {{ checkboxMessage }}
            </mat-checkbox>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="isCancelButtonRequired" class="button button-white w-28 float-right m-1" (click)="onCancel()">{{ data.cancelButton }}</button>
    <button type="button" class="button button-blue w-28 float-right m-1" mat-button cdkFocusInitial
            (click)="onConfirm()">
        {{ data.confirmButton }}
    </button>
</div>

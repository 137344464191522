import { DataService } from '../../core/services/data.service';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { GenerateAuthPageModel } from '../utility.model';
import { ToastNotificationPlacement } from '../toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../toastnotification/toastNotificationType.model';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})

export class GenerateAuthPage {
    constructor(private dataService: DataService, private toastService: ToastNotificationService) { }

    generateAuthPage(record, inputType: string, componentRef) {
        const empId = this.dataService.getLastUpdatedBy();
        let successMsg = "", errorMsg = "";
        var request : GenerateAuthPageModel = {
            inputType: "",
            empId: empId,
            records: []
        }

        if (inputType == "FILE") {
            request = {
                inputType: "FILE",
                empId: empId,
                records: record
            }
        }
        else {
            request = {
                inputType: "FILE_VOL",
                empId: empId,
                records: record
            }
        }
        this.dataService.generateAuthPage(request).subscribe(result => {
            componentRef.destroy();

            if (inputType == "FILE") {
                successMsg = `Successfully generated the Auth page for selected file`;
                errorMsg = `Error while generating the Auth page for selected file`;
            }
            else {
                successMsg = `Successfully generated the Auth page for selected file volume`;
                errorMsg = `Error while generating the Auth page for selected file volume`;
            }
            if (result["OverallStatus"] == Constants.SUCCESS) {
                const successMessage = successMsg;
                const type = ToastNotificationType.success;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(successMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            }
            else {
                var errorMessage = errorMsg;
                if (result["ErrorMessage"] != "") {
                    errorMessage = result["ErrorMessage"];
                }
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            }
        },
            error => {
                componentRef.destroy();
                const errorMessage = errorMsg;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
    }
}
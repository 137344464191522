import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit, AfterViewInit {

  constructor(private adalService: MsAdalAngular6Service, private loadingSpinnerService: LoadingSpinnerService) { }

  ngOnInit(): void {
  }

  logout() {
    this.adalService.logout();
  }

  ngAfterViewInit(): void {
    this.loadingSpinnerService.removeLoadingSpinner();
  }
}

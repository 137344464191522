import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { state, style, trigger } from '@angular/animations';
import { ICDetailSummary, ICSearchRequest } from '../ic-detail.model';
import { Constants } from '../../constants';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-ic-detail-search-result-card',
    templateUrl: './ic-detail-search-result-card.component.html',
    styleUrls: ['./ic-detail-search-result-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
        ]),
    ]
})
export class IcDetailSearchResultCardComponent implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    private tableDataSource;

    @Input() model: ICDetailSummary;
    @Input() currentSort: Sort;
    @Input() set tableData(value: ICDetailSummary[]) {
        this.tableDataSource = new MatTableDataSource(value);
        this.tableDataSource.sort = this.sort;
    }
    @Input() isTableView: boolean;
    @Output() sortingChangeAction: EventEmitter<ICSearchRequest> = new EventEmitter();

    columnsToDisplay = Constants.IC_DETAIL_RESULT_TABLE_COLUMNS;
    expandedElement: ICDetailSummary | null;
    isDetailsExpanded = false;

    sortBy = Constants.SortBy;
    searchRequest: ICSearchRequest = {
        postalCode: '',
        city: '',
        country: '',
        state: '',
        province: '',
        icNumber: '',
        areaManager: '',
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: "city",
        sortBy: "asc"
    };

    constructor() { }

    sortData(sort: Sort) {
        const SortByColumn = sort.active; // This will give the name of the column currently being sorted
        if (this.searchRequest.sortBy === SortByColumn) {
            switch (this.searchRequest.sortDirection) {
                case this.sortBy.ASC:
                    this.searchRequest.sortDirection = this.sortBy.DESC;
                    break;
                case this.sortBy.DESC:
                    this.searchRequest.sortDirection = this.sortBy.ASC;
                    break;
                default: break;
            }
        } else {
            this.searchRequest.sortDirection = this.sortBy.ASC;
        }
        this.searchRequest.sortBy = SortByColumn;

        this.sortingChangeAction.emit(this.searchRequest);
    }

    ngOnInit(): void {
        this.searchRequest.sortBy = this.currentSort.active;
        this.searchRequest.sortDirection = this.currentSort.direction;
    }

}

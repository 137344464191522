import { ToastNotificationMessage } from 'src/app/shared/toastnotification/toastNotificationMessage.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toastnotification',
  templateUrl: './toastnotification.component.html',
  styleUrls: ['./toastnotification.component.scss']
})
export class ToastnotificationComponent {
  private toastMessage: ToastNotificationMessage | null;

  constructor() {
    this.toastMessage = new ToastNotificationMessage();
  }

  @Input()
  set message(message: ToastNotificationMessage | null) {
      this.toastMessage = message;
      setTimeout(() => this.close(), 5000)
  }

  get message(): ToastNotificationMessage | null {
    return this.toastMessage;
  }

  get diagnostic(): any {
    return JSON.stringify(this.toastMessage);
  }

  close(): void {
    this.toastMessage = null;
  }

}

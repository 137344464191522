<div class="d-flex align-items-center justify-content-between" id="navbar">
  <div class="d-flex align-items-center">
    <div class="d-flex">
      <a class="d-flex" (click)="navigateToRootUrl()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="ul-logo" style="vertical-align: initial;">
          <title>UL Logo</title>
          <path
            d="M10,20C4.5,20,0,15.5,0,10C0,4.5,4.5,0,10,0c5.5,0,10,4.5,10,10C20,15.5,15.5,20,10,20 M10,18.1c4.5,0,8.1-3.6,8.1-8.1 c0-4.5-3.6-8.1-8.1-8.1c-4.5,0-8.1,3.6-8.1,8.1C1.9,14.5,5.5,18.1,10,18.1">
          </path>
          <path
            d="M5.1,4.6H7v5.9c0,0.4,0.1,0.7,0.6,0.7c0.5,0,0.6-0.4,0.6-0.8V4.6h1.8v5.7c0,1.3-0.6,2.4-2.5,2.4c-1.6,0-2.5-0.7-2.5-2.4 V4.6">
          </path>
          <polyline points="10.8,15.4 10.8,7.5 12.7,7.5 12.7,13.8 14.9,13.8 14.9,15.4 10.8,15.4"></polyline>
        </svg>
      </a>
    </div>
  </div>
  <div class="heading">
    <h3>{{headerName}}</h3>
  </div>
  <div class="d-flex align-items-center absolute right-5">
      <div class="d-flex">
          <a *ngIf="!loggedIn" class="action-item" (click)="login()">Login</a>
          <a *ngIf="loggedIn" class="action-item" (click)="logout()">Logout</a>
      </div>
      <div class="d-flex align-items-center">
          <a class="mt-1" [href]="helpLink" target="_blank">
              <i class="material-icons inline-mat-icon md-32 action-item mr-2 font-size-30">help_outline</i>
          </a>
      </div>
      <!--<div class="d-flex align-items-center" *ngIf="loggedIn">
          <button class="mt-1" (click)="startTour()">
              <i class="material-icons inline-mat-icon md-32 action-item mr-2 font-size-30">tour</i>
          </button>
      </div>-->
      <span class="icon-divider float-right">&nbsp;</span>
  </div>
  <div class="d-flex action-item">
    <div class="mx-2 d-flex flex-row justify-content-around" *ngIf="!loggedIn">
      <div class="user-info-bg-container d-flex flex-row align-items-center">
        <span class="user-info ml-2"></span>
      </div>
    </div>
    <div class="mx-2 d-flex flex-row justify-content-around ml-3 mr-3" *ngIf="loggedIn">
      <div class="user-info-bg-container d-flex flex-row align-items-center">
        <span *ngIf="loggedIn" class="user-info ml-2" [matTooltip]="userName">{{userInitials}}</span>
      </div>
    </div>
  </div>
</div>
<ng-template #CreateNewMfr>
</ng-template>
<div class="d-flex flex-column mx-3 px-3">
    <div class="d-flex my-3 justify-content-between align-items-center">
        <div class="d-flex">
            <i (click)="onClose()" class="material-icons md-36 action-item mr-2">chevron_left</i>
            <h2 class="capitalize">{{ action }} Manufacturer</h2>
        </div>
    </div>
    <div class="ml-3.5">
        <span class="required-field">* Indicates Required Fields</span>
    </div>
    <div class="my-2 py-1 px-3">
        <ng-form [formGroup]="createEditMfrForm">
            <input type="hidden" formControlName="fileVolRecId" name="fileVolRecId" />
            <div class="form-group row" *ngIf="action === 'Add New'" [hidden]="false">
                <label for="selectMultipleVolume" class="col-sm-4 col-form-label">Add to Selected Volume(s)</label>
                <div class="col-sm-8">
                    <mat-form-field appearance="none">
                        <mat-select formControlName="volumeList" multiple class="form-control" [disabled]="isSelectedVolumeDisabled"
                                    #selectedVolumes>
                            <mat-option *ngFor="let volume of volumeList" [value]="volume">{{volume}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group row" *ngIf="action === 'Add New'" [hidden]="false">
                <label for="isAddToAllVolume" class="col-sm-4 col-form-label">Add to All Active Volume(s)</label>
                <div class="col-sm-8 mt-3">
                    <mat-checkbox (change)="addToAllVolume($event.checked)" class="ng-pristine ng-untouched ng-valid ng-empty form-control-label" name="isAddToAllVolume"
                                  formControlName="isAddToAllVolume">
                    </mat-checkbox>
                </div>
            </div>
            <div class="form-group row" *ngIf="action === 'Add New'" [hidden]="false">
                <label for="sameAsApplicant" class="col-sm-4 col-form-label">Same As Applicant</label>
                <div class="col-sm-8 mt-3">
                    <mat-checkbox [checked]="sameAsApplicantCheckBoxValue" (change)="setAccountDetails($event.checked)" #mfrSameAsApplicant
                                  class="ng-pristine ng-untouched ng-valid ng-empty form-control-label" name="sameAsApplicant">
                    </mat-checkbox>
                </div>
            </div>
            <div class="form-group row">
                <label for="fileNumber" class="col-sm-4 col-form-label">File</label>
                <div class="col-sm-8">
                    <input type="text" name="fileNumber" [disabled]="true" formControlName="fileNumber"
                           id="fileNumber" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="volume" class="col-sm-4 col-form-label">Volume</label>
                <div class="col-sm-8">
                    <input type="text" name="volume" formControlName="volume" id="volume" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="inputAccountNumber" class="col-sm-4 col-form-label">Account Number <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" name="accountNumber" id="inputAccountNumber" required
                           (onBlur)="ownerAccountValidator()" formControlName="mfrAccountNumber" class="form-control"
                           [ngClass]="{'is-invalid': (createEditMfrForm.get('mfrAccountNumber').touched || createEditMfrForm.get('mfrAccountNumber').dirty) && !createEditMfrForm.get('mfrAccountNumber').valid }" />
                    <span class="invalid-feedback">
                        <span *ngIf="createEditMfrForm.get('mfrAccountNumber').errors?.required">
                            Please enter Account Number.
                        </span>
                        <span *ngIf="createEditMfrForm.get('mfrAccountNumber').errors?.invalidOwnerAcount">
                            Please enter a valid account number
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="orgNumber" class="col-sm-4 col-form-label">Org Number</label>
                <div class="col-sm-8">
                    <input type="text" name="orgNumber" formControlName="orgNumber" id="orgNumber" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="mfrPartySiteNumber" class="col-sm-4 col-form-label">Party Site Number <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select class="form-control" formControlName="mfrPartySiteNumber"
                            (change)="changePartySiteNumber($event)" required
                            mfrExists mfrIsWithdrawn [fileVolRecId]="createEditMfrForm.get('fileVolRecId').value" [validateFormControl]="createEditMfrForm.controls['mfrPartySiteNumber']"
                            [ngClass]="{'is-invalid': (createEditMfrForm.get('mfrPartySiteNumber').touched ||
                        createEditMfrForm.get('mfrPartySiteNumber').dirty) && !createEditMfrForm.get('mfrPartySiteNumber').valid }" #partySiteSelectBox>
                        <option [ngValue]="null" [disabled]=true>Choose Party Site Number</option>
                        <option *ngFor="let party of ownerAccountDetails.customerPartySiteDetails" [ngValue]="party.partySiteNumber">
                            {{party.partySiteNumber}}
                        </option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditMfrForm.get('mfrPartySiteNumber').errors?.required">
                            Please select Party site number.
                        </span>
                        <span *ngIf="createEditMfrForm.get('mfrPartySiteNumber').errors?.invalidOwnerAcount">
                            Please select correct customer Party Site Number.
                        </span>
                        <span *ngIf="createEditMfrForm.get('mfrPartySiteNumber').errors?.mfrExists">
                            Entered PartySite number already exists. Please enter different Party Site Number.
                        </span>
                        <span *ngIf="createEditMfrForm.get('mfrPartySiteNumber').errors?.mfrIsWithdrawn">
                            PartySite number already exists in withdrawn state. Please re-activate it.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="mfrSubscriberNumber" class="col-sm-4 col-form-label">Subscriber Number</label>
                <div class="col-sm-8">
                    <input type="text" name="mfrSubscriberNumber" formControlName="mfrSubscriberNumber"
                           id="mfrSubscriberNumber" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="deliverableInstance" class="col-sm-4 col-form-label">Deliverable Item Instance</label>
                <div class="col-sm-8">
                    <div formArrayName="deliverableItemInstance" *ngFor="let item of createEditMfrForm.get('deliverableItemInstance')['controls']; let i = index;" class="row">
                        <div class="col-sm-8" [formGroupName]="i">
                            <input type="text" name="item.{{i}}.deliverableItemInstanceName" formControlName="deliverableItemInstanceName"
                                   id="item.{{i}}" class="form-control" />
                        </div>
                        <div class="col-sm-4 cursor-pointer">
                            <i (click)="addNewDeliverableItem()" class="material-icons md-36 pr-3">
                                add_circle_outline
                            </i>
                            <i (click)="removeDeliverableItem(i)" class="material-icons md-36">
                                remove_circle_outline
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="companyName" class="col-sm-4 col-form-label">Company Name</label>
                <div class="col-sm-8">
                    <input type="text" name="companyName" formControlName="mfrCompanyName" id="companyName" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="companyAddress" class="col-sm-4 col-form-label">Company Address</label>
                <div class="col-sm-8">
                    <textarea type="text" formControlName="mfrCompanyAddress" class="form-control" rows="4">
                    </textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="mfrStartDate" class="col-sm-4 col-form-label">Start Date <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <mat-form-field appearance="none">
                        <input matInput [matDatepicker]="startDatePicker" required [value]="createEditMfrForm.controls.startDate.value"
                               id="mfrStartDate" formControlName="startDate" name="startDate" class="form-date-picker">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditMfrForm.get('startDate').errors?.required">
                            Please enter the start date
                        </span>
                    </span>
                </div>
            </div>
            <!--<div class="form-group row">
        <label for="mfrEndDate" class="col-sm-4 col-form-label">End Date</label>
        <div class="col-sm-8">
            <mat-form-field appearance="none">
                <input matInput [matDatepicker]="endDatePicker"
                       id="mfrEndDate" formControlName="endDate" name="endDate" class="form-date-picker">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>-->
            <div class="form-group row">
                <label for="wdrwlCode" class="col-sm-4 col-form-label">Withdrawal Code</label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="wdrwlCode"
                            name="wdrwlCode" formControlName="wdrwlCode">
                        <option [value]="option.wdrwlCode" *ngFor="let option of wdrwlCodes">{{ option.wdrwlCode }}</option>
                    </select>
                </div>
            </div>
            <!--<div class="form-group row">
        <label for="serviceContract" class="col-sm-4 col-form-label">Service Contract</label>
        <div class="col-sm-8">
            <input type="text" name="serviceContract" formControlName="serviceContract" class="form-control"
                   id="serviceContract" />
        </div>
    </div>-->
            <div class="form-group row">
                <label for="mfrType" class="col-sm-4 col-form-label">Type <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select matInput class="form-control" id="mfrType"
                            name="mfrType" formControlName="mfrType" required
                            [ngClass]="{'is-invalid': (createEditMfrForm.get('mfrType').touched ||
                        createEditMfrForm.get('mfrType').dirty) && !createEditMfrForm.get('mfrType').valid }">
                        <option [value]="null" [disabled]=true>Choose Manufacturer Type</option>
                        <option *ngFor="let option of mfrTypes" [value]="option.value">{{ option.key }}</option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditMfrForm.get('mfrType').errors?.required">
                            Please select the Manufacturer type
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="mfrFactoryId" class="col-sm-4 col-form-label">Factory ID</label>
                <div class="col-sm-8 mt-3">
                    <input type="text" name="mfrFactoryId" formControlName="mfrFactoryId" class="form-control"
                           id="mfrFactoryId" />
                </div>
            </div>
            <div class="d-flex flex-row my-3">
                <button type="submit" class="btn btn-copy" (click)="onSubmit(createEditMfrForm.value)" [disabled]="!createEditMfrForm.valid"
                        [ngClass]="{'cursor-not-allowed': !createEditMfrForm.valid}">
                    Save
                </button>
                <button type="reset" class="btn btn-outline-secondary ml-3" (click)="onClose()">
                    Cancel
                </button>
            </div>
        </ng-form>
    </div>
</div>
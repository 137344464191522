import {
    AfterViewInit, Component, ComponentFactoryResolver, ComponentRef,
    OnInit, ViewChild, ViewContainerRef
} from '@angular/core';
import { Constants } from '../../constants';
import { DataService } from '../../core/services/data.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ProductCcnSearchRequest, ProductSearchResultModel, DeleteEventModel } from '../product-ccn.model';
import { ProductCcnService } from '../product-ccn.service';
import { CreateEditProductCcnModel } from '../create-product-ccn/create-product-ccn.model';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportConfirmDialogComponent } from '../../shared/export-confirm-dialog/export-confirm-dialog.component';
import * as FileSaver from 'file-saver';
import { NgForm } from '@angular/forms';
import { ImportFileComponent } from '../../shared/import-file/import-file.component';

@Component({
    selector: 'app-product-ccn-search',
    templateUrl: './product-ccn-search.component.html',
    styleUrls: ['./product-ccn-search.component.scss']
})
export class ProductCcnSearchComponent implements OnInit, AfterViewInit {
    @ViewChild('productCcnContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public pageName = '';
    levelOneURL = '';
    activeLevel = 0;
    isSearch = false;
    sortOptions = Constants.PRODUCT_SEARCH_SORT_OPTIONS;
    searchResultCount = 0;
    hasSearchActionOccurred = false;
    noSearchResults = false;
    productSearchModel: ProductCcnSearchRequest = {
        productName: '',
        productCcn: '',
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0],
        filterBy: Constants.ALL,
    };
    searchResult: ProductSearchResultModel;
    isTableView = false;
    isAdmin: boolean;
    refreshSearchResultSubscription: Subscription;
    currentSort = {
        active: '',
        direction: ''
    };

    constructor(
        private dataService: DataService,
        private loadingSpinnerService: LoadingSpinnerService,
        private toastService: ToastNotificationService,
        private resolver: ComponentFactoryResolver,
        private searchService: ProductCcnService,
        private sideNavService: SideNavService,
        private modalService: NgbModal
    ) {
        this.refreshSearchResultSubscription = this.searchService.refreshSearchResult$.subscribe(res => {
            // Fetch the data again
            if (this.dataService.isEmpty(this.productSearchModel)) {
                this.getSearchResult(this.productSearchModel);
            }
        });
    }

    onViewChange = (sortingEvent: Paginator) => {
            this.isTableView = sortingEvent.isTableView;
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        this.productSearchModel.filterBy = Constants.ALL;
        this.productSearchModel.pageNumber = sortingEvent.page;
        this.productSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.productSearchModel);
    }

    onSortByChange = (sortingEvent: Paginator) => {
        this.productSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.productSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];

        this.currentSort.active = this.productSearchModel.sortBy;
        this.currentSort.direction = this.productSearchModel.sortDirection;

        this.getSearchResult(this.productSearchModel);
    }

    /**
    * To sort the values based
    * on the sort option chosen
    * in the table headers
    * @param sortingEvent
    */
    onSortingChange = (sortingEvent: ProductCcnSearchRequest) => {
        this.productSearchModel.sortBy = sortingEvent.sortBy;
        this.productSearchModel.sortDirection = sortingEvent.sortDirection;

        this.getSearchResult(this.productSearchModel);
    }

    onSearch = (formValues) => {
        this.productSearchModel.pageNumber = 1;
        this.productSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
        this.productSearchModel.sortDirection = this.sortOptions[0].value.split('-')[1];
        this.productSearchModel.sortBy = this.sortOptions[0].value.split('-')[0];
        this.productSearchModel.filterBy = Constants.ALL;
        this.getSearchResult(formValues);
    }

    onDelete = (deleteEvent: DeleteEventModel) => {
        document.querySelector('mat-sidenav-content').scrollTop = 0;
        if (deleteEvent.value) {
            const successMessage = `The product was deleted successfully`;
            const type = ToastNotificationType.success;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(successMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.getSearchResult(this.productSearchModel);
        }
        else {
            const errorMessage = `Error while trying to delete the product. Please try again later`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    addProduct() {
        const model: CreateEditProductCcnModel = {
            productCcnRecId: 0,
            productCcn: null,
            productType: null,
            deliverable: null,
            deliverableType: null,
            industry: null,
            typeOfService: null,
            fusSampleLines: null,
            isML: 'N',
            invoiceText: null,
            subIndustry: null
        };
        this.sideNavService.createEditProductCcn(model);
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            this.entry.clear();
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.dataService.setOption(Constants.PRODUCT_SEARCH_REQUEST_OBJECT, formValues);
            this.searchService.getSearchResult(this.productSearchModel).subscribe((searchResult) => {
                this.searchResult = searchResult;
                this.searchResultCount = searchResult.paging.totalRecords;
                this.currentSort.active = this.productSearchModel.sortBy;
                this.currentSort.direction = this.productSearchModel.sortDirection;
                componentRef.destroy();
                if (this.searchResultCount === 0) {
                    this.isSearch = false;
                    const errorMessage = `No Data found for the specified search criteria`;
                    const type = ToastNotificationType.infoBlue;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.dataService.navigateToPageTop();
                    return;
                }
                this.hasSearchActionOccurred = true;
                this.noSearchResults = false;
                this.isSearch = true;
            },
                error => {
                    componentRef.destroy();
                    const errorMessage = `Error while retrieving the data`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.searchResultCount = 0;
                    this.isSearch = false;
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.isTableView = false;
                    this.dataService.navigateToPageTop();
                });
        }
        else {
            const errorMessage = `Please enter the search criteria in the form below`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.searchResultCount = 0;
            this.isSearch = false;
            this.hasSearchActionOccurred = false;
            this.noSearchResults = false;
            this.isTableView = false;
            this.dataService.navigateToPageTop();
        }
    };

    resetForm(productCcnSearchForm: NgForm): void {
        // This will clear the previous search request data
        productCcnSearchForm.resetForm({
            productName: '',
            productCcn: '',
            exactSearch: true,
        });
        this.isSearch = false;
        this.searchResult.results = [];
        this.dataService.setOption(Constants.PRODUCT_SEARCH_REQUEST_OBJECT, {});
    }

    exportToExcel() {
        const fileName = `ProductCCN-${this.dataService.customDateFormat(null)}.xlsx`;
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ExportConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    if (result.selectedValue === 'true') {
                        this.productSearchModel.pageNumber = 1;
                        this.productSearchModel.pageSize = this.searchResultCount;
                    }
                    this.searchService.exportToExcel(this.productSearchModel).subscribe((object: Blob) => {
                        if (object.size && object.size > 0) {
                            FileSaver.saveAs(object, fileName);
                        }
                        else {
                            const errorMessage = `Error while trying to export the data to excel`;
                            const type = ToastNotificationType.error;
                            const placement = ToastNotificationPlacement.Body;
                            const message = new ToastNotificationMessage(errorMessage, type, placement);
                            this.toastService.sendToastNotificationMessage(message);
                        }
                        componentRef.destroy();
                    },
                        error => {
                            componentRef.destroy();
                            const errorMessage = `Error while trying to export the data to excel`;
                            const type = ToastNotificationType.error;
                            const placement = ToastNotificationPlacement.Body;
                            const message = new ToastNotificationMessage(errorMessage, type, placement);
                            this.toastService.sendToastNotificationMessage(message);
                        });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy();
                }
            });
    }

    exportAllData() {
        const fileName = `ProductCCN-${this.dataService.customDateFormat(null)}.xlsx`;
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.searchService.exportAllData().subscribe((object: Blob) => {
            if (object.size && object.size > 0) {
                FileSaver.saveAs(object, fileName);
            }
            else {
                const errorMessage = `Error while trying to export all product data to excel`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            }
            componentRef.destroy();
        },
            error => {
                componentRef.destroy();
                const errorMessage = `Error while trying to export all product data to excel`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
    }

    ngOnInit(): void {
        this.pageName = `Product Type CCN Mapping`;
        this.activeLevel = 1;
        this.levelOneURL = '';
        const obj = this.dataService.getOption(Constants.PRODUCT_SEARCH_REQUEST_OBJECT)
        if (this.dataService.getOption(Constants.PRODUCT_SEARCH_REQUEST_OBJECT)
                && Object.keys(obj).length > 0) {
            this.productSearchModel = this.dataService.getOption(
                Constants.PRODUCT_SEARCH_REQUEST_OBJECT
            );
            if (this.dataService.isEmpty(this.productSearchModel)) {
                this.onSearch(this.productSearchModel);
            }
        }
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
    }

    uploadExcelFile() {
        const dialogRef = this.modalService.open(ImportFileComponent, { windowClass: 'modal-zindex' });
    }

    ngAfterViewInit() {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    ngOnDestroy(): void {
        if (this.refreshSearchResultSubscription) {
            this.refreshSearchResultSubscription.unsubscribe();
        }
    }
}

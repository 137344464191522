import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private loadingSpinnerService: LoadingSpinnerService) { }

  ngOnInit(): void {
    this.loadingSpinnerService.removeLoadingSpinner();
  }

}

<div class="modal-header">
    <h2 class="modal-title">{{title}}</h2>
</div>
<div class="modal-body">
    <div class="font-bold mt-4 mb-4 error-message">
        <div> {{errorMessage}}</div>
    </div>
    <div class="mt-4 row">
        <div class="col-md-9">
            <input class="form-control" type="file" #excelInputFile />
        </div>
        <div class="col-md-3">
            <button class="btn btn-primary" (click)="uploadFile()">Upload</button>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="button button-white w-28 float-right m-1" (click)="onCancel()">Cancel</button>
</div>
<ng-template #uploadContainer>
</ng-template>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateFileVolumeComponent } from './create-file-volume/create-file-volume.component';
import { FileVolumeSearchComponent } from './file-volume-search/file-volume-search.component';
import { FileVolumeSearchResultCardComponent } from './file-volume-search-result-card/file-volume-search-result-card.component';
import { VolumeExistsDirective } from './create-file-volume/validation/volume-exists.directive';
import { FileVolumeComponent } from './file-volume/file-volume.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from '../app-routing.module';
import { PaginatorComponent } from '../shared/paginator/paginator.component';
import { UppercaseDirectiveModule } from '../shared/uppercase.directive';
import { UtilityModule } from '../utility';
import { FileVolumeDetailViewComponent } from './file-volume-detail-view/file-volume-detail-view.component';

@NgModule({
    declarations: [
        CreateFileVolumeComponent,
        FileVolumeSearchComponent,
        FileVolumeSearchResultCardComponent,
        FileVolumeComponent,
        VolumeExistsDirective,
        FileVolumeDetailViewComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FontAwesomeModule,
        AppRoutingModule,
        UtilityModule,
        UppercaseDirectiveModule
    ],
    exports: [
        CreateFileVolumeComponent,
        FileVolumeSearchComponent,
        FileVolumeSearchResultCardComponent,
    ],
})
export class FileVolumeModule {}

<ng-template #CreateNewListee></ng-template>
<div class="d-flex flex-column mx-3 px-3">
    <div class="d-flex my-3 justify-content-between align-items-center">
        <div class="d-flex">
            <i (click)="onClose()" class="material-icons md-36 action-item mr-2">chevron_left</i>
            <h2 class="capitalize">{{ action }} Listee</h2>
        </div>
    </div>
    <div class="ml-3.5">
        <span class="required-field">* Indicates Required Fields</span>
    </div>
    <div class="my-2 py-1 px-3">
        <ng-form [formGroup]="createEditListeeForm">
            <div class="form-group row" *ngIf="action === 'Add New'" [hidden]="false">
                <label for="isAddToAllVolume" class="col-sm-4 col-form-label">Add to All Active Volume(s)</label>
                <div class="col-sm-8 mt-3">
                    <mat-checkbox (change)="addToAllVolume($event.checked)" class="ng-pristine ng-untouched ng-valid ng-empty form-control-label" 
                                  name="isAddToAllVolume" formControlName="isAddToAllVolume">
                    </mat-checkbox>
                </div>
            </div>
            <div class="form-group row" [hidden]="true">
                <label for="sameAsApplicant" class="col-sm-4 col-form-label">Same As Applicant</label>
                <div class="col-sm-8 mt-3">
                    <mat-checkbox [checked]="sameAsApplicantCheckBoxValue" class="ng-pristine ng-untouched ng-valid ng-empty form-control-label"
                                  name="sameAsApplicant" [disabled]="isCheckboxDisabled" (change)="setAccountDetails($event)" #sameAsApplicant>
                    </mat-checkbox>
                </div>
            </div>
            <div class="form-group row">
                <label for="associationType" class="col-sm-4 col-form-label">Listee Type <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select class="form-control" name="associationType" formControlName="associationType" required id="associationType" (change)="onListeeTypeChange($event)"
                            [ngClass]="{'is-invalid': (createEditListeeForm.get('associationType').touched || createEditListeeForm.get('associationType').dirty) && !createEditListeeForm.get('associationType').valid }">
                        <option [value]="null" [disabled]="true">Choose Listee Type</option>
                        <option class="cssDisable" *ngFor="let obj of listeeTypeOptions" [disabled]="obj.Disabled" [value]="obj.Value">{{obj.Option}}</option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditListeeForm.get('associationType').errors?.required">
                            Please select Listee Type.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="listeeFileNo" class="col-sm-4 col-form-label">Listee File <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" name="listeeFileNumber" required formControlName="listeeFileNumber" id="listeeFileNo" class="form-control"
                           [ngClass]="{'is-invalid': (createEditListeeForm.get('listeeFileNumber').touched || createEditListeeForm.get('listeeFileNumber').dirty) && !createEditListeeForm.get('listeeFileNumber').valid }" />
                    <span class="invalid-feedback">
                        <span *ngIf="createEditListeeForm.get('listeeFileNumber').errors?.required">
                            Please enter Listee File Number.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputAccountNumber" class="col-sm-4 col-form-label">Account Number <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" name="accountNumber" id="inputAccountNumber" required (onBlur)="ownerAccountValidator()" formControlName="accountNumber" class="form-control"
                           [ngClass]="{'is-invalid': (createEditListeeForm.get('accountNumber').touched || createEditListeeForm.get('accountNumber').dirty) && !createEditListeeForm.get('accountNumber').valid }" />
                    <span class="invalid-feedback">
                        <span *ngIf="createEditListeeForm.get('accountNumber').errors?.required">
                            Please enter Account Number.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="orgNumber" class="col-sm-4 col-form-label">Org Number</label>
                <div class="col-sm-8">
                    <input type="text" formControlName="orgNumber" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="listeePartySiteNumber" class="col-sm-4 col-form-label">Party Site Number <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select class="form-control" formControlName="listeePartySiteNumber" (change)="changePartySiteNumber($event)" required
                            [ngClass]="{'is-invalid': (createEditListeeForm.get('listeePartySiteNumber').touched || createEditListeeForm.get('listeePartySiteNumber').dirty) && !createEditListeeForm.get('listeePartySiteNumber').valid }">
                        <option [ngValue]="null" [disabled]=true [selected]="true">Choose Party Site Number</option>
                        <option *ngFor="let party of ownerAccountDetails.customerPartySiteDetails" [ngValue]="party.partySiteNumber">
                            {{party.partySiteNumber}}
                        </option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditListeeForm.get('listeePartySiteNumber').errors?.required">
                            Please select Party site number.
                        </span>
                        <span *ngIf="createEditListeeForm.get('listeePartySiteNumber').errors?.invalidOwnerAcount">
                            Please select correct customer Party Site Number.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="subscriberNumber" class="col-sm-4 col-form-label">Subscriber Number</label>
                <div class="col-sm-8">
                    <input type="text" formControlName="subscriberNumber" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="companyName" class="col-sm-4 col-form-label">Company Name</label>
                <div class="col-sm-8">
                    <input type="text" name="companyName" formControlName="companyName" id="companyName" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="companyAddress" class="col-sm-4 col-form-label">Company Address</label>
                <div class="col-sm-8">
                    <textarea type="text" formControlName="companyAddress" class="form-control" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="startDate" class="col-sm-4 col-form-label">Start Date <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <mat-form-field appearance="none">
                        <input matInput [matDatepicker]="startDatePicker" required [value]="createEditListeeForm.controls.startDate.value"
                               id="listeeStartDate" formControlName="startDate" name="startDate" class="form-date-picker">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <!--<div class="form-group row">
                <label for="endDate" class="col-sm-4 col-form-label">End Date</label>
                <div class="col-sm-8">
                    <mat-form-field appearance="none">
                        <input matInput [matDatepicker]="endDatePicker"
                               id="listeeEndDate" formControlName="endDate" name="endDate" class="form-date-picker">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>-->
            <div class="d-flex flex-row my-3">
                <button type="submit" class="btn btn-copy" (click)="onSubmit(createEditListeeForm.value)" [disabled]="!createEditListeeForm.valid || isSubmitDisabled"
                        [ngClass]="{'cursor-not-allowed': !createEditListeeForm.valid}">Save</button>
                <button type="reset" class="btn btn-outline-secondary ml-3" (click)="onClose()">Cancel</button>
            </div>
        </ng-form>
    </div>
</div>
<ng-template #listeeSearchContainer></ng-template>
<div *ngIf="!isTableView">
    <div *ngFor="let model of searchDataResults; let i = index;">
        <div class="search-result-card flex flex-grow py-2 mt-2"
             [ngClass]="{'card-active': model.listeeStatus == 'Y', 'card-inactive': model.listeeStatus == 'N'}">
            <div class="flex flex-col flex-grow">
                <div class="flex flex-row items-center px-2">
                    <div class="flex my-2 flex-wrap">
                        <ng-container *ngIf="model.listeeFileNumber">
                            <span class="font-bold">Listee File:&nbsp;</span>
                            <span>{{ model.listeeFileNumber }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.fileCcn">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">CCN:&nbsp;</span>
                            <span>{{ model.fileCcn }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyName">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">ListeeCompany Name:&nbsp;</span>
                            <span>{{ model.ownerCompanyName }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.listeeAssociationType">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Association Type:&nbsp;</span>
                            <span>{{ model.listeeAssociationType }}</span>
                        </ng-container>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="flex flex-row mt-2 file-details-body p-2 flex-wrap">
                    <ng-container *ngIf="model.fileCompanyName">
                        <span class="font-bold">Applicant Company Name:&nbsp;</span>
                        <span>{{ model.fileCompanyName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerAccount">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Applicant Account Number:&nbsp;</span>
                        <span>{{model.ownerAccount}}</span>
                    </ng-container>
                    <ng-container *ngIf="model.filePartyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Applicant Org Number:&nbsp;</span>
                        <span>{{ model.filePartyNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.filePartySiteNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Applicant Partysite No:&nbsp;</span>
                        <span>{{model.filePartySiteNumber}}</span>
                    </ng-container>
                    <ng-container *ngIf="model.fileCountryCode">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Applicant CountryCode:&nbsp;</span>
                        <span>{{model.fileCountryCode}}</span>
                    </ng-container>
                </div>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                    <ng-container>
                        <button (click)="indexExpanded = (indexExpanded == i?-1:i);" color="primary" class="text-black">
                            <fa-icon *ngIf="indexExpanded == i" icon="caret-down" class="pr-2 text-xl"></fa-icon>
                            <fa-icon *ngIf="indexExpanded != i" icon="caret-right" class="pr-2 text-xl"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="model.basicFileNumber">
                        <span class="font-bold">Basic File:&nbsp;</span>
                        <span>{{ model.basicFileNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.volume">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Vol:&nbsp;</span>
                        <span>{{ model.volume }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.complementaryCCN">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Comp CCN:&nbsp;</span>
                        <span>{{ formatCompCCN(model.complementaryCCN) }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.listeeOwnerAccount">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Listee Account No:&nbsp;</span>
                        <span>{{ model.listeeOwnerAccount }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.listeePartyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Listee Org No:&nbsp;</span>
                        <span>{{ model.listeePartyNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.listeePartySiteNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Listee Partysite No:&nbsp;</span>
                        <span>{{ model.listeePartySiteNumber }}</span>
                    </ng-container>
                </div>
                <mat-divider *ngIf="indexExpanded == i"></mat-divider>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="indexExpanded == i">
                    <ng-container *ngIf="model.serviceContract">
                        <div class="font-bold">Service Contract#:&nbsp;</div>
                        <span>{{ model.serviceContract }}</span>
                        <span class="field-divider mx-2">&nbsp;</span>
                    </ng-container>
                    <ng-container *ngIf="model.listeeSubscriberNumber">
                        <span class="font-bold">Subscriber Number:&nbsp;</span>
                        <span>{{ model.listeeSubscriberNumber }}</span>
                        <span class="field-divider mx-2">&nbsp;</span>
                    </ng-container>
                    <ng-container *ngIf="model.startDate">
                        <div class="font-bold">Start Date:&nbsp;</div>
                        <span>{{ formatDate(model.startDate) }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.endDate">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <div class="font-bold">End Date:&nbsp;</div>
                        <span>{{ formatDate(model.endDate) }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.wdrwlCode">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <div class="font-bold">Withdrawal Code:&nbsp;</div>
                        <span>{{ model.wdrwlCode }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.lastUpdatedBy">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Last Updated By:&nbsp;</span>
                        <span>{{ model.lastUpdatedBy }}</span>
                    </ng-container>
                </div>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="indexExpanded == i">
                    <ng-container>
                        <span class="font-bold">Listee Company Address:&nbsp;</span>
                    </ng-container>
                    <div class="flex flex-column file-details-body flex-wrap">
                        <ng-container *ngIf="model.ownerCompanyAddress1">
                            <div>{{ model.ownerCompanyAddress1 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress2">
                            <div>{{ model.ownerCompanyAddress2 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress3">
                            <div>{{ model.ownerCompanyAddress3 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress4">
                            <div>{{ model.ownerCompanyAddress4 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress5">
                            <div>{{ model.ownerCompanyAddress5 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress6">
                            <div>{{ model.ownerCompanyAddress6 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCity">
                            <div>{{ model.ownerCity }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCity">
                            <div>{{ model.ownerProvince }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerState">
                            <div>{{ model.ownerState }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCountry">
                            <div>{{ model.ownerCountry }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerPostalCode">
                            <div>{{ model.ownerPostalCode }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="flex flex-col flex-grow-0 justify-start" *ngIf="isAdmin || isEdit">
                <div class="flex flex-row items-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item class="additional-menu-item"
                                (click)="updateListee(model)"
                                *ngIf="isAdmin || isEdit">
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="reactivateListee(model.basicFileVolRecId, model.listeeAssoRecId, model.listeeAssociationType)"
                                [disabled]="model.listeeStatus == 'Y'" *ngIf="isAdmin || isEdit">
                            <mat-icon>replay</mat-icon>
                            <span>Re-Activate</span>
                        </button>
                        <button mat-menu-item (click)="withdrawListee(model.listeeAssoRecId, model.basicFileVolRecId)"
                                [disabled]="model.listeeStatus == 'N'" *ngIf="isAdmin || isEdit">
                            <mat-icon>archive</mat-icon>
                            <span>Withdraw</span>
                        </button>
                        <button mat-menu-item (click)="deleteListee(model.listeeAssoRecId, model.basicFileVolRecId)"
                                *ngIf="isAdmin">
                            <mat-icon>delete_forever</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-elevation-z2 flex flex-grow mt-2" *ngIf="isTableView">
    <table mat-table
           [dataSource]="tableDataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)"
           [matSortDisableClear]="true" [matSortActive]="searchRequest.sortBy" [matSortDirection]="searchRequest.sortDirection">

        <ng-container matColumnDef="listeeStatus">
            <th mat-header-cell *matHeaderCellDef class="table-header">Status</th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                <span *ngIf="element.listeeStatus == 'Y'" class="active-circle"></span><span *ngIf="element.listeeStatus == 'Y'"> Active</span>
                <span *ngIf="element.listeeStatus == 'N'" class="inactive-circle"></span><span *ngIf="element.listeeStatus == 'N'"> Withdrawn</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="basicFileNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Basic File</th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.basicFileNumber}}
            </td>
        </ng-container>
        <ng-container matColumnDef="listeeFileNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Listee File </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.listeeFileNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="fileCcn">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> CCN </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.fileCcn}}
            </td>
        </ng-container>

        <ng-container matColumnDef="volume">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Volume </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.volume}}
            </td>
        </ng-container>

        <ng-container matColumnDef="ownerCompanyName">
            <th mat-header-cell *matHeaderCellDef class="table-header">Listee Company Name </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.ownerCompanyName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="listeeAssociationType">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Association Type </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.listeeAssociationType}}
            </td>
        </ng-container>

        <ng-container matColumnDef="listeeOwnerAccount">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Listee Account No </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.listeeOwnerAccount}}
            </td>
        </ng-container>

        <ng-container matColumnDef="listeePartySiteNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Listee PartySite No </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.listeePartySiteNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="listeeSubscriberNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Subscriber Number </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.listeeSubscriberNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef class="table-header"> Options </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                        *ngIf="isAdmin || isEdit">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="additional-menu-item"
                            (click)="updateListee(element)"
                            *ngIf="isAdmin || isEdit">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="reactivateListee(element.basicFileVolRecId, element.listeeAssoRecId, element.listeeAssociationType)"
                            [disabled]="element.listeeStatus == 'Y'" *ngIf="isAdmin || isEdit">
                        <mat-icon>replay</mat-icon>
                        <span>Re-Activate</span>
                    </button>
                    <button mat-menu-item (click)="withdrawListee(element.listeeAssoRecId, element.basicFileVolRecId)"
                            [disabled]="element.listeeStatus == 'N'" *ngIf="isAdmin || isEdit">
                        <mat-icon>archive</mat-icon>
                        <span>Withdraw</span>
                    </button>
                    <button mat-menu-item (click)="deleteListee(element.listeeAssoRecId, element.basicFileVolRecId)"
                            *ngIf="isAdmin">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="table-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="pl-4">
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container *ngIf="element.fileCompanyName">
                                <span class="font-bold">Applicant Company Name:&nbsp;</span>
                                <span>{{ element.fileCompanyName }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.ownerAccount">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Applicant Account Number:&nbsp;</span>
                                <span>{{element.ownerAccount}}</span>
                            </ng-container>
                            <ng-container *ngIf="element.filePartyNumber">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Applicant Org Number:&nbsp;</span>
                                <span>{{ element.filePartyNumber }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.filePartySiteNumber">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Applicant Partysite No:&nbsp;</span>
                                <span>{{element.filePartySiteNumber}}</span>
                            </ng-container>
                            <ng-container *ngIf="element.fileCountryCode">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Applicant CountryCode:&nbsp;</span>
                                <span>{{element.fileCountryCode}}</span>
                            </ng-container>
                        </div>
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container *ngIf="element.complementaryCCN">
                                <span class="font-bold">Comp CCN:&nbsp;</span>
                                <span>{{ formatCompCCN(element.complementaryCCN) }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.listeePartyNumber">
                                <span class="font-bold">Listee Org No:&nbsp;</span>
                                <span>{{ element.listeePartyNumber }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.startDate">
                                <div class="font-bold">Start Date: &nbsp;</div>
                                <span>{{ formatDate(element.startDate) }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.endDate">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <div class="font-bold">End Date: &nbsp;</div>
                                <span>{{ formatDate(element.endDate) }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.wdrwlCode">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <div class="font-bold">Withdrawal Code:&nbsp;</div>
                                <span>{{ element.wdrwlCode }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.serviceContract">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <div class="font-bold">Service Contract#: &nbsp;</div>
                                <span>{{ element.serviceContract }}</span>
                            </ng-container>
                        </div>
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container *ngIf="element.lastUpdatedBy">
                                <span class="font-bold">Last Updated By: &nbsp;</span>
                                <span>{{ element.lastUpdatedBy }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container>
                                <div class="font-bold">Company Address:&nbsp;</div>
                            </ng-container>
                            <div class="flex flex-column file-details-body flex-wrap">
                                <ng-container *ngIf="element.ownerCompanyAddress1">
                                    <div>{{ element.ownerCompanyAddress1 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress2">
                                    <div>{{ element.ownerCompanyAddress2 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress3">
                                    <div>{{ element.ownerCompanyAddress3 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress4">
                                    <div>{{ element.ownerCompanyAddress4 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress5">
                                    <div>{{ element.ownerCompanyAddress5 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress6">
                                    <div>{{ element.ownerCompanyAddress6 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCity">
                                    <div>{{ element.ownerCity }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCity">
                                    <div>{{ element.ownerProvince }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerState">
                                    <div>{{ element.ownerState }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCountry">
                                    <div>{{ element.ownerCountry }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerPostalCode">
                                    <div>{{ element.ownerPostalCode }}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="table-element-row"
            [class.table-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
</div>

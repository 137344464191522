import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from '../app-routing.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [HeaderComponent, FooterComponent],
    imports: [CommonModule, AppRoutingModule, MatTooltipModule],
    exports: [HeaderComponent, FooterComponent],
})
export class LayoutModule {}

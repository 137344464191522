<div *ngIf="!isTableView">
    <div class="search-result-card flex flex-grow py-2 mt-2"
         [ngClass]="{'card-active': model.status == 'A', 'card-inactive': model.status == 'W'}">
        <div class="flex flex-col flex-grow">
            <div class="flex flex-row items-center px-2">
                <div class="flex my-2 flex-wrap">
                    <ng-container *ngIf="model.ownerCompanyName">
                        <span class="font-bold">Applicant - Customer Name:&nbsp;</span>
                        <span>{{ model.ownerCompanyName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerAccount">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Account Number:&nbsp;</span>
                        <span>{{ model.ownerAccount }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerPartySiteNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">PartySiteNumber:&nbsp;</span>
                        <span>{{ model.ownerPartySiteNumber }}</span>
                    </ng-container>
                    <!-- <ng-container >
                        <span *ngIf="model.ownerCompanyName" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.ownerCompanyName" class="font-bold">Applicant&nbsp;</span>
                        <span *ngIf="model.ownerCompanyName" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.ownerCompanyName" class="font-bold">Listee&nbsp;</span>
                        <span *ngIf="model.mfrCompanyName" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.mfrCompanyName" class="font-bold">Manufacturer</span>
                        
                    </ng-container> -->
                    <!-- <ng-container *ngIf="model.ownerCompanyName">
                        <span class="font-bold">Applicant Customer Name:&nbsp;</span>
                        <span>{{ model.ownerCompanyName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfrCompanyName">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Listee Customer Name:&nbsp;</span>
                        <span>{{ model.mfrCompanyName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfrCompanyName">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Manufacturer Customer Name:&nbsp;</span>
                        <span>{{ model.mfrCompanyName }}</span>
                    </ng-container> -->
                  
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="flex flex-row items-center px-2">
                <div class="flex my-2 flex-wrap">
                    <ng-container *ngIf="model.mfrCompanyName">
                        <span class="font-bold">Listee -  Customer Name:&nbsp;</span>
                        <span>{{ model.mfrCompanyName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.listeeAccount">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Account Number:&nbsp;</span>
                        <span>{{ model.listeeAccount }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.listeePartyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">PartySiteNumber:&nbsp;</span>
                        <span>{{ model.listeePartyNumber }}</span>
                    </ng-container>
                    <!-- <ng-container *ngIf="model.ownerAccount">
                        <span class="font-bold">Account Number:&nbsp;</span>
                        <span>{{ model.ownerAccount }}</span>
                    </ng-container> -->
                    <!-- <ng-container >
                        <span *ngIf="model.ownerAccount" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.ownerAccount" class="font-bold">Applicant&nbsp;</span>
                        <span *ngIf="model.listeeAccount" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.listeeAccount" class="font-bold">Listee&nbsp;</span>
                        <span *ngIf="model.mfrAccount" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.mfrAccount" class="font-bold">Manufacturer</span>
                        
                    </ng-container> -->
                    <!-- <ng-container *ngIf="model.listeeAccount">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Listee Account Number:&nbsp;</span>
                        <span>{{ model.listeeAccount }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfrAccount">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Manufacturer Account Number:&nbsp;</span>
                        <span>{{ model.mfrAccount }}</span>
                    </ng-container> -->
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="flex flex-row items-center px-2">
                <div class="flex my-2 flex-wrap">
                    <ng-container *ngIf="model.mfrCompanyName">
                        <span class="font-bold">Manufacturer - Customer Name:&nbsp;</span>
                        <span>{{ model.mfrCompanyName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfrAccount">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Account Number:&nbsp;</span>
                        <span>{{ model.mfrAccount }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfrPartyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">PartySiteNumber:&nbsp;</span>
                        <span>{{ model.mfrPartyNumber }}</span>
                    </ng-container>
                    <!-- <ng-container *ngIf="model.ownerPartySiteNumber">
                        <span class="font-bold">PartySiteNumber:&nbsp;</span>
                        <span>{{ model.ownerPartySiteNumber }}</span>
                    </ng-container> -->
                    <!-- <ng-container >
                        <span *ngIf="model.ownerPartySiteNumber" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.ownerPartySiteNumber" class="font-bold">Applicant&nbsp;</span>
                        <span *ngIf="model.listeePartyNumber" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.listeePartyNumber" class="font-bold">Listee&nbsp;</span>
                        <span *ngIf="model.mfrPartyNumber" class="field-divider mx-2">&nbsp;</span>
                        <span *ngIf="model.mfrPartyNumber" class="font-bold">Manufacturer</span>
                        
                    </ng-container>  -->
                    <!-- <ng-container *ngIf="model.listeePartyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Listee PartySiteNumber:&nbsp;</span>
                        <span>{{ model.listeePartyNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfrPartyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Manufacturer PartySiteNumber:&nbsp;</span>
                        <span>{{ model.mfrPartyNumber }}</span>
                    </ng-container> -->
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                
                <ng-container>
                    <button (click)="isCustomerExpanded = !isCustomerExpanded" color="primary" class="text-black">
                        <fa-icon *ngIf="isCustomerExpanded" icon="caret-down" class="pr-2 text-center"></fa-icon>
                        <fa-icon *ngIf="!isCustomerExpanded" icon="caret-right" class="pr-2 text-center"></fa-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="model.basicCcn">
                    <span class="font-bold"> CCN:&nbsp;</span>
                    <span>{{ model.basicCcn }}</span>
                </ng-container>
                <ng-container *ngIf="model.complementaryCCN ">
                    <span class="field-divider mx-2">&nbsp;</span>
                    <span class="font-bold"> Comp CCN:&nbsp;</span>
                    <ng-container *ngFor="let compCCN of model.complementaryCCN;let indexOf=index;">
                        <span *ngIf="indexOf !== 0">{{ ',' }}</span>
                        <span>{{ compCCN.compCCN }}</span>
                    </ng-container>
                </ng-container>
            </div>
            <mat-divider *ngIf="isCustomerExpanded"></mat-divider>
            <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="isCustomerExpanded">
                <ng-container *ngIf="model.ownerCompanyAddress1">
                    <span class="font-bold">Company Address:&nbsp;</span>
                </ng-container>
                <div class="flex flex-column file-details-body flex-wrap">
                    <ng-container *ngIf="model.ownerCompanyAddress1">
                        <div>{{ model.ownerCompanyAddress1 }}</div>
                    </ng-container>
                    <ng-container *ngIf="model.ownerCompanyAddress2">
                        <div>{{ model.ownerCompanyAddress2 }}</div>
                    </ng-container>
                    <ng-container *ngIf="model.ownerCompanyAddress3">
                        <div>{{ model.ownerCompanyAddress3 }}</div>
                    </ng-container>
                    <ng-container *ngIf="model.ownerCompanyAddress4">
                        <div>{{ model.ownerCompanyAddress4 }}</div>
                    </ng-container>
                    <ng-container *ngIf="model.ownerCity">
                        <div>{{ model.ownerCity }}</div>
                    </ng-container>
                    <ng-container *ngIf="model.ownerState">
                        <div>{{ model.ownerState }}</div>
                    </ng-container>
                    <ng-container *ngIf="model.ownerCountry">
                        <div>{{ model.ownerCountry }}</div>
                    </ng-container>
                    <ng-container *ngIf="model.ownerPostalCode">
                        <div>{{ model.ownerPostalCode }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="flex flex-col flex-grow-0 justify-start">
            <div class="flex flex-row items-center">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="additional-menu-item" (click)="viewListee(model)">
                        <mat-icon>visibility</mat-icon>
                        <span>View Listee</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>

<div class="mat-elevation-z2 flex flex-grow mt-2" *ngIf="isTableView">
    <table mat-table
           [dataSource]="tableDataSource" multiTemplateDataRows>
      
        <ng-container matColumnDef="ownerCompanyName">
            <th mat-header-cell *matHeaderCellDef class="table-header">Customer Name</th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.ownerCompanyName}}
            </td>
        </ng-container>
        <ng-container matColumnDef="ownerAccount">
            <th mat-header-cell *matHeaderCellDef class="table-header"> Account Number </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.ownerAccount}}
            </td>
        </ng-container>
        <ng-container matColumnDef="ownerPartySiteNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header"> Party Site Number </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.ownerPartySiteNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="ccn">
            <th mat-header-cell *matHeaderCellDef class="table-header"> CCN </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.basicCcn}}
            </td>
        </ng-container>

        <ng-container matColumnDef="complementaryCCN">
            <th mat-header-cell *matHeaderCellDef class="table-header">Comp CCN </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer" 
                (click)="expandedElement = expandedElement === element ? null : element">
                <span *ngFor="let compCCN of element.complementaryCCN;let indexOf=index;">
                    <span *ngIf="indexOf !== 0">{{ ',' }}</span>
                    <span>{{ compCCN.compCCN }}</span>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef class="table-header"> Options </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="additional-menu-item" (click)="viewListee(element)">
                        <mat-icon>visibility</mat-icon>
                        <span>View Listee</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>assignment</mat-icon>
                        <!-- <span>Generate Auth Page</span> -->
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="table-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="pl-4">
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container>
                                <span class="font-bold">Company Address:&nbsp;</span>
                            </ng-container>
                            <div class="flex flex-column file-details-body flex-wrap">
                                <ng-container *ngIf="element.ownerCompanyAddress1">
                                    <div>{{ element.ownerCompanyAddress1 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress2">
                                    <div>{{ element.ownerCompanyAddress2 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress3">
                                    <div>{{ element.ownerCompanyAddress3 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress4">
                                    <div>{{ element.ownerCompanyAddress4 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCity">
                                    <div>{{ element.ownerCity }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerState">
                                    <div>{{ element.ownerState }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCountry">
                                    <div>{{ element.ownerCountry }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerPostalCode">
                                    <div>{{ element.ownerPostalCode }}</div>
                                </ng-container>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
            class="table-element-row"
            [class.table-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
</div>
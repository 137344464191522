import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export class Constants {
    public static readonly ADMIN_ROLE = 'Admin';
    public static readonly EDIT_ROLE = 'Edit';
    public static readonly USER_ROLE = 'User';
    public static readonly CREATEFILEONLY_ROLE = 'CreateFileOnly';
    public static readonly USER_NAME = 'MT';
    public static readonly FILE_TYPE = ['', 'FILE', 'AGENCY'];
    public static readonly ASC = 'asc';
    public static readonly DESC = 'desc';
    public static readonly ALL = 'All';
    public static readonly ACTIVE = 'Active';
    public static readonly WITHDRAWN = 'Withdrawn';
    public static readonly FILE_SEARCH_REQUEST_OBJECT = 'fileSearchRequestObject';
    public static readonly FILE_VOL_SEARCH_REQUEST_OBJECT = 'fileVolSearchRequestObject';
    public static readonly MFR_SEARCH_REQUEST_OBJECT = 'mfrSearchRequestObject';
    public static readonly LISTEE_SEARCH_REQUEST_OBJECT = 'listeeSearchRequestObject';
    public static readonly PRODUCT_SEARCH_REQUEST_OBJECT = 'productSearchRequestObject';
    public static readonly IC_SEARCH_REQUEST_OBJECT = 'icSearchRequestObject';
    public static readonly MYUL_STATUS_SEARCH_REQUEST_OBJECT = 'myULStatusSearchRequestObject';
    public static readonly DEFAULT_PAGE_SIZE = 25;
    public static readonly DELETE = 'Deleted';
    public static readonly WITHDRAW = 'Withdrawed'
    public static readonly IS_TABLE_VIEW = 'isTableView';
    public static readonly IS_ADMIN_USER = 'isAdminUser';
    public static readonly IS_EDIT_USER = 'isEditUser';
    public static readonly IS_CREATEFILEONLY_USER = 'isCreateFileOnly';
    public static readonly ADD = 'Add';
    public static readonly UPDATED = 'Update';
    public static readonly SUCCESS = 'Success';
    public static readonly FILE_DETAILS = 'file-details';
    public static readonly FILE_VOL_DETAILS = 'file-volume';

    public static readonly confirmIdleAutoLogoutTitle = 'Confirm You Wish To Stay Logged In';
    public static readonly confirmIdleAutoLogoutHeader = 'Do You Wish To Stay Logged Into The System?';
    public static readonly confirmIdleAutoLogoutContentLine1 = 'Due to inactivity you are about to be automatically logged out of the system.';
    public static readonly confirmIdleAutoLogoutContentLine2 = 'Press Stay Logged In button now to remain active.';
    public static readonly confirmIdleAutoLogoutCancelText = 'Stay Logged In';
    public static readonly confirmIdleAutoLogoutStayConfirmText = 'Logout';
    public static readonly WDRWL_CODES = "wdrwlCodes";
    public static readonly RESP_OFC_CODE = "respOfcCode";
    public static readonly FILE_GEN_AUTH_PAGE_REC_ID = 'fileGenAuthPageRecId';
    public static readonly FILE_VOL_GEN_AUTH_PAGE_REC_ID = 'fileVolGenAuthPageRecId';
    public static readonly CUSTOMER_SEARCH_REQUEST_OBJECT = 'customerSearchRequestObject';

    public static readonly SortBy = {
        ASC: 'asc',
        DESC: 'desc'
    };

    public static readonly FILE_VIEW_OPTIONS = {
        fileNumber: 'fileNumber',
        isEdit: 'isEdit',
        fileRecId: 'fileRecId'
    };

    public static readonly FILE_VOL_VIEW_OPTIONS = {
        fileNumber: 'fileNumber',
        isEdit: 'isEdit',
        fileVolRecId: 'fileVolRecId'
    };

    public static readonly FileVolumeType = [
        { name: 'Basic Volume', value: 'BASIC VOLUME' },
        { name: 'Ultralink Volume', value: 'ULTRALINK VOLUME' },
        { name: 'ML Volume', value: 'ML VOLUME' }
    ];

    public static readonly PAGE_SIZE_OPTION: number[] = [10, 20, 25, 50, 100];

    public static readonly SHOW_BY_OPTIONS = [
        { 'key': 'All', 'value': 'All' },
        { 'key': 'Active', 'value': 'A' },
        { 'key': 'Withdrawn', 'value': 'W' }
    ];

    public static readonly FILE_SEARCH_SORT_OPTIONS = [
        { 'key': 'CCN(A to Z)', 'value': 'fileCcn-asc' },
        { 'key': 'CCN(Z to A)', 'value': 'fileCcn-desc' },
        { 'key': 'File Number(A to Z)', 'value': 'fileNumber-asc' },
        { 'key': 'File Number(Z to A)', 'value': 'fileNumber-desc' },
        { 'key': 'Subscriber Number(A to Z)', 'value': 'subscriberNumber-asc' },
        { 'key': 'Subscriber Number(Z to A)', 'value': 'subscriberNumber-desc' },
        { 'key': 'Account Number(A to Z)', 'value': 'ownerAccount-asc' },
        { 'key': 'Account Number(Z to A)', 'value': 'ownerAccount-desc' },
        { 'key': 'Party Site Number(A to Z)', 'value': 'ownerPartySiteNumber-asc' },
        { 'key': 'Party Site Number(Z to A)', 'value': 'ownerPartySiteNumber-desc' },
        { 'key': 'Company Name(A to Z)', 'value': 'ownerCompanyName-asc' },
        { 'key': 'Company Name(Z to A)', 'value': 'ownerCompanyName-desc' }
    ];

    public static readonly FILE_VOLUME_SEARCH_SORT_OPTIONS = [
        { 'key': 'Volume(A to Z)', 'value': 'volume-asc' },
        { 'key': 'Volume(Z to A)', 'value': 'volume-desc' },
        { 'key': 'CCN(A to Z)', 'value': 'fileCcn-asc' },
        { 'key': 'CCN(Z to A)', 'value': 'fileCcn-desc' },
        { 'key': 'File Number(A to Z)', 'value': 'fileNumber-asc' },
        { 'key': 'File Number(Z to A)', 'value': 'fileNumber-desc' },
        { 'key': 'Subscriber Number(A to Z)', 'value': 'subscriberNumber-asc' },
        { 'key': 'Subscriber Number(Z to A)', 'value': 'subscriberNumber-desc' },
        { 'key': 'Account Number(A to Z)', 'value': 'ownerAccount-asc' },
        { 'key': 'Account Number(Z to A)', 'value': 'ownerAccount-desc' },
        { 'key': 'Party Site Number(A to Z)', 'value': 'ownerPartySiteNumber-asc' },
        { 'key': 'Party Site Number(Z to A)', 'value': 'ownerPartySiteNumber-desc' },
        { 'key': 'Responsible Office(A to Z)', 'value': 'responsibleOffice-asc' },
        { 'key': 'Responsible Office(Z to A)', 'value': 'responsibleOffice-desc' },
        { 'key': 'Company Name(A to Z)', 'value': 'ownerCompanyName-asc' },
        { 'key': 'Company Name(Z to A)', 'value': 'ownerCompanyName-desc' }
    ];

    public static readonly MFR_SEARCH_SORT_OPTIONS = [
        { 'key': 'Volume(A to Z)', 'value': 'volume-asc' },
        { 'key': 'Volume(Z to A)', 'value': 'volume-desc' },
        { 'key': 'File Number(A to Z)', 'value': 'fileNumber-asc' },
        { 'key': 'File Number(Z to A)', 'value': 'fileNumber-desc' },
        { 'key': 'CCN(A to Z)', 'value': 'fileCcn-asc' },
        { 'key': 'CCN(Z to A)', 'value': 'fileCcn-desc' },
        { 'key': 'Subscriber Number(A to Z)', 'value': 'mfrSubscriberNumber-asc' },
        { 'key': 'Subscriber Number(Z to A)', 'value': 'mfrSubscriberNumber-desc' },
        { 'key': 'Account Number(A to Z)', 'value': 'mfrAccount-asc' },
        { 'key': 'Account Number(Z to A)', 'value': 'mfrAccount-desc' },
        { 'key': 'Party Site Number(A to Z)', 'value': 'mfrPartySiteNumber-asc' },
        { 'key': 'Party Site Number(Z to A)', 'value': 'mfrPartySiteNumber-desc' },
        { 'key': 'Type(A to Z)', 'value': 'mfrType-asc' },
        { 'key': 'Type(Z to A)', 'value': 'mfrType-desc' },
        { 'key': 'Company Name(A to Z)', 'value': 'mfrCompanyName-asc' },
        { 'key': 'Company Name(Z to A)', 'value': 'mfrCompanyName-desc' }
    ];

    public static readonly PRODUCT_SEARCH_SORT_OPTIONS = [
        { 'key': 'CCN(A to Z)', 'value': 'ccn-asc' },
        { 'key': 'CCN(Z to A)', 'value': 'ccn-desc' },
        { 'key': 'Product Type(A to Z)', 'value': 'productType-asc' },
        { 'key': 'Product Type(Z to A)', 'value': 'productType-desc' },
        { 'key': 'Deliverable(A to Z)', 'value': 'deliverable-asc' },
        { 'key': 'Deliverable(Z to A)', 'value': 'deliverable-desc' },
        { 'key': 'Deliverable Type(A to Z)', 'value': 'deliverableType-asc' },
        { 'key': 'Deliverable Type(Z to A)', 'value': 'deliverableType-desc' },
        { 'key': 'Type of Service(A to Z)', 'value': 'typeOfService-asc' },
        { 'key': 'Type of Service(Z to A)', 'value': 'typeOfService-desc' },
        { 'key': 'Fus Sample Lines(A to Z)', 'value': 'fusSampleLines-asc' },
        { 'key': 'Fus Sample Lines(Z to A)', 'value': 'fusSampleLines-desc' },
        { 'key': 'Industry(A to Z)', 'value': 'industry-asc' },
        { 'key': 'Industry(Z to A)', 'value': 'industry-desc' },
        { 'key': 'Sub-Industry(A to Z)', 'value': 'subIndustry-asc' },
        { 'key': 'Sub-Industry(Z to A)', 'value': 'subIndustry-desc' },
        { 'key': 'ML(A to Z)', 'value': 'isML-asc' },
        { 'key': 'ML(Z to A)', 'value': 'isML-desc' }
    ];

    public static readonly LISTEE_SEARCH_SORT_OPTIONS = [
        { 'key': 'Volume(A to Z)', 'value': 'volume-asc' },
        { 'key': 'Volume(Z to A)', 'value': 'volume-desc' },
        { 'key': 'CCN(A to Z)', 'value': 'fileCcn-asc' },
        { 'key': 'CCN(Z to A)', 'value': 'fileCcn-desc' },
        { 'key': 'Listee FileNo(A to Z)', 'value': 'listeeFileNumber-asc' },
        { 'key': 'Listee FileNo(Z to A)', 'value': 'listeeFileNumber-desc' },
        { 'key': 'Basic FileNo(A to Z)', 'value': 'basicFileNumber-asc' },
        { 'key': 'Basic FileNo(Z to A)', 'value': 'basicFileNumber-desc' },
        { 'key': 'Subscriber Number(A to Z)', 'value': 'listeeSubscriberNumber-asc' },
        { 'key': 'Subscriber Number(Z to A)', 'value': 'listeeSubscriberNumber-desc' },
        { 'key': 'Account Number(A to Z)', 'value': 'listeeOwnerAccount-asc' },
        { 'key': 'Account Number(Z to A)', 'value': 'listeeOwnerAccount-desc' },
        { 'key': 'Party Site Number(A to Z)', 'value': 'listeePartySiteNumber-asc' },
        { 'key': 'Party Site Number(Z to A)', 'value': 'listeePartySiteNumber-desc' },
        { 'key': 'Association Type(A to Z)', 'value': 'listeeAssociationType-asc' },
        { 'key': 'Association Type(Z to A)', 'value': 'listeeAssociationType-desc' }
    ];

    public static readonly IC_SEARCH_SORT_OPTIONS = [
        { 'key': 'IC Number(A to Z)', 'value': 'icnumber-asc' },
        { 'key': 'IC Number(Z to A)', 'value': 'icnumber-desc' },
        { 'key': 'IC Name(A to Z)', 'value': 'icName-asc' },
        { 'key': 'IC Name(Z to A)', 'value': 'icName-desc' },
        { 'key': 'City(A to Z)', 'value': 'city-asc' },
        { 'key': 'City(Z to A)', 'value': 'city-desc' },
        { 'key': 'State(A to Z)', 'value': 'state-asc' },
        { 'key': 'State(Z to A)', 'value': 'state-desc' },
        { 'key': 'Postal Code(A to Z)', 'value': 'postalCode-asc' },
        { 'key': 'Postal Code(Z to A)', 'value': 'postalCode-desc' },
        { 'key': 'Country(A to Z)', 'value': 'country-asc' },
        { 'key': 'Country(Z to A)', 'value': 'country-desc' }
    ];

    public static readonly MyULSTATUS_SEARCH_SORT_OPTIONS = [
        { 'key': 'File Number(A to Z)', 'value': 'fileNumber-asc' },
        { 'key': 'File Number(Z to A)', 'value': 'fileNumber-desc' },
        { 'key': 'MFR PartySiteNo(A to Z)', 'value': 'mfrPartySiteNumber-asc' },
        { 'key': 'MFR PartySiteNo(Z to A)', 'value': 'mfrPartySiteNumber-desc' },
        { 'key': 'Volume(A to Z)', 'value': 'volume-asc' },
        { 'key': 'Volume(Z to A)', 'value': 'volume-desc' },
        { 'key': 'Owner PartySiteNo(A to Z)', 'value': 'ownerPartySiteNumber-asc' },
        { 'key': 'Owner PartySiteNo(Z to A)', 'value': 'ownerPartySiteNumber-desc' }
    ];

    public static readonly CUSTOMER_SEARCH_SORT_OPTIONS = [
        { 'key': 'CCN (A to Z)', 'value': 'ccn-asc' },
        { 'key': 'CCN (Z to A)', 'value': 'ccn-desc' },
        { 'key': 'Account Number (A to Z)', 'value': 'accountNumber-asc' },
        { 'key': 'Account Number (Z to A)', 'value': 'accountNumber-desc' }
    ];

    public static readonly PRIMARY_LIGHT = '#ECF5FC';
    public static readonly PRIMARY_WHITE = '#FFFFFF';
    public static readonly emptyKeyValue = [{ 'key': '', 'value': '' }];

    public static readonly PROD_CCN_VIEW_OPTIONS = {
        prodRecId: 'prodRecId',
        isEdit: 'isEdit'
    };

    public static readonly LISTEE_VIEW_OPTIONS = {
        listeeRecId: 'listeeRecId',
        isEdit: 'isEdit'
    };

    public static readonly MFR_VIEW_OPTIONS = {
        mfrRecId: 'mfrRecId',
        isEdit: 'isEdit'
    };

    public static readonly PRODUCT_DELIVERABLE_OPTIONS = [
        '',
        "Canada UL Mark (New Certification)",
        "UL Mark (New Certification)",
        "Canada ULC Mark (Certification Surveillance)",
        "Canada UL Mark (New Certification)"
    ];

    public static readonly PRODUCT_DELIVERABLE_TYPES = [
        '',
        'Unlisted',
        'Recognized'
    ];

    public static readonly TYPE_OF_SERVICE = [
        'Production Volume',
        'No IBSC - Use LPS Template',
        'M - No IB or SC',
        'No IB',
        'Inspection Service',
        'No IB or SC',
        'No IB/SC',
        'IB only, no SC'
    ];

    public static readonly LISTEE_ASSOCIATION_TYPE = [
        { 'key': 'All', 'value': 'All' },
        { 'key': 'Related Listee', 'value': 'R' },
        { 'key': 'Alternate Listee', 'value': 'A' },
        { 'key': 'ML Listee', 'value': 'M' },
        { 'key': 'Same As Applicant', 'value': 'S' }
    ];

    public static readonly ADD_LISTEE_ASSOCIATION_TYPE = [
        { 'key': 'Related Listee', 'value': 'R' },
        { 'key': 'Alternate Listee', 'value': 'A' },
        { 'key': 'ML Listee', 'value': 'M' },
        { 'key': 'Same As Applicant', 'value': 'S' }
    ];

    public static readonly MFR_TYPE = [
        { 'key': 'Manufacturer', 'value': 'MFR' },
        { 'key': 'Additional Listee', 'value': 'ADL' },
        { 'key': 'Agency Manufacturer', 'value': 'AGENCY_MFR' }
    ];

    public static readonly VOLUME_TYPE = [
        { 'key': '', 'value': null },
        { 'key': "BASIC VOLUME", 'value': "BASIC VOLUME" },
        { 'key': "ULTRALINK VOLUME", 'value': "ULTRALINK VOLUME" },
        { 'key': "ML VOLUME", 'value': "ML VOLUME" }
    ];

    public static readonly MARK_CODE = [
        { 'key': 'A', 'value': 'A' },
        { 'key': 'L', 'value': 'L' },
        { 'key': 'U', 'value': 'U' },
        { 'key': 'UV', 'value': 'UV' },
        { 'key': 'F', 'value': 'F' },
        { 'key': 'C', 'value': 'C' },
        { 'key': 'M', 'value': 'M' },
        { 'key': 'P', 'value': 'P' },
        { 'key': 'UC', 'value': 'UC' },
        { 'key': 'CA', 'value': 'CA' },
        { 'key': 'V', 'value': 'V' },
        { 'key': 'K', 'value': 'K' },
        { 'key': 'T', 'value': 'T' }
    ];

    public static readonly FILTER_BY_LISTEE_STATUS = [
        { 'key': 'All', 'value': 'All' },
        { 'key': 'Active', 'value': 'Y' },
        { 'key': 'Withdrawn', 'value': 'N' }
    ];

    public static readonly FILTER_BY_VOLUME_TYPE = [
        { 'key': 'All', 'value': 'All' },
        { 'key': 'Basic Volume', 'value': 'BASIC VOLUME' },
        { 'key': 'Ultralink Volume', 'value': 'ULTRALINK VOLUME' },
        { 'key': 'ML Volume', 'value': 'ML VOLUME' }
    ];

    public static readonly TOUR_STEPS = [
        'step1', 'navigationIcon1', 'navigationIcon2', 'navigationIcon3', 'navigationIcon4', 'navigationIcon5',
        'navigationIcon6', 'step2@file-details', 'step3@file-details', 'step4@file-details', 'step5@file-details'
    ];

    public static readonly TOUR_TEMP_DATA = {
        'resultCard': {
            fileNumber: 'E63260',
            fileCcn: 'QMFZ2',
            fileStatus: 'A',
            ownerAccount: '124349',
            ownerCompanyName: 'KYODEN CO LTD',
            ownerCompanyAddress: `TOCHIGI PLANT1724 SHIMOTSUBOYAMASHIMOTSUKE- SHIJPTOCHIGI - KEN323 - 0194s`,
            subscriberNumber: '631442002',
            ownerPartySiteNumber: '63260'
        }
    };

    public static readonly BOOLEAN_OPTIONS = [
        { "key": '', value: null },
        { "key": 'Y', value: 'Y' },
        { "key": 'N', value: 'N' }
    ];

    public static readonly FILE_RESULT_TABLE_COLUMNS = [
        'authPage', 'fileStatus', 'fileNumber', 'fileCcn', 'ownerCompanyName',
        'ownerAccount', 'ownerPartySiteNumber', 'subscriberNumber', 'options'
    ];

    public static readonly FILE_VOL_RESULT_TABLE_COLUMNS = [
        'authPage', 'fileVolumeStatus', 'fileNumber', 'fileCcn', 'volume',
        'ownerCompanyName', 'ownerAccount', 'ownerPartySiteNumber', 'subscriberNumber',
        'isListee', 'responsibleOffice', 'options'
    ];

    public static readonly LISTEE_RESULT_TABLE_COLUMNS = [
        'listeeStatus', 'basicFileNumber', 'listeeFileNumber', 'fileCcn', 'volume',
        'listeeAssociationType', 'ownerCompanyName', 'listeeOwnerAccount',
        'listeePartySiteNumber', 'listeeSubscriberNumber', 'options'
    ];

    public static readonly MFR_RESULT_TABLE_COLUMNS = [
        'mfrStatus', 'fileNumber', 'volume', 'mfrAccount', 'mfrPartyNumber',
        'mfrPartySiteNumber', 'mfrSubscriberNumber', 'mfrCompanyName',
        'mfrFactoryId', 'mfrType', 'fileCcn', 'options'
    ];

    public static readonly PRODUCT_RESULT_TABLE_COLUMNS = [
        'ccn', 'productType', 'deliverable', 'deliverableType', 'typeOfService',
        'fusSampleLines', 'industry', 'subIndustry', 'isML', 'options'
    ];

    public static readonly IC_DETAIL_RESULT_TABLE_COLUMNS = [
        'icNumber', 'icName', 'city', 'state', 'postalCode', 'country'
    ];

    public static readonly MYUL_STATUS_RESULT_TABLE_COLUMNS = [
        'mfrWdrwStatus', 'fileNumber', 'volume', 'mfrPartySiteNumber',
        'mfrPortalStatus', 'mfrMGKMail', 'ownerPartySiteNumber', 'ownerPortalStatus'
    ];

    public static readonly CUSTOMER_RESULT_TABLE_COLUMNS = [
        'ownerCompanyName', 'ownerAccount', 'ownerPartySiteNumber', 'ccn', 'complementaryCCN', 'options'
    ];

    public static readonly CONFIRM_MODAL_CONFIG: NgbModalOptions = {
        windowClass: 'modal-zindex',
        backdrop: 'static',
        keyboard: false
    };

    public static readonly MyULStatusFilter = [
        { name: 'All', value: '' },
        { name: 'In MyUL', value: 'Y' },
        { name: 'Not in MyUL', value: 'N' }
    ];
}

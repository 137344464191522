import { Input, Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
    @Input() levelOneTitle = '';
    @Input() levelTwoTitle = '';
    @Input() levelThreeTitle = '';
    @Input() levelOneURL = '';
    @Input() levelTwoURL = '';
    @Input() levelThreeURL = '';
    @Input() activeTitleLevel = 0;

    constructor() {}
}

import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from '../../constants';
import { DataService } from '../../core/services/data.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { FileVolumeSearchRequest, FileVolumeSearchResultModel, WithdrawOrDeleteEventModel } from '../file-volume-detail-search.model';
import { FileVolumeService } from '../file-volume.service';
import { WdrwlCodesSummary, GenerateAuthPageModel } from '../../shared/utility.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportConfirmDialogComponent } from '../../shared/export-confirm-dialog/export-confirm-dialog.component';
import * as FileSaver from 'file-saver';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-file-volume-search',
    templateUrl: './file-volume-search.component.html',
    styleUrls: ['./file-volume-search.component.scss']
})
export class FileVolumeSearchComponent implements OnInit, AfterViewInit {
    @ViewChild('fileVolumeContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public pageName = '';
    levelTwoTitle = '';
    levelOneURL = '';
    activeLevel = 0;

    loading = false;
    isSearch = false;
    isSearching = false;
    noSearchResults = false;
    hasSearchActionOccurred = false;
    searchResultCount: number;
    isTableView = false;

    withdrawalCodes: WdrwlCodesSummary[];
    refreshSearchResultSubscription: Subscription;
    prevSearchRequest: FileVolumeSearchRequest = {} as FileVolumeSearchRequest;
    sortOptions = Constants.FILE_VOLUME_SEARCH_SORT_OPTIONS;
    statusOptions = Constants.SHOW_BY_OPTIONS;
    volumeType = Constants.FILTER_BY_VOLUME_TYPE;
    searchResult: FileVolumeSearchResultModel;
    currentSort = { active: '', direction: '' };
    fileVolumeSearchModel: FileVolumeSearchRequest = {
        fileNumber: '',
        volume: '',
        ownerAccount: '',
        ccn: '',
        compCCN: true,
        subscriberNumber: '',
        partySiteNumber: '',
        fileRecId: 0,
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0],
        filterBy: Constants.ALL,
        volumeType: Constants.ALL,
    };

    constructor(
        private dataService: DataService,
        private loadingSpinnerService: LoadingSpinnerService,
        private toastService: ToastNotificationService,
        private searchService: FileVolumeService,
        private resolver: ComponentFactoryResolver,
        private modalService: NgbModal,
        private previousRouteService: PreviousRouteService
    ) {
        this.refreshSearchResultSubscription = this.searchService.refreshSearchResult$.subscribe(res => {
            // Fetch the data again
            if (this.dataService.isEmpty(this.fileVolumeSearchModel)) {
                this.getSearchResult(this.fileVolumeSearchModel);
            }
        });
    }

    ngOnInit(): void {
        this.activeLevel = 1;
        this.pageName = 'File Volume Search';
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        const obj = this.dataService.getOption(Constants.FILE_VOL_SEARCH_REQUEST_OBJECT)
        if (this.dataService.getOption(Constants.FILE_VOL_SEARCH_REQUEST_OBJECT) && Object.keys(obj).length > 0) {
            this.fileVolumeSearchModel = this.dataService.getOption(Constants.FILE_VOL_SEARCH_REQUEST_OBJECT);
            if (this.dataService.isEmpty(this.fileVolumeSearchModel)) { this.onSearch(this.fileVolumeSearchModel); }
        }
        this.getWithdrawalCodeDetails();
        this.getRespOfficeCodes();
    }

    ngOnDestroy(): void {
        if (this.refreshSearchResultSubscription) {
            this.refreshSearchResultSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    onWithdrawOrDelete = (withdrawOrDeleteEvent: WithdrawOrDeleteEventModel) => {
        document.querySelector('mat-sidenav-content').scrollTop = 0;
        if (withdrawOrDeleteEvent.value) {
            const successMessage = `The File volume detail was ${withdrawOrDeleteEvent.action.toLowerCase()} successfully`;
            const type = ToastNotificationType.success;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(successMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.getSearchResult(this.fileVolumeSearchModel);
        }
        else {
            const withdrawOrDeleteMsg = withdrawOrDeleteEvent.action === Constants.WITHDRAWN ? 'withdraw' : 'delete';
            const errorMessage = `Error while trying to ${withdrawOrDeleteMsg} the file volume. Please try again later`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    onViewChange = (sortingEvent: Paginator) => {
        this.isTableView = sortingEvent.isTableView;
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.fileVolumeSearchModel);
        if (result) { this.fileVolumeSearchModel.pageNumber = sortingEvent.page; }
        else { this.fileVolumeSearchModel.pageNumber = 1; }
        this.fileVolumeSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.fileVolumeSearchModel);
    }

    onSortByChange = (sortingEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.fileVolumeSearchModel);
        if (!result) { this.fileVolumeSearchModel.pageNumber = 1; }
        this.fileVolumeSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.fileVolumeSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];
        this.getSearchResult(this.fileVolumeSearchModel);
    }

    /* To sort the values based on the sort option chosen in the table headers - @param sortingEvent */
    onSortingChange = (sortingEvent: FileVolumeSearchRequest) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.fileVolumeSearchModel);
        if (!result) { this.fileVolumeSearchModel.pageNumber = 1; }
        this.fileVolumeSearchModel.sortBy = sortingEvent.sortBy;
        this.fileVolumeSearchModel.sortDirection = sortingEvent.sortDirection;
        this.getSearchResult(this.fileVolumeSearchModel);
    }

    onSearch = (formValues) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.fileVolumeSearchModel);
        if (!this.hasSearchActionOccurred) {
            this.fileVolumeSearchModel.pageNumber = 1;
            this.fileVolumeSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
            this.fileVolumeSearchModel.sortDirection = this.sortOptions[0].value.split('-')[1];
            this.fileVolumeSearchModel.sortBy = this.sortOptions[0].value.split('-')[0];
        }
        else if (this.hasSearchActionOccurred && !result) {
            this.fileVolumeSearchModel.pageNumber = 1;
        }
        this.getSearchResult(formValues);
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.prevSearchRequest = Object.assign({}, this.fileVolumeSearchModel);
            this.dataService.setOption(Constants.FILE_VOL_SEARCH_REQUEST_OBJECT, this.fileVolumeSearchModel);
            this.searchService.getSearchResult(this.fileVolumeSearchModel)
                .subscribe((searchResult) => {
                    componentRef.destroy();
                    this.searchResult = searchResult;
                    this.searchResultCount = searchResult.paging.totalRecords;
                    this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, []);
                    this.currentSort = {
                        active: this.fileVolumeSearchModel.sortBy,
                        direction: this.fileVolumeSearchModel.sortDirection
                    };

                    if (this.searchResultCount === 0) {
                        const errorMessage = 'No Data found for the specified search criteria';
                        const type = ToastNotificationType.infoBlue;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = true;
                        this.isSearch = false;
                        return;
                    }
                    else {
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = false;
                        this.isSearch = true;
                    }
                }, (error) => {
                    componentRef.destroy();
                    const errorMessage = 'Error while retrieving the data';
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.hasSearchActionOccurred = true;
                    this.searchResultCount = 0;
                    this.noSearchResults = true;
                    this.isTableView = false;
                    this.isSearch = false;
                });
        }
        else {
            const errorMessage = 'Please enter the search criteria in the form below';
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.hasSearchActionOccurred = false;
            this.noSearchResults = false;
            this.searchResultCount = 0;
            this.isSearch = false;
            this.isTableView = false;
        }
    }

    resetForm(fileVolumeSearchForm: NgForm): void {
        // This will clear the previous search request data
        fileVolumeSearchForm.resetForm({
            fileNumber: '',
            volume: '',
            ownerAccount: '',
            ccn: '',
            compCCN: true,
            subscriberNumber: '',
            partySiteNumber: '',
            fileRecId: 0,
            exactSearch: true,
            filterBy: Constants.ALL,
            volumeType: Constants.ALL,
        });

        this.isSearch = false;
        this.isTableView = false;
        this.searchResultCount = 0;
        this.hasSearchActionOccurred = false;
        this.searchResult = {} as FileVolumeSearchResultModel;
        this.prevSearchRequest = {} as FileVolumeSearchRequest;
        this.dataService.setOption(Constants.FILE_VOL_SEARCH_REQUEST_OBJECT, {});
    }

    /**
       * This method will get the
       * withdrawal codes and description
       */
    getWithdrawalCodeDetails = () => {
        this.dataService.getWdrwlCodes().subscribe((details) => {
            this.withdrawalCodes = details.results;
            this.dataService.setOption(Constants.WDRWL_CODES, details.results);
        });
    }

    getRespOfficeCodes = () => {
        this.dataService.getRespOfcCodes().subscribe((details) => {
            this.dataService.setOption(Constants.RESP_OFC_CODE, details.results);
        });
    }

    generateAuthPage = () => {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const fileVolRec = this.dataService.getOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID);
        const empId = this.dataService.getLastUpdatedBy();
        if (fileVolRec != undefined && fileVolRec.length > 0) {
            // Call the api to generate the auth page
            const request: GenerateAuthPageModel = {
                inputType: "FILE_VOL",
                empId: empId,
                records: fileVolRec
            }
            this.dataService.generateAuthPage(request).subscribe(result => {
                // Show the success message
                componentRef.destroy();
                if (result["OverallStatus"] == Constants.SUCCESS) {
                    const successMessage = `Successfully generated the Auth page for selected file volume(s)`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, []);
                    this.searchService.onRefreshSearchResult();
                }
                else {
                    var errorMessage = `Error while generating the Auth page for selected file volume(s)`;
                    if (result["ErrorMessage"] != "") { errorMessage = result["ErrorMessage"]; }
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                componentRef.destroy();
                const errorMessage = `Error while generating the Auth page for selected file volume(s)`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
        }
        else {
            componentRef.destroy();
            const errorMessage = `Please select the file volume(s) to proceed.`;
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    exportToExcel() {
        this.loading = true;
        const fileName = `FileVolume-${this.dataService.customDateFormat(null)}.xlsx`;
        const dialogRef = this.modalService.open(ExportConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.result.then(result => {
            if (result.action === 'yes') {
                if (result.selectedValue === 'true') {
                    this.fileVolumeSearchModel.pageNumber = 1;
                    this.fileVolumeSearchModel.pageSize = this.searchResultCount;
                }
                this.searchService.exportToExcel(this.fileVolumeSearchModel).subscribe((object: Blob) => {
                    if (object.size && object.size > 0) { FileSaver.saveAs(object, fileName); }
                    else {
                        const errorMessage = `Error while trying to export the data to excel`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                    this.loading = false;
                });
            }
        }).catch(res => {
            if (res.action === 'no') { this.loading = false; }
        });
    }
}
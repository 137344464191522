<div class="px-20">
  <app-breadcrumb [levelOneTitle]="pageName" [levelTwoTitle]="levelTwoTitle" [activeTitleLevel]="activeLevel">
  </app-breadcrumb>
</div>
<div class="">
    <mat-card class="main-card mat-elevation-z2"
              joyrideStep="step2"
              title="File Search Form"
              text="User can search for a file detail by using various search parameters"
              stepPosition="center"
              (next)="toggleStepResulCard()">
        <mat-card-header>
            <div class="header-text w-full">
                <h4>SEARCH FOR A FILE</h4>
            </div>
            <div class="additional-menu float-right"
                 joyrideStep="step5"
                 title="Additional Options"
                 text="This option allows the user to either create a new record, export data to excel and various other action based on the component"
                 (prev)="toggleStepResulCard()">
                <button [matMenuTriggerFor]="menu" class="additional-menu-button" aria-label="Menu buttom">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="createNewFile()" class="additional-menu-item" *ngIf="isAdmin || isEdit || isCreateFileOnly">
                        <mat-icon>add_circle_outline</mat-icon>
                        <span>Create New File</span>
                    </button>
                    <button mat-menu-item class="additional-menu-item" (click)="generateAuthPage()"
                            [disabled]="!hasSearchActionOccurred || noSearchResults">
                        <mat-icon>assignment</mat-icon>
                        <span>Generate Authpage</span>
                    </button>
                    <button mat-menu-item class="additional-menu-item" (click)="exportToExcel()"
                            [disabled]="!hasSearchActionOccurred || noSearchResults">
                        <mat-icon>upload_file</mat-icon>
                        <span>Export to Excel</span>
                    </button>
                </mat-menu>
            </div>
        </mat-card-header>
        <mat-card-content class="main-card-content">
            <form #fileDetailSearchForm="ngForm" (ngSubmit)="onSearch(fileDetailSearchForm.value)">
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inputFileNumber" class="form-label">Applicant File Number</label>
                        <div>
                            <input type="text" name="fileNumber" #fileNumber="ngModel" [(ngModel)]="fileSearchModel.fileNumber"
                                   class="form-control" id="inputFileNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputAccountNumber" class="form-label">Applicant Account Number</label>
                        <div>
                            <input type="text" name="ownerAccount" #accountNumber="ngModel"
                                   [(ngModel)]="fileSearchModel.ownerAccount" class="form-control" id="inputAccountNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputCCN" class="form-label">CCN</label>
                        <div>
                            <input type="text" name="ccn" #ccn="ngModel" [(ngModel)]="fileSearchModel.ccn" class="form-control"
                                   id="inputCCN" maxlength="5" appUppercase />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputControlNumber" class="form-label">Control Number</label>
                        <div>
                            <input type="text" name="controlNumber" #controlNumber="ngModel"
                                   [(ngModel)]="fileSearchModel.controlNumber" class="form-control" id="inputControlNumber" />
                        </div>
                    </div>
                </div>
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="inputOwnerCompanyName" class="form-label">Applicant Company Name</label>
                        <div>
                            <input type="text" name="ownerCompanyName" #ownerCompanyName="ngModel"
                                   [(ngModel)]="fileSearchModel.ownerCompanyName" class="form-control" id="inputOwnerCompanyName" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputpartysiteNumber" class="form-label">Applicant Party Site Number</label>
                        <div>
                            <input type="text" name="partySiteNumber" #partysiteNumber="ngModel"
                                   [(ngModel)]="fileSearchModel.partySiteNumber" class="form-control" id="inputpartysiteNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputorgNumber" class="form-label">Org Number</label>
                        <div>
                            <input type="text" name="orgNumber" #orgNumber="ngModel"
                                   [(ngModel)]="fileSearchModel.orgNumber" class="form-control" id="inputorgNumber" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="inputSubscriberNumber" class="form-label">Applicant Subscriber Number</label>
                        <div>
                            <input type="text" name="subscriberNumber" #subscriberNumber="ngModel"
                                   [(ngModel)]="fileSearchModel.subscriberNumber" class="form-control" id="inputSubscriberNumber" />
                        </div>
                    </div>
                </div>
                <div class="mb-4 row">
                    <div class="col-md-3">
                        <label for="filterBy" class="form-label">Filter by Status</label>
                        <div>
                            <mat-select class="form-control" id="filterBy" name="filterBy" [(ngModel)]="fileSearchModel.filterBy">
                                <mat-option [value]="options.value" *ngFor="let options of statusOptions">{{ options.key }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="md-4 row">
                    <div class="col-md-4">
                        <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty form-label" name="exactSearch"
                                      #exactSearch="ngModel" [(ngModel)]="fileSearchModel.exactSearch">
                            Exact Search
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="text-right col-md-4">
                        <button class="mr-2 button form-clear-button" type='button' (click)="resetForm(fileDetailSearchForm)">Clear</button>
                        <button class="button form-button" type="submit">Search</button>
                    </div>
                </div>
            </form>
            <div class="w-full m-0" *ngIf="showTourResultCard" joyrideStep="step3" (prev)="toggleStepResulCard()" title="Pagination"
                 text="Using the given options user can decide the sorting option, sort order, no of results per page as well navigate to different pages of the result">
                <app-paginator [length]="1" [pageIndex]="0" [sortByOptions]="sortOptions" [showSortBy]="true"
                               [showFilterBy]="false" [showPageSize]="true">
                </app-paginator>
            </div>
            <div class="w-full m-0" *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
                <hr />
                <app-paginator (sortingChange)='onSortByChange($event)' (viewChange)='onViewChange($event)' (paginationChange)='onSortPaginationChange($event)'
                               [isTableView]="isTableView" [length]="searchResultCount" [sortByOptions]="sortOptions" [showSortBy]="true"
                               [filterByOptions]="statusOptions" [showFilterBy]="false" [showPageSize]="true" [pageIndex]="0">
                </app-paginator>
            </div>
        </mat-card-content>
    </mat-card>
    <ng-template #fileContainer></ng-template>
    <div *ngIf="noSearchResults && hasSearchActionOccurred">
        <div class="ml-20">
            <div class="flex flex-col justify-center max-w-full mx-auto my-3">
                <div class="flex"><img src="../../../assets/images/noResults.png" class="Bitmap mx-auto" /></div>
                <div class="flex"><div class="feedback-header mx-auto title-no-data">No File Details Found</div></div>
            </div>
        </div>
    </div>
    <div *ngIf="showTourResultCard">
        <div class="search-details-container" joyrideStep="step4" (next)="toggleStepResulCard()" title="Search Result Card"
             text="This card will contain the all the basic details. The strip on the left denotes if a file is active(green) or withdrawn(grey)">
            <app-file-search-result-card [model]="tourResultCardData"></app-file-search-result-card>
        </div>
    </div>
    <div *ngIf="isSearch && searchResult && searchResult.paging && searchResult.paging.totalRecords > 0">
        <div class="search-details-container">
            <div *ngIf="searchResult && searchResult.results && !isTableView">
                <app-file-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                             [wdrwlCodes]="withdrawalCodes" (withdrawOrDeleteAction)="onWithdrawOrDelete($event)">
                </app-file-search-result-card>
            </div>
            <div *ngIf="searchResult && searchResult.results && isTableView">
                <app-file-search-result-card [isTableView]="isTableView" [tableData]="searchResult.results" [currentSort]="currentSort"
                                             [wdrwlCodes]="withdrawalCodes" (withdrawOrDeleteAction)="onWithdrawOrDelete($event)" (sortingChangeAction)="onSortingChange($event)">
                </app-file-search-result-card>
            </div>
        </div>
    </div>
    <div class="loader-center" *ngIf="loading">
        <div id="loader" class="loader"></div>
    </div>
</div>
import { Directive, HostListener, Input } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { MfrDetailService } from '../../mfr-detail.service';
import { CheckMfrExistsRequest } from '../create-mfr.model';

@Directive({
    selector: '[mfrExists]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MfrExistsDirective,
            multi: true,
        },
    ],
})

export class MfrExistsDirective implements Validator {
    @Input('validateFormControl') validateFormControl: FormControl;
    @Input('fileVolRecId') fileVolRecId: number;
    mfrExists = false;
    mfrIsWithdrawn = false;
    constructor(private mfrService: MfrDetailService) { }

    @HostListener('change', ['$event.target.value'])
    onchange = (partySiteNumber) => {
        // API call headers
        if (this.fileVolRecId && partySiteNumber) {
            const request: CheckMfrExistsRequest = {
                fileVolRecId: this.fileVolRecId,
                partySiteNumber: partySiteNumber.split(': ')[1], // Temp solution to send correct partysite value,
            };
            this.mfrService.checkMfrIsWithdrawn(request)
                .subscribe((result) => {
                    if (result) {
                        this.mfrIsWithdrawn = result;
                        this.mfrExists = !result;
                        this.validateFormControl.updateValueAndValidity();
                    }
                    else {
                        this.mfrIsWithdrawn = result;
                        this.mfrService
                            .checkMfrExists(request)
                            .subscribe((result) => {
                                if (result) {
                                    this.mfrExists = result;

                                    // this.validateFormControl.validator(this.validateFormControl);
                                    this.validateFormControl.updateValueAndValidity();
                                }
                                else {
                                    this.mfrExists = result;
                                    this.validateFormControl.updateValueAndValidity();
                                }
                            });
                    }
                });
            }
    };

    validate(c: FormControl) {
        return this.mfrCheckValidator(c);
    }

    mfrCheckValidator(c: AbstractControl): ValidationErrors | null {
        if (this.mfrIsWithdrawn) {
            return { mfrExists: false, mfrIsWithdrawn: true };
        }
        else if (this.mfrExists) {
            return { mfrExists: true, mfrIsWithdrawn: false };
        }
        return null;
    }
}

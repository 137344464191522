import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListeeComponent } from './listee/listee.component';
import { ListeeSearchComponent } from './listee-search/listee-search.component';
import { ListeeSearchResultCardComponent } from './listee-search-result-card/listee-search-result-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { AppRoutingModule } from '../app-routing.module';
import { UtilityModule } from '../utility';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreateListeeComponent } from './create-listee/create-listee.component';

@NgModule({
    declarations: [
        ListeeComponent,
        ListeeSearchComponent,
        ListeeSearchResultCardComponent,
        CreateListeeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        AppRoutingModule,
        UtilityModule,
    ]
})
export class ListeeModule {}

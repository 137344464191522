import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route, ExtraOptions } from '@angular/router';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { FileSearchComponent } from './file-details/file-search/file-search.component';
import { FileDetailsViewComponent } from './file-details/file-detail-view/file-detail-view.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { FileVolumeComponent } from './file-volume/file-volume/file-volume.component';
import { MfrDetailComponent } from './mfr-detail/mfr-detail/mfr-detail.component';
import { FileVolumeSearchComponent } from './file-volume/file-volume-search/file-volume-search.component';
import { MfrSearchComponent } from './mfr-detail/mfr-search/mfr-search.component';
import { ListeeSearchComponent } from './listee/listee-search/listee-search.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { FileDetailsResolver } from './file-details/file-details-resolve.resolver';
import { ListeeComponent } from './listee/listee/listee.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { ProductCcnSearchComponent } from './product-ccn-association/product-ccn-search/product-ccn-search.component';
import { ProductCcnViewComponent } from './product-ccn-association/product-ccn-view/product-ccn-view.component';
import { ProductCcnResolver } from './product-ccn-association/product-ccn-resolve.resolver';
/*import { ListeeDetailViewComponent } from './listee/listee-detail-view/listee-detail-view.component';*/
import { ListeeResolver } from './listee/listee-resolve.resolver';
import { MfrResolver } from './mfr-detail/mfr-resolve.resolver';
/*import { FileVolumeDetailViewComponent } from './file-volume/file-volume-detail-view/file-volume-detail-view.component';*/
import { FileVolumeResolver } from './file-volume/file-volume.resolver';
import { IcDetailSearchComponent } from './ic-details/ic-detail-search/ic-detail-search.component';
import { CustomerSearchComponent } from './customer-details/customer-search/customer-search.component';

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
};

const fallbackRoute: Route = {
    path: 'file-details',
    component: FileSearchComponent

};
const routes: Routes = [
    {
        path: 'file-details/:fileRecId',
        canActivate: [AuthenticationGuard],
        resolve: {
            modelData: FileDetailsResolver
        },
        children: [
            {
                path: 'details', // child route path
                component: FileDetailsViewComponent, // child route component that the router renders
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileDetailsResolver
                }
            },
            {
                path: 'file-volume',
                component: FileVolumeComponent, // another child route component that the router renders
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileDetailsResolver
                }
            },
            {
                path: 'mfr-details/:fileVolRecId',
                component: MfrDetailComponent, // another child route component that the router renders
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileDetailsResolver
                }
            },
            {
                path: 'mfr-details',
                component: MfrDetailComponent, // another child route component that the router renders
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileDetailsResolver
                }
            },
            {
                path: 'listee-details/:fileVolRecId',
                component: ListeeComponent,
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileDetailsResolver
                }
            },
            {
                path: 'listee-details',
                component: ListeeComponent,
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileDetailsResolver
                }
            }]
    },
    { path: 'file-details', component: FileSearchComponent, canActivate: [AuthenticationGuard] },
    {
        path: 'file-volume/:fileVolRecId',
        canActivate: [AuthenticationGuard],
        children: [
            /*{
                path: 'details', // child route path
                component: FileVolumeDetailViewComponent, // child route component that the router renders
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileVolumeResolver
                }
            },*/
            {
                path: 'mfr-details',
                component: MfrDetailComponent, // another child route component that the router renders
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileVolumeResolver
                }
            },
            {
                path: 'listee-details',
                component: ListeeComponent,
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: FileVolumeResolver
                }
            }
        ]
    },
    {
        path: 'productCcn/:prodRecId',
        component: ProductCcnViewComponent, // child route component that the router renders
        canActivate: [AuthenticationGuard],
        resolve: {
            modelData: ProductCcnResolver
        },
        children: [
            {
                path: 'details', // child route path
                component: ProductCcnViewComponent, // child route component that the router renders
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: ProductCcnResolver
                }
            }
        ]
    },
    /*{
        path: 'listeeDetail/:listeeRecId',
        component: ListeeDetailViewComponent,
        canActivate: [AuthenticationGuard],
        resolve: {
            modelData: ListeeResolver
        },
        children: [
            {
                path: 'details',
                component: ListeeDetailViewComponent,
                canActivate: [AuthenticationGuard],
                resolve: {
                    modelData: ListeeResolver
                }
            }
        ]
    },*/
    /*{
        path: 'mfr-details/:mfrRecId',
        canActivate: [AuthenticationGuard]
    },*/
    { path: 'file-volume', component: FileVolumeSearchComponent, canActivate: [AuthenticationGuard] },
    { path: 'manufacturerDetails', component: MfrSearchComponent, canActivate: [AuthenticationGuard] },
    { path: 'listeeDetails', component: ListeeSearchComponent, canActivate: [AuthenticationGuard] },
    { path: 'productCcn', component: ProductCcnSearchComponent, canActivate: [AuthenticationGuard] },
    { path: 'icDetails', component: IcDetailSearchComponent, canActivate: [AuthenticationGuard] },
    { path: 'customerDetails', component: CustomerSearchComponent, canActivate: [AuthenticationGuard] },
    { path: 'logged-out', component: LoggedOutComponent },
    { path: 'loader', component: LoadingSpinnerComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: '', component: FileSearchComponent, canActivate: [AuthenticationGuard] },
    fallbackRoute,
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routingConfiguration)],
    exports: [RouterModule]
})
export class AppRoutingModule {}

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MfrDetailService } from './mfr-detail.service';
import { AuthService } from '../core/services/auth.service';
import { DataService } from '../core/services/data.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { WithdrawMFRRequest, DeleteMfrRequest } from './mfr-detail-search.model';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { WdrwlCodesSummary } from '../shared/utility.model';
import { Constants } from '../constants';

@Injectable({
    providedIn: 'root'
})

export class WithdrawOrDeleteMfr {
    mlVolumeString = "ML VOLUME";

    constructor(
        private dataService: DataService,
        private mfrService: MfrDetailService,
        private modalService: NgbModal,
        private authService: AuthService) { }

    withdrawMfr(mfrAssoRecId: number, wdrwlCodes: WdrwlCodesSummary[],
        fileNumber: string, partySiteNumber: string, volumeType: string, componentRef): Observable<boolean> {
        let subject = new Subject<boolean>();
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.wdrwlCodeDetails = wdrwlCodes;
        if (volumeType != this.mlVolumeString) {
            dialogRef.componentInstance.showWithdrawOrDeleteAll = true;
            dialogRef.componentInstance.checkboxMessage = "Withdraw MFR across all the Volumes in the File";
        }
        dialogRef.componentInstance.data = {
            title: 'Withdraw MFR',
            confirmationInfo: 'Are you sure you want to withdraw the mfr? If yes, please select the withdrawal code',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const lastUpdatedBy = this.authService.getUser().userName.split("@")[0];
                    const lastUpdatedDate = this.dataService.getLastUpdatedDate();
                    const endDate = this.dataService.customDateFormat(null);
                    if (result.showWithdrawOrDeleteValue) {
                        const withdrawRequest: WithdrawMFRRequest = {
                            mfrRecId: mfrAssoRecId,
                            status: 'W',
                            wdrlRecId: result.wdrwlId,
                            lastUpdatedBy: lastUpdatedBy,
                            lastUpdatedOn: lastUpdatedDate,
                            endDate: endDate,
                            fileNumber: fileNumber,
                            partySiteNumber: partySiteNumber
                        }
                        this.mfrService.withdrawMfrFromAllVolume(withdrawRequest).subscribe((result) => {
                            if (result) {
                                subject.next(true);
                            }
                            else {
                                subject.next(false);
                            }
                        },
                            error => {
                                subject.next(false);
                            });
                    } else {
                        const withdrawRequest: WithdrawMFRRequest = {
                            mfrRecId: mfrAssoRecId,
                            status: 'W',
                            wdrlRecId: result.wdrwlId,
                            lastUpdatedBy: lastUpdatedBy,
                            lastUpdatedOn: lastUpdatedDate,
                            endDate: endDate,
                            fileNumber: '',
                            partySiteNumber: ''
                        }
                        this.mfrService.withdrawMfr(withdrawRequest).subscribe((result) => {
                            if (result) {
                                subject.next(true);
                            }
                            else {
                                subject.next(false);
                            }
                        },
                            error => {
                                subject.next(false);
                            });
                    }
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
        return subject.asObservable();
    }

    deleteMfr(mfrAssoRecId: number, fileNumber: string, partySiteNumber: string, volumeType: string,
        componentRef): Observable<boolean> {
        let subject = new Subject<boolean>();
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        if (volumeType != this.mlVolumeString) {
            dialogRef.componentInstance.showWithdrawOrDeleteAll = true;
            dialogRef.componentInstance.checkboxMessage = "Delete MFR across all the Volumes in the File";
        }
        dialogRef.componentInstance.data = {
            title: 'Delete MFR',
            confirmationInfo: 'Are you sure you want to delete the mfr?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const userId = this.authService.getUser().userName.split("@")[0];
                    if (result.showWithdrawOrDeleteValue) {
                        const deleteRequest: DeleteMfrRequest = {
                            fileNumber: fileNumber,
                            partySiteNumber,
                            userId
                        }
                        this.mfrService.deleteMfrFromAllVolume(deleteRequest).subscribe((result) => {
                            if (result) {
                                subject.next(true);
                            }
                            else {
                                subject.next(false);
                            }
                        },
                            error => {
                                subject.next(false);
                            });
                    } else {
                        this.mfrService.deleteMfr(mfrAssoRecId, userId).subscribe((result) => {
                            if (result) {
                                subject.next(true);
                            }
                            else {
                                subject.next(false);
                            }
                        },
                            error => {
                                subject.next(false);
                            });
                    }
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
        return subject.asObservable();
    }
}

<div class="modal-header">
    <h2 class="modal-title">{{ title }}</h2>
</div>
<div class="modal-body">
    <div *ngIf="data.icInfo">
        <div class="font-bold text-center">
            <span>IC Information</span>
        </div>
        <div class="text-left mt-1">
            <span>{{ data.icInfo }}</span>
        </div>
    </div>
    <div *ngIf="data.lcInfo" class="mt-4 mb-1">
        <div class="font-bold text-center">
            <span>LC Information</span>
        </div>
        <div class="text-left mt-1">
            <span>{{ data.lcInfo }}</span>
        </div>
    </div>
    <div *ngIf="data.errorMessage" class="mt-4 mb-1">
        <div class="font-bold text-center">
            <span>Error Message</span>
        </div>
        <div class="text-left mt-1">
            <span>{{ data.errorMessage }}</span>
        </div>
    </div>
    <div class="loader-dialog-box" *ngIf="isLoading">
        <div id="loader" class="loader"></div>
    </div>
</div>
<div class="modal-footer">
    <button class="button button-blue w-28 text-center m-1" (click)="onCancel()">Ok</button>
</div>


/* eslint-disable @typescript-eslint/indent */
import { Injectable } from '@angular/core';
import {
    Router,
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    ActivatedRoute,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Constants } from '../constants';
import { DataService } from '../core/services/data.service';
import { FileSummary } from '../file-details/file-detail-search.model';
import {
    FileVolumeSearchRequest,
    FileVolumeSummary,
} from '../file-volume/file-volume-detail-search.model';
import { FileVolumeService } from './file-volume.service';

@Injectable({
    providedIn: 'root',
})
export class FileVolumeResolver
    implements Resolve<FileSummary | FileVolumeSummary> {
    constructor(
        private router: ActivatedRoute,
        private fileVolumeService: FileVolumeService,
        private dataService: DataService
    ) {}
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<FileSummary | FileVolumeSummary> {
        let fileDetails: FileSummary | FileVolumeSummary;
        this.dataService
            .getModelData()
            .subscribe((modelData) => (fileDetails = modelData));
        if (fileDetails) {
            return of(fileDetails);
        } else {
            const fileVolRecId = route.params.fileVolRecId;
            return this.fileVolumeService
                .getFileVolDetails(fileVolRecId)
                .pipe((result) => result);
        }
        //  else {
        //   // Call the API here
        //   const sortOptions = Constants.FILE_SEARCH_SORT_OPTIONS;
        //   const fileVolumeSearchModel: FileVolumeSearchRequest = {
        //     fileNumber: '',
        //     ownerAccount: '',
        //     ccn: '',
        //     compCCN: '',
        //     subscriberNumber: '',
        //     partySiteNumber: '',
        //     fileRecId: 0,
        //     exactSearch: false,
        //     pageNumber: 1,
        //     pageSize: Constants.DEFAULT_PAGE_SIZE,
        //     sortDirection: sortOptions[0].value.split('-')[1],
        //     sortBy: sortOptions[0].value.split('-')[0],
        //     filterBy: Constants.ALL
        //   };
        //   const fileRecId = route.params.fileRecId;
        //   if (!fileRecId) {
        //     fileVolumeSearchModel.fileRecId = 123;
        //   }
        //   return this.fileVolumeService.getSearchResult(fileRecId).pipe(result => result);
        // }
    }
}

/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, HostListener, Input } from '@angular/core';
import { FileVolumeService } from '../../file-volume.service';
import { CheckVolumeRequest } from '../create-file-volume.model';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[volumeExists]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: VolumeExistsDirective,
        multi: true
    }]
})

export class VolumeExistsDirective implements Validator {
    @Input('validateFormControl') validateFormControl: FormControl;
    @Input('fileRecId') fileRecId: number;
    volumeExists = false;
    constructor(private fileVolumeService: FileVolumeService) {}

    @HostListener('blur', ['$event.target.value'])
    onBlur = (volume) => {
        // API call headers
        if (this.fileRecId && volume) {
            const request: CheckVolumeRequest = {
                fileRecId: this.fileRecId,
                volume: volume,
            };
            this.fileVolumeService
                .checkVolumeExists(request)
                .subscribe((result) => {
                    this.volumeExists = result;
                    // this.validateFormControl.validator(this.validateFormControl);
                    this.validateFormControl.updateValueAndValidity();
                });
        }
    };

    validate(c: FormControl) { return this.volumeCheckValidator(c); }

    volumeCheckValidator(c: AbstractControl): ValidationErrors | null {
        if (!this.volumeExists) { return null; }
        return { volumeExists: true };
    }
}

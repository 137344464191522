import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteConfirmDialog } from '../../core/model/confirm-dialog.model';
import { WdrwlCodesSummary } from '../utility.model';
import { Constants } from '../../constants';
import { DataService } from '../../core/services/data.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit{

    @Input() data: DeleteConfirmDialog;
    @Input() wdrwlCodeDetails: WdrwlCodesSummary[];
    @Input() isCancelButtonRequired: boolean = true;
    @Input() showWithdrawOrDeleteAll: boolean = false;
    @Input() checkboxMessage: string = '';

    withdrawalDescription: string;
    withdrawalId: number;
    showWithdrawOrDeleteValue = false;

    constructor(public activeModal: NgbActiveModal, public dataService: DataService) { }

    onCodeChange(code) {
        var temp;
        this.wdrwlCodeDetails.forEach((data) => {
            if (data.wdrwlCode == code.target.value) {
                temp = data;
            }
        });
        this.withdrawalId = temp.wdrwlRecId;
        this.withdrawalDescription = temp.wdrwlDescription;
    }

    onValueChange(event) {
        this.showWithdrawOrDeleteValue = event.checked;
    }

    onCancel() {
        this.activeModal.dismiss({
            action: 'no',
            wdrwlId: this.withdrawalId,
            showWithdrawOrDeleteValue: this.showWithdrawOrDeleteValue
        });
    }

    onConfirm() {
        this.activeModal.close({
            action: 'yes',
            wdrwlId: this.withdrawalId,
            showWithdrawOrDeleteValue: this.showWithdrawOrDeleteValue
        });
    }

    ngOnInit() {
        if (this.wdrwlCodeDetails)
        {
            this.wdrwlCodeDetails = [...this.dataService.getOption(Constants.WDRWL_CODES)];
            this.wdrwlCodeDetails.shift();
            this.withdrawalId = this.wdrwlCodeDetails[0].wdrwlRecId;
            this.withdrawalDescription = this.wdrwlCodeDetails[0].wdrwlDescription;
        }
    }
}

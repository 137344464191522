<ng-template #CreateNewFile></ng-template>
<div class="d-flex flex-column mx-3 px-3">
    <div class="d-flex my-3 justify-content-between align-items-center">
        <div class="d-flex">
            <i (click)="onClose()" class="material-icons md-36 action-item mr-2">chevron_left</i>
            <h2>{{ action }} File</h2>
        </div>
    </div>
    <div class="ml-3.5">
        <span class="required-field">* Indicates Required Fields</span>
    </div>
    <div class="my-2 py-1 px-3">
        <ng-form [formGroup]="createEditFileForm">
            <div class="form-group row">
                <label for="ownerAccountNumber" class="col-sm-4 col-form-label">Applicant AccountNo <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" id="ownerAccountNumber" name="ownerAccountNumber" required (onBlur)="ownerAccountValidator()" formControlName="ownerAccountNumber" class="form-control"
                           [ngClass]="{'is-invalid': (createEditFileForm.get('ownerAccountNumber').touched || createEditFileForm.get('ownerAccountNumber').dirty) && !createEditFileForm.get('ownerAccountNumber').valid }" />
                    <span class="invalid-feedback">
                        <span *ngIf="createEditFileForm.get('ownerAccountNumber').errors?.required">
                            Please enter Applicant Account Number.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="orgNumber#" class="col-sm-4 col-form-label">Org Number</label>
                <div class="col-sm-8">
                    <input type="text" id="orgNumber" name="orgNumber" formControlName="orgNumber" class="form-control"
                           [ngClass]="{'is-invalid': (createEditFileForm.get('orgNumber').touched || createEditFileForm.get('orgNumber').dirty) && !createEditFileForm.get('orgNumber').valid }" />
                </div>
            </div>
            <div class="form-group row">
                <label for="partySiteNumber" class="col-sm-4 col-form-label">Applicant PartySiteNo <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select class="form-control" id="partySiteNumber" name="partySiteNumber" formControlName="partySiteNumber" (change)="changePartySiteNumber($event)" required
                            [ngClass]="{'is-invalid': (createEditFileForm.get('partySiteNumber').touched || createEditFileForm.get('partySiteNumber').dirty) && !createEditFileForm.get('partySiteNumber').valid }">
                        <option [ngValue]="null" [disabled]=true [selected]="true">Choose Party Site Number</option>
                        <option *ngFor="let party of ownerAccountDetails.customerPartySiteDetails" [ngValue]="party.partySiteNumber">
                            {{party.partySiteNumber}}
                        </option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditFileForm.get('partySiteNumber').errors?.required">
                            Please select Party site number.
                        </span>
                        <span *ngIf="createEditFileForm.get('partySiteNumber').errors?.invalidOwnerAcount">
                            Please select correct customer Party Site Number.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="subscriberNumber" class="col-sm-4 col-form-label">Applicant Subscriber Number</label>
                <div class="col-sm-8">
                    <input type="text" id="subscriberNumber" name="subscriberNumber" formControlName="subscriberNumber" class="form-control"
                           [ngClass]="{'is-invalid': (createEditFileForm.get('subscriberNumber').touched || createEditFileForm.get('subscriberNumber').dirty) && !createEditFileForm.get('subscriberNumber').valid }" />
                </div>
            </div>
            <div class="form-group row">
                <label for="ownerCompanyName" class="col-sm-4 col-form-label">Applicant Company Name</label>
                <div class="col-sm-8">
                    <input type="text" id="ownerCompanyName" name="ownerCompanyName" formControlName="ownerCompanyName" class="form-control" />
                </div>
            </div>
            <div class="form-group row">
                <label for="ownerCompanyAddress" class="col-sm-4 col-form-label">Applicant Company Address</label>
                <div class="col-sm-8">
                    <textarea type="text" id="ownerCompanyAddress" name="ownerCompanyAddress" formControlName="ownerCompanyAddress" class="form-control" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="fileCcn" class="col-sm-4 col-form-label">CCN <span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" name="fileCcn" id="fileCcn" (blur)="ccnExistsValidator($event,'fileCcn',0)"
                           [ngClass]="{'is-invalid': (createEditFileForm.get('fileCcn').touched || createEditFileForm.get('fileCcn').dirty)
                           && !createEditFileForm.get('fileCcn').valid }" formControlName="fileCcn" required maxlength="5" appUppercase />
                    <span class="invalid-feedback">
                        <span class='block' *ngIf="createEditFileForm.get('fileCcn').errors?.required">
                            Please enter the CCN value.
                        </span>
                        <span class='block' *ngIf="createEditFileForm.get('fileCcn').errors?.minlength">
                            The CCN value should be of minimum 4 characters long.
                        </span>
                        <span class='block' *ngIf="createEditFileForm.get('fileCcn').errors?.pattern">
                            The CCN value can contain only letters and numbers.
                        </span>
                        <span class='block' *ngIf="createEditFileForm.get('fileCcn').errors?.ccnExists">
                            Entered CCN doesn't exist. Please enter a valid File CCN.
                        </span>
                    </span>
                </div>
            </div>
            <div class=" form-group row">
                <label for="ownerFileNumber" class="col-sm-4 col-form-label">File Type<span class="required-field">*</span></label>
                <div class="col-sm-8">
                    <select class="form-control" id="fileType" name="fileType" formControlName="fileType" required
                            [ngClass]="{'is-invalid': (createEditFileForm.get('fileType').touched || createEditFileForm.get('fileType').dirty) && !createEditFileForm.get('fileType').valid }">
                        <option [ngValue]="null" [disabled]=true [selected]="this.action === 'Save'">Choose File Type</option>
                        <option *ngFor=" let type of fileTypes" [ngValue]="type.value">{{type.name}}</option>
                    </select>
                    <span class="invalid-feedback">
                        <span *ngIf="createEditFileForm.get('fileType').errors?.required">
                            Please select File Type.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label for="ownerFileNumber" class="col-sm-4 col-form-label">File Number</label>
                <div class="col-sm-8">
                    <input type="text" id="ownerFileNumber" name="ownerFileNumber" formControlName="ownerFileNumber" class="form-control" appUppercase
                           [ngClass]="{'is-invalid': (createEditFileForm.get('ownerFileNumber').touched || createEditFileForm.get('ownerFileNumber').dirty) && !createEditFileForm.get('ownerFileNumber').valid }" />
                    <span class="invalid-feedback">
                        <span class='block' *ngIf="createEditFileForm.get('ownerFileNumber').errors?.maxlength">
                            The FileNumber value cannot be more than 15 characters.
                        </span>
                        <span class='block' *ngIf="createEditFileForm.get('ownerFileNumber').errors?.pattern">
                            The FileNumber value can contain only letters and numbers.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-group row" *ngIf="this.action == 'Edit'">
                <label for="compCCN" class="col-sm-4 col-form-label">Control Number</label>
                <div class="col-sm-8">
                    <div formArrayName="controlNumber" *ngFor="let item of createEditFileForm.get('controlNumber')['controls']; let i = index;" class="row">
                        <div class="col-sm-5" [formGroupName]="i">
                            <input type="text" name="item.{{i}}.controlNumber" formControlName="controlNumber"
                                   id="item.{{i}}" class="form-control" disabled/>
                        </div>
                        <div class="cursor-pointer">
                            <i (click)="addNewControlNumber()" class="material-icons md-36 pr-3">add_circle_outline</i>
                            <i (click)="removeControlNumber(i)" class="material-icons md-36 pr-3">remove_circle_outline</i>
                            <button class="btn btn-copy mb-3" [ngClass]="{'cursor-not-allowed': item.controls.disabled.value}"
                                    (click)="getControlNumber(i)" [disabled]="item.controls.disabled.value">Auto Generate</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row my-3">
                <button type="submit" class="btn btn-copy" (click)="saveFileNumber(createEditFileForm.value)" [disabled]="!createEditFileForm.valid"
                        [ngClass]="{'cursor-not-allowed': !createEditFileForm.valid}">{{ action }} File</button>
                <button type="reset" class="btn btn-outline-secondary ml-3" (click)="onClose()">Cancel</button>
            </div>
        </ng-form>
    </div>
</div>
import {
    AfterViewInit, Component, ComponentFactoryResolver,
    OnInit, ViewChild, ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from '../../constants';
import { DataService } from '../../core/services/data.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ManufacturerSearchRequest, ManufacturerSearchResultModel, WithdrawOrDeleteEventModel } from '../mfr-detail-search.model';
import { MfrDetailService } from '../mfr-detail.service';
import { WdrwlCodesSummary } from '../../shared/utility.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportConfirmDialogComponent } from '../../shared/export-confirm-dialog/export-confirm-dialog.component';
import * as FileSaver from 'file-saver';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-mfr-search',
    templateUrl: './mfr-search.component.html',
    styleUrls: ['./mfr-search.component.scss']
})
export class MfrSearchComponent implements OnInit, AfterViewInit {
    @ViewChild('mfrContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public pageName = '';
    levelTwoTitle = '';
    activeLevel = 0;

    loading = false;
    isSearch = false;
    isSearching = false;
    noSearchResults = false;
    hasSearchActionOccurred = false;
    searchResultCount: number;
    isTableView = false;

    withdrawalCodes: WdrwlCodesSummary[];
    refreshSearchResultSubscription: Subscription;
    prevSearchRequest: ManufacturerSearchRequest = {} as ManufacturerSearchRequest;
    sortOptions = Constants.MFR_SEARCH_SORT_OPTIONS;
    statusOptions = Constants.SHOW_BY_OPTIONS;
    searchResult: ManufacturerSearchResultModel;
    currentSort = { active: '', direction: '' };
    mfrSearchModel: ManufacturerSearchRequest = {
        fileNumber: '',
        mfrAccount: '',
        ownerAccount: '',
        volume: '',
        deliverableItemInstance: '',
        subscriberNumber: '',
        partySiteNumber: '',
        fileRecId: 0,
        fileVolRecId: 0,
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0],
        filterBy: Constants.ALL
    };

    constructor(
        private dataService: DataService,
        private loadingSpinnerService: LoadingSpinnerService,
        private toastService: ToastNotificationService,
        private searchService: MfrDetailService,
        private modalService: NgbModal,
        private resolver: ComponentFactoryResolver,
        private previousRouteService: PreviousRouteService
    ) {
        this.refreshSearchResultSubscription = this.searchService.refreshSearchResult$.subscribe(res => {
            // Fetch the data again
            if (this.dataService.isEmpty(this.mfrSearchModel)) { this.getSearchResult(this.mfrSearchModel); }
        });
    }

    ngOnInit(): void {
        this.activeLevel = 1;
        this.pageName = 'Manufacturer Association';
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        const obj = this.dataService.getOption(Constants.MFR_SEARCH_REQUEST_OBJECT)
        if (this.dataService.getOption(Constants.MFR_SEARCH_REQUEST_OBJECT) && Object.keys(obj).length > 0) {
            this.mfrSearchModel = this.dataService.getOption(Constants.MFR_SEARCH_REQUEST_OBJECT);
            if (this.dataService.isEmpty(this.mfrSearchModel)) { this.onSearch(this.mfrSearchModel); }
        }
        this.getWithdrawalCodeDetails();
    }

    ngOnDestroy(): void {
        if (this.refreshSearchResultSubscription) {
            this.refreshSearchResultSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    /**
     * This method will get the
     * withdrawal codes and description
     */
    getWithdrawalCodeDetails = () => {
        this.dataService.getWdrwlCodes().subscribe((details) => {
            this.withdrawalCodes = details.results;
            this.dataService.setOption(Constants.WDRWL_CODES, this.withdrawalCodes);
        });
    }

    onWithdrawOrDelete = (withdrawOrDeleteEvent: WithdrawOrDeleteEventModel) => {
        document.querySelector('mat-sidenav-content').scrollTop = 0;
        if (withdrawOrDeleteEvent.value) {
            const successMessage = `The MFR association was ${withdrawOrDeleteEvent.action.toLowerCase()} successfully`;
            const type = ToastNotificationType.success;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(successMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.getSearchResult(this.mfrSearchModel);
        }
        else {
            const withdrawOrDeleteMsg = withdrawOrDeleteEvent.action === Constants.WITHDRAWN ? 'withdraw' : 'delete';
            const errorMessage = `Error while trying to ${withdrawOrDeleteMsg} the mfr association. Please try again later`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    onViewChange = (sortingEvent: Paginator) => {
        this.isTableView = sortingEvent.isTableView;
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.mfrSearchModel);
        if (result) { this.mfrSearchModel.pageNumber = sortingEvent.page; }
        else { this.mfrSearchModel.pageNumber = 1; }
        this.mfrSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.mfrSearchModel);
    }

    onSortByChange = (sortingEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.mfrSearchModel);
        if (!result) { this.mfrSearchModel.pageNumber = 1; }
        this.mfrSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.mfrSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];
        this.getSearchResult(this.mfrSearchModel);
    }

    /* To sort the values based on the sort option chosen in the table headers - @param sortingEvent */
    onSortingChange = (sortingEvent: ManufacturerSearchRequest) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.mfrSearchModel);
        if (!result) { this.mfrSearchModel.pageNumber = 1; }
        this.mfrSearchModel.sortBy = sortingEvent.sortBy;
        this.mfrSearchModel.sortDirection = sortingEvent.sortDirection;
        this.getSearchResult(this.mfrSearchModel);
    }

    onSearch = (formValues) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.mfrSearchModel);
        if (!this.hasSearchActionOccurred) {
            this.mfrSearchModel.pageNumber = 1;
            this.mfrSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
            this.mfrSearchModel.sortDirection = this.sortOptions[0].value.split('-')[1];
            this.mfrSearchModel.sortBy = this.sortOptions[0].value.split('-')[0];
        }
        else if (this.hasSearchActionOccurred && !result) {
            this.mfrSearchModel.pageNumber = 1;
        }
        this.getSearchResult(formValues);
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.prevSearchRequest = Object.assign({}, this.mfrSearchModel);
            this.dataService.setOption(Constants.MFR_SEARCH_REQUEST_OBJECT, this.mfrSearchModel);
            this.searchService.getSearchResult(this.mfrSearchModel)
                .subscribe((searchResult) => {
                    componentRef.destroy();
                    this.searchResult = searchResult;
                    this.searchResultCount = searchResult.paging.totalRecords;
                    this.currentSort = {
                        active: this.mfrSearchModel.sortBy,
                        direction: this.mfrSearchModel.sortDirection
                    };

                    if (this.searchResultCount === 0) {
                        const errorMessage = `No Data found for the specified search criteria`;
                        const type = ToastNotificationType.infoBlue;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = true;
                        this.isSearch = false;
                        return;
                    }
                    else {
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = false;
                        this.isSearch = true;
                    }
                }, error => {
                    componentRef.destroy();
                    const errorMessage = `Error while retrieving the data`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.searchResultCount = 0;
                    this.isTableView = false;
                    this.isSearch = false;
                });
        }
        else {
            const errorMessage = `Please enter the search criteria in the form below`;
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.hasSearchActionOccurred = false;
            this.noSearchResults = false;
            this.searchResultCount = 0;
            this.isSearch = false;
            this.isTableView = false;
        }
    }

    resetForm(mfrSearchForm: NgForm): void {
        // This will clear the previous search request data
        mfrSearchForm.resetForm({
            fileNumber: '',
            ownerAccount: '',
            volume: '',
            deliverableItemInstance: '',
            subscriberNumber: '',
            partySiteNumber: '',
            fileRecId: 0,
            fileVolRecId: 0,
            exactSearch: true,
            filterBy: Constants.ALL
        });

        this.isSearch = false;
        this.isTableView = false;
        this.searchResultCount = 0;
        this.hasSearchActionOccurred = false;
        this.searchResult = {} as ManufacturerSearchResultModel;
        this.prevSearchRequest = {} as ManufacturerSearchRequest;
        this.dataService.setOption(Constants.MFR_SEARCH_REQUEST_OBJECT, {});
    }

    exportToExcel() {
        this.loading = true;
        const fileName = `MFRDetails-${this.dataService.customDateFormat(null)}.xlsx`;
        const dialogRef = this.modalService.open(ExportConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.result.then(result => {
            if (result.action === 'yes') {
                if (result.selectedValue === 'true') {
                    this.mfrSearchModel.pageNumber = 1;
                    this.mfrSearchModel.pageSize = this.searchResultCount;
                }
                this.searchService.exportToExcel(this.mfrSearchModel).subscribe((object: Blob) => {
                    if (object.size && object.size > 0) { FileSaver.saveAs(object, fileName); }
                    else {
                        const errorMessage = `Error while trying to export the data to excel`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                    this.loading = false;
                });
            }
        }).catch(res => {
            if (res.action === 'no') { this.loading = false; }
        });
    }
}
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../constants';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { DataService } from '../../core/services/data.service';
import { ProductSummary, ProductEditRequest } from '../product-ccn.model';
import { ProductCcnService } from '../product-ccn.service';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';

@Component({
    selector: 'app-product-ccn-view',
    templateUrl: './product-ccn-view.component.html',
    styleUrls: ['./product-ccn-view.component.scss']
})
export class ProductCcnViewComponent implements OnInit, AfterViewInit {

    public pageName = '';
    levelTwoTitle = '';
    levelOneURL = '';
    activeLevel = 0;
    isEdit: boolean;
    deliverableValue = "";

    deliverableTypes: string[] = Constants.PRODUCT_DELIVERABLE_TYPES;
    deliverableOption: string[] = Constants.PRODUCT_DELIVERABLE_OPTIONS;
    serviceType: string[] = Constants.TYPE_OF_SERVICE;
    mlOptions: string[] = ['Y', 'N'];

    modelData: ProductSummary;

    constructor(
        private router: Router,
        private loadingSpinnerService: LoadingSpinnerService,
        private dataService: DataService,
        private activatedRoute: ActivatedRoute,
        private productService: ProductCcnService,
        private toastService: ToastNotificationService
    ) {
        this.dataService.changeMessage(Constants.PRIMARY_WHITE);
        this.dataService.getModelData().subscribe(result => this.modelData = result);
        if (!this.modelData) {
            this.modelData = <ProductSummary>(
                this.activatedRoute.snapshot.data.modelData
            );
            this.dataService.setModelData(this.modelData);
        }
    }

    editProduct = () => {
        this.isEdit = true;
        this.setBreadCrumbTitle();
    }

    onCancel() {
        this.isEdit = false;
        this.router.navigateByUrl(`productCcn/${this.modelData.productCcnRecId}/details`);
    }

    deleteProduct = (prodRecId: number) => {
        //Delete API call to be made
    }

    onUpdateProduct = (formValues) => {
        const updateRequest: ProductEditRequest = {
            productCcn: this.modelData.ccn,
            productType: formValues.productType,
            deliverable: formValues.deliverable,
            deliverableType: formValues.deliverableType,
            industry: this.modelData.industry,
            typeOfService: formValues.typeOfService,
            fusSampleLines: formValues.fusSampleLines,
            isML: formValues.isML,
            invoiceText: formValues.invoiceText
        }

        this.dataService.setOption(Constants.PROD_CCN_VIEW_OPTIONS.prodRecId, this.modelData.productCcnRecId);
    }

    setBreadCrumbTitle = () => {
        if (this.isEdit)
            this.levelTwoTitle = 'Edit'
        else
            this.levelTwoTitle = 'View'
    }

    ngOnInit(): void {
        this.pageName = 'Product CCN Association';
        this.activeLevel = 1;
        this.levelOneURL = '/productCcn';
        this.isEdit = this.dataService.getOption(Constants.PROD_CCN_VIEW_OPTIONS.isEdit);
        this.setBreadCrumbTitle();
        this.modelData.deliverable = this.modelData.deliverable.trim();
        this.modelData.isML = this.modelData.isML.trim();
    }

    ngAfterViewInit() {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

}

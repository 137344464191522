import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MaterialModule } from './material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { ClientSettingsService } from './core/services/client-settings.service';
import {
    MsAdalAngular6Module,
    MsAdalAngular6Service,
} from 'microsoft-adal-angular6';
import { InsertAuthTokenInterceptor } from './core/interceptors/insert-auth-token.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ToastnotificationComponent } from './shared/toastnotification/toastnotification.component';
import { ToastNotificationService } from './shared/toastnotification/toastnotification.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
    InitializeApp,
    MsAdalAngular6ConfigFactory,
} from './shared/initializeapp-function';
import { SideNavComponent } from './shared/side-nav/side-nav.component';
import { SidenavInsertionDirective } from './shared/side-nav/sidenav-insertion.directive';
import { BackgroundDirective } from './shared/background-blue.directive';
import { ModalDialogComponent } from './shared/modal-dialog/modal-dialog.component';
import { InformationWindowComponent } from './shared/informationwindow/informationwindow.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {
    FontAwesomeModule,
    FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { AppInsightsService } from './app-insights/app-insights.service';
import { NoDataFoundComponent } from './shared/no-data-found/no-data-found.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import {
    DateAdapter,
    MatNativeDateModule,
    MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { dateFormats } from './shared/date-format/date-format.model';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FileDetailsModule } from './file-details/file-details.module';
import { FileVolumeModule } from './file-volume/file-volume.module';
import { MfrDetailModule } from './mfr-detail/mfr-detail.module';
import { ListeeModule } from './listee/listee.module';
import { ProductCcnModule } from './product-ccn-association/product-ccn.module';
import { UtilityModule } from './utility';
import { JoyrideModule } from 'ngx-joyride';
import { IcDetailModule } from './ic-details/ic-detail.module';
import { CustomerDetailsModule } from './customer-details/customer-details.module';
import { SideNavLoaderComponent } from './shared/side-nav-loader/side-nav-loader.component';

@NgModule({
    declarations: [
        AppComponent,
        LoggedOutComponent,
        UnauthorizedComponent,
        ToastnotificationComponent,
        SideNavComponent,
        SidenavInsertionDirective,
        BackgroundDirective,
        ModalDialogComponent,
        NoDataFoundComponent,
        LoaderComponent,
        PageNotFoundComponent,
        LoadingSpinnerComponent,
        SideNavLoaderComponent,
        InformationWindowComponent
    ],
    imports: [
        JoyrideModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        LayoutModule,
        MsAdalAngular6Module,
        NgbAlertModule,
        NgbModule,
        NgIdleKeepaliveModule.forRoot(),
        HttpClientModule,
        FontAwesomeModule,
        MatDatepickerModule,
        FileDetailsModule,
        FileVolumeModule,
        MfrDetailModule,
        ListeeModule,
        ProductCcnModule,
        UtilityModule,
        IcDetailModule,
        CustomerDetailsModule
    ],
    providers: [
        ToastNotificationService,
        MatNativeDateModule,
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ClientSettingsService],
            useFactory: InitializeApp,
        },
        MsAdalAngular6Service,
        {
            provide: 'adalConfig',
            useFactory: MsAdalAngular6ConfigFactory,
            deps: [],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        AuthenticationGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InsertAuthTokenInterceptor,
            multi: true,
        },
        AppInsightsService,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
        },
        { provide: MAT_DATE_FORMATS, useValue: dateFormats },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
    }
}

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListeeService } from './listee.service';
import { AuthService } from '../core/services/auth.service';
import { DataService } from '../core/services/data.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { WithdrawListeeRequest, ListeeDeleteModel } from './listee.model';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constants } from '../constants';

@Injectable({
    providedIn: 'root'
})

export class WithdrawOrDeleteListee {
    constructor(
        private dataService: DataService,
        private listeeService: ListeeService,
        private modalService: NgbModal,
        private authService: AuthService) { }

    withdrawListee(listeeAssoRecId: number, fileVolRecId: number, componentRef): Observable<boolean> {
        let subject = new Subject<boolean>();
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Withdraw Listee Association',
            confirmationInfo: 'Are you sure you want to withdraw the listee association?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const lastUpdatedBy = this.authService.getUser().userName.split("@")[0];
                    const lastUpdatedDate = this.dataService.getLastUpdatedDate();
                    const endDate = this.dataService.customDateFormat(null);
                    const withdrawRequest: WithdrawListeeRequest = {
                        fileVolRecId: fileVolRecId,
                        listeeAssoRecId: listeeAssoRecId,
                        isActive: 'N',
                        lastUpdatedBy: lastUpdatedBy,
                        lastUpdatedOn: lastUpdatedDate,
                        endDate: endDate
                    }
                    this.listeeService.withdrawListee(withdrawRequest).subscribe((result) => {
                        if (result) {
                            subject.next(true);
                        }
                        else {
                            subject.next(false);
                        }
                    },
                        error => {
                            subject.next(false);
                        });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
        return subject.asObservable();
    }

    deleteListee(listeeAssoRecId: number, fileVolRecId: number, componentRef): Observable<boolean> {
        let subject = new Subject<boolean>();
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Delete Listee Association',
            confirmationInfo: 'Are you sure you want to delete the listee association?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const lastUpdatedBy = this.authService.getUser().userName.split("@")[0];
                    const lastUpdatedDate = this.dataService.getLastUpdatedDate();
                    const deleteRequest: ListeeDeleteModel = {
                        fileVolRecId: fileVolRecId,
                        listeeAssoRecId: listeeAssoRecId,
                        lastUpdatedBy: lastUpdatedBy,
                        lastUpdatedOn: lastUpdatedDate
                    }
                    this.listeeService.deleteListee(deleteRequest).subscribe((result) => {
                        if (result) {
                            subject.next(true);
                        }
                        else {
                            subject.next(false);
                        }
                    },
                        error => {
                            subject.next(false);
                        });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
        return subject.asObservable();
    }
}

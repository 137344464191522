/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/member-ordering */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ToastNotificationPlacement } from '../toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../toastnotification/toastNotificationMessage.model';
import { SidenavInsertionDirective } from './sidenav-insertion.directive';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(SidenavInsertionDirective, { static: false }) insertionPoint:
        | SidenavInsertionDirective
        | undefined;
    message: ToastNotificationMessage | undefined;
    private toastSubscription: Subscription | undefined;
    private readonly _onInnerSidenavClose = new Subject<any>();
    public onClose = this._onInnerSidenavClose.asObservable();
    componentRef: ComponentRef<any> | undefined;
    childComponentType: Type<any> | undefined;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        public viewContainerRef: ViewContainerRef,
        private toastService: ToastNotificationService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.childComponentType) {
            this.loadChildComponent(this.childComponentType);
            this.cd.detectChanges();
        }
    }

    loadChildComponent(componentType: Type<any>): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
        if (this.insertionPoint) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                componentType
            );

            const viewContainerRef = this.insertionPoint.viewContainerRef;
            viewContainerRef.clear();

            this.componentRef = viewContainerRef.createComponent(
                componentFactory
            );

            this.toastSubscription = this.toastService.toastNotificationMessage$.subscribe(
                (message: ToastNotificationMessage | null) => {
                    if (
                        message &&
                        message.placement ===
                            ToastNotificationPlacement.InnerSidenav
                    ) {
                        this.message = message;
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {}
}

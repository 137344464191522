<div class="ml-20">
    <div class="flex flex-col justify-center max-w-full mx-auto my-3">
        <div class="flex">
            <img src="../../../assets/images/noResults.png" class="Bitmap mx-auto" />
        </div>
        <div class="flex">
            <div class="feedback-header mx-auto title-no-data">Page Not Found</div>
        </div>
        <div class="flex">
            <div class="feedback-message mx-auto body-no-data">
                Try searching using different search criteria.
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from 'src/app/app-insights/app-insights.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private appInsightsService: AppInsightsService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            // console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
          } else {
            // console.log('this is server side error');
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          }
          console.log(errorMsg);
          this.appInsightsService.logException(error);
          return throwError(errorMsg);
        })
      );
  }
}

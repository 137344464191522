
<div class="modal-header">
    <h2 class="modal-title">What's New</h2>
</div>
<div class="modal-body p-0">
    <div class="list-group">
        <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-2">IFVS Release</h5>
                <h6>{{welcomeBannerReleaseDate | date:'dd-MMM-yyyy' : 'UTC'}}</h6>
            </div>
            <h6 class="mt-2">CAR-Remediation : File Volume Create\Edit - CCN Validation </h6>
            <li class="mb-1">If entered CCN doesn't exist in CTR, users will not be allowed to create or edit file volumes.</li>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="w-25 btn button-white btn-lg float-right m-1" (click)="onSkip()">Skip</button>
    <button type="button" class="w-25 btn button-blue btn-lg float-right m-1" (click)="onConfirm()">Okay</button>
</div>

<section>
    <div class="px-20">
        <app-breadcrumb [levelOneTitle]="pageName" [levelOneURL]="levelOneURL" [levelTwoTitle]="levelTwoTitle" [activeTitleLevel]="activeLevel">
        </app-breadcrumb>
        <div>
            <div class="font-bold inline-block">
                <div>
                    {{modelData.productType}}
                </div>
            </div>
            <div class="additional-menu float-right inline-block">
                <button [matMenuTriggerFor]="menuFile" class="additional-menu-button" aria-label="Menu buttom">
                    <mat-icon class="text-3xl">menu</mat-icon>
                </button>
                <mat-menu #menuFile="matMenu">
                    <button mat-menu-item (click)="editProduct()" class="additional-menu-item">
                        <span>Edit Product</span>
                    </button>
                    <button mat-menu-item class="additional-menu-item" (click)="deleteProduct(modelData.productCcnRecId)">
                        <span>Delete Product</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <hr class="hr-line" />
    <div class="ml-16 w-11/12">
        <mat-card class="mt-2">
            <mat-card-content>
                <form #editProductForm="ngForm" (ngSubmit)="onUpdateProduct(editProductForm.value)">
                    <div class="mb-2 row">
                        <div class="col-md-4">
                            <label for="inputProductType" class="form-label">Product Type</label>
                            <label *ngIf="!isEdit" class="form-control-label">{{ modelData.productType }}</label>
                            <input *ngIf="isEdit" type="text" name="productType" #productType="ngModel"
                                   [(ngModel)]="modelData.productType" class="form-control" id="inputProductType"
                                   placeholder="Product Type" required value="modelData.productType"
                                   [ngClass]="{'is-invalid': (productType.touched || productType.dirty) && !productType.valid }" />
                        </div>
                        <div class="col-md-4">
                            <label for="inputCcn" class="form-label">CCN</label>
                            <label *ngIf="!isEdit" class="form-control-label">{{ modelData.ccn}}</label>
                            <input *ngIf="isEdit" type="text" name="productCcn" disabled #productCcn="ngModel"
                                   [(ngModel)]="modelData.ccn" class="form-control" id="inputFilNumber" value="modelData.ccn"
                                   [ngClass]="{'is-invalid': (productCcn.touched || productCcn.dirty) && !productCcn.valid }" />
                        </div>
                        <div class="col-md-4">
                            <label for="inputDeliverable" class="form-label">Deliverable</label>
                            <label *ngIf="!isEdit" class="form-control-label">{{ modelData.deliverable}}</label>
                            <select *ngIf="isEdit" matInput class="form-control" id="inputDeliverable" [(value)]="modelData.deliverable"
                                     name="deliverable" [(ngModel)]="modelData.deliverable" #deliverable="ngModel">
                                <option [value]="option" *ngFor="let option of deliverableOption">{{ option }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div class="col-md-4">
                            <label for="inputDeliverableType" class="form-label">Deliverable Type</label>
                            <label *ngIf="!isEdit" class="form-control-label">{{ modelData.deliverableType}}</label>
                            <select *ngIf="isEdit" matInput class="form-control" id="inputDeliverableType" [(value)]="modelData.deliverableType"
                                    name="deliverableType" [(ngModel)]="modelData.deliverableType" #deliverableType="ngModel">
                                <option [value]="type" *ngFor="let type of deliverableTypes">{{ type }}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label for="inputTypeOfService" class="form-label">Type of Service</label>
                            <label *ngIf="!isEdit" class="form-control-label">{{ modelData.typeOfService}}</label>
                            <select *ngIf="isEdit" matInput class="form-control" id="inputTypeOfService" required [(value)]="modelData.typeOfService"
                                    name="typeOfService" [(ngModel)]="modelData.typeOfService" #typeOfService="ngModel"
                                    [ngClass]="{'is-invalid': (typeOfService.touched || typeOfService.dirty) && !typeOfService.valid }">
                                <option [value]="service" *ngFor="let service of serviceType">{{ service }}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label for="inputFusSampleLines" class="form-label">Fus Sample Lines</label>
                            <label *ngIf="!isEdit" class="form-control-label">{{ modelData.fusSampleLines}}</label>
                            <input *ngIf="isEdit" type="text" name="fusSampleLines" #fusSampleLines="ngModel"
                                   [(ngModel)]="modelData.fusSampleLines" class="form-control" id="inputFusSampleLines" value="modelData.fusSampleLines"/>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div class="col-md-4">
                            <label for="inputIsML" class="form-label">ML</label>
                            <label *ngIf="!isEdit" class="form-control-label">{{ modelData.isML}}</label>
                            <select *ngIf="isEdit" matInput class="form-control" id="inputTypeOfService" required [(value)]="modelData.isML"
                                    name="isML" [(ngModel)]="modelData.isML" #isML="ngModel"
                                    [ngClass]="{'is-invalid': (isML.touched || isML.dirty) && !isML.valid }">
                                <option [value]="option" *ngFor="let option of mlOptions">{{ option }}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label for="inputInvoiceText" class="form-label">Invoice Text</label>
                            <label *ngIf="!isEdit" class="form-control-label">{{ modelData.invoiceText}}</label>
                            <input *ngIf="isEdit" type="text" name="invoiceText" #invoiceText="ngModel"
                                   [(ngModel)]="modelData.invoiceText" class="form-control" id="inputFilNumber" value="modelData.invoiceText"/>
                        </div>
                    </div>
                    <div class="md-3 row" *ngIf="isEdit">
                        <div class="col-md-8"></div>
                        <div class="text-right col-md-4">
                            <button class="mr-2 button form-clear-button" (click)="onCancel()">Cancel</button>
                            <button *ngIf="isEdit" class="button form-button"
                                    [ngClass]="{'btn-disabled': !editProductForm.form.valid}"
                                    [disabled]="!editProductForm.form.valid" type="submit">
                                Update
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</section>
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileVolumeService } from './file-volume.service';
import { AuthService } from '../core/services/auth.service';
import { DataService } from '../core/services/data.service';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { WdrwlCodesSummary } from '../shared/utility.model';
import { WithdrawFileVolume } from './file-volume-detail-search.model';
import { Observable, Subject  } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constants } from '../constants'

@Injectable({
    providedIn: 'root'
})

export class WithdrawOrDeleteFilevolume {
    constructor(
        private dataService: DataService,
        private fileVolApiService: FileVolumeService,
        private modalService: NgbModal,
        private authService: AuthService) { }

    withdrawFileVolume(fileVolRecId: number, wdrwlCodes: WdrwlCodesSummary[], componentRef): Observable<boolean> {
        let subject = new Subject<boolean>();
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.wdrwlCodeDetails = wdrwlCodes;
        dialogRef.componentInstance.data = {
            title: 'Withdraw File Volume',
            confirmationInfo: 'Are you sure you want to withdraw the file volume? If yes please choose the withdrawal code',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const lastUpdatedBy = this.authService.getUser().userName.split("@")[0];
                    const lastUpdatedDate = this.dataService.getLastUpdatedDate();
                    const endDate = this.dataService.customDateFormat(null);
                    const withdrawRequest: WithdrawFileVolume = {
                        fileVolRecId: fileVolRecId,
                        fileVolStatus: 'W',
                        lastUpdatedOn: lastUpdatedDate,
                        lastUpdatedBy: lastUpdatedBy,
                        wdrlRecId: result.wdrwlId,
                        mfrStatus: 'W',
                        listeeIsActive: 'N',
                        endDate: endDate
                    }
                    this.fileVolApiService.withdrawFileVolume(withdrawRequest).subscribe((result) => {
                        if (result) {
                            subject.next(true);
                        }
                        else {
                            subject.next(false);
                        }
                    },
                        error => {
                            subject.next(false);
                        });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
        return subject.asObservable();
    }

    deleteFileVolume(fileVolRecId: number, componentRef): Observable<boolean> {
        let subject = new Subject<boolean>();
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Delete File Volume',
            confirmationInfo: 'Are you sure you want to delete the file volume?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                if (result.action === 'yes') {
                    const userId = this.authService.getUser().userName.split("@")[0];
                    this.fileVolApiService.deleteFileVolume(fileVolRecId, userId).subscribe((result) => {
                        if (result) {
                            subject.next(true);
                        }
                        else {
                            subject.next(false);
                        }
                    },
                        error => {
                            subject.next(false);
                        });
                }
            })
            .catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
        return subject.asObservable();
    }
}
